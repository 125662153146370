import { Center, Heading, Image, useBreakpointValue,Button,Box,Text, VStack, Select, HStack, InputGroup, InputLeftElement, } from "@chakra-ui/react";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Formik, Form, Field } from "formik"
import { useNavigate, Link } from "react-router-dom";
import React, {useState} from "react"

import netflixlogo from "../Assets/Netflix_Logo.png"



function NotFoundPage({toggleProp}){

    let navigate = useNavigate()


    let [initialValues, setInitialvalues] = useState({
        language: ""
    })
     
    let btnStyle = {

        //background: "#e50914",
        fontWeight: 700,
        border: "none",
        outline: "none",
        padding: "16px",
        fontSize: "16px",
        cursor: "pointer",
        paddingRight:"30px",
        paddingLeft:"30px"
    }



    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })

    let tabletView = useBreakpointValue({
        md: true,
        lg: false
    })



    return(
        <div>
        
            <Center minHeight="100vh" backgroundColor= "rgba(0,0,0,1)" color="white">
                <VStack>
                <Heading fontSize={tabletView? "7xl": (mobileView? "4xl": "8xl")}>404 - Not Found</Heading>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >The page you are looking for does not exist.</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}>But don't worry, you can find plenty of other things on our </Text>
                <Text fontSize={tabletView? "xl": (mobileView? "sm":"2xl")} mt={-4}>homepage.</Text>

                <Button mt={4} colorScheme="facebook" bg="#e50914" _hover={{background:"red.500"}} onClick={(e)=> navigate("/") } >Back to homepage</Button>



                
                {/* Creating logo*/}  
                <Link to="/">
                  <Image transform={mobileView&& "scale(0.7)"} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} zIndex={2} src={netflixlogo} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%" left={mobileView?"-5": "3"} width="auto" height="75px"  /> 
                </Link>

                
                  <HStack gap={6} zIndex={2} transform={mobileView&& "scale(0.7)"} position="absolute" top= {mobileView? "0.2%":"0%"} right={mobileView?"-2": "8"}>
                  
                  <Formik
                  initialValues={initialValues}>
                    {(formik)=>(
                      <Form>
                        <InputGroup  mt={4} >
                        <InputLeftElement  color="white" pr={2} children={<FontAwesomeIcon icon={faLanguage}/>} />
                        <Field color="white" as={Select} width={!mobileView&&!tabletView && "10vw"} style={{textAlign: "center"}}  name="language" id="language">
                          <option style={{color:"black"}}>English</option>
                          <option style={{color:"black"}}>Espanol</option>
                        </Field>
                        </InputGroup>
                      </Form>
                    )}
                  </Formik>
                
                  <Button onClick={(e)=> navigate("/signin", {replace:true})} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} mt={4} color="white"  fontWeight="bold"  width="auto" >Sign In</Button>
            
                  </HStack> 
                

                </VStack>
            </Center>

        </div>
    )
}


export default NotFoundPage