import { VStack, useBreakpointValue, Tooltip, IconButton,Button,Box, Text, Heading,Image, Container, ButtonGroup, Card, CardBody, HStack, Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalFooter, ModalCloseButton, SimpleGrid, CardHeader, CardFooter } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import ClassicPostLoader from "./Images Skeleton"
import YoutubeMagic from "./Youtube"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'


import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import 'react-horizontal-scrolling-menu/dist/styles.css'
import InfiniteScroll from "react-infinite-scroll-component"
import {format} from "date-fns"
import React,{ useEffect, useState, useRef, useContext } from "react"


import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlay, faInfo, faInfoCircle, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { MyContext } from "../contexts/AuthContext"



  // // This function add TMDB movies to model database
  // export let [imageCard, setImageCard] = useState()


function FilmsTitles({setIsOpen, descrip,title,setTitle, movieId,setMovieId, imageCover, setImageCover, setDecrip, setLength, setReleaseDate, setYoutubePlay, }){

  let {imageCard, setImageCard} = useContext(MyContext)
  let {tvShows, setTvShows} = useContext(MyContext)

  
  let replaceTiltleSpaces = title? title.replace(/\s+/g, "-").toLowerCase(): "pirate"



  function getCSRFToken(e){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken()


// Fetching data from TMDB database
  const options = {
    //method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
    }
  };


    let [apiData, setApiData] = useState()
    let [blockBuster, setBlockBuster] = useState()
    let [upcoming, setUpcoming] = useState([])
    //For Trending
    let [nextPage, setNextPage] = useState(1)

    //For BlockBuster
    let [nextPageBlock, setNextPageBlock] = useState(1)

    //For Upcoming
    let [nextPageUp, setNextPageUp] = useState(1)
    let [hasMoreUp, setHasMoreUp] = useState()
    let [hasLessUp, setHasLessUp] = useState()
    let [UpCount, setUpCount] = useState()
     
    




    
      let func = async(e)=>{
        try{
          let [response, responseUp, responseBlock] = await axios.all([
            axios.get(`https://api.themoviedb.org/3/trending/movie/day?language=en-US&page=${nextPage}`, options),
            axios.get(`https://api.themoviedb.org/3/movie/popular?language=en-US&page=${nextPageUp}`, options),
            axios.get(`https://api.themoviedb.org/3/trending/person/day?language=en-US&page=${nextPageBlock}`, options),
          ])
          console.log("Fetched external TMDB trending data successfully:", responseBlock.data.results )
          setUpcoming(prev=> [...prev, ...responseUp.data.results])
          
          setUpCount(responseUp.data.total_pages)

          setHasMoreUp(responseUp.data.page < response.data.total_pages )
          setNextPageUp(prev=> prev+1)
        
        }
        catch(error){
          console.error("Error fectching external TMDB data:", error.message)
        }
      }

      
  
      useEffect((e)=>{
        func()
      }, [])


 

    //Creating a Hamburger Menu / base represents mobile & md represents desktop
    let mobileView = useBreakpointValue({
      base: true,
      md: false
    })
    
    
    let tabletView = useBreakpointValue({
      md: true,
      lg:false
          
    })
   


   
    return(
        <div>
            {/* Fetched data from TMDB database */}

            <div style={{position: "relative"}}>
            <Heading size="md" mb={5}pt={10} >Popular</Heading>
            {/* <ButtonGroup spacing={4}  style={{position:"absolute", top:45, right: 20}} >
            <IconButton size="xs" isDisabled={!hasLessUp? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>setNextPageUp(prev=>prev-1)} />
            <Tooltip hasArrow label={`Page ${nextPageUp}/${UpCount}`}>
            <IconButton size="xs" isDisabled={!hasMoreUp? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>setNextPageUp(prev=>prev+1)} />
            </Tooltip>
            </ButtonGroup> */}
            </div>
            
            
            {upcoming.length>0?
            <InfiniteScroll dataLength={upcoming.length} next={func} hasMore={hasMoreUp} 
            loader={<h4>Loading...</h4>} 
            endMessage={<p>All movies loaded!</p>}>
              <SimpleGrid columns={{base:2, md:3, lg:5}} gap={10} ml={2} >

              {upcoming.map((f, index)=>{
                return <Card key={index}  transform={mobileView && "scale(0.9)"} style={{width:"195px",height:"100%"}}>
                  <CardBody p={0}>
                  <div style={{ height:"181px", overflow:"hidden" }}>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setYoutubePlay(true)
                    setTvShows(false)
                    setImageCover("https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.overview)
                    setLength("03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path)
                    setTitle(f.original_title)
                  }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" width="195px" height="265px"  _hover={{transform:"scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                  </div>
                  </CardBody>
                  <CardFooter p={2} style={{background:"#18181b", color: "#fff"}}>      
                  <VStack alignItems="start">
                  <Text fontWeight="bold" noOfLines={1}>{f.original_title}</Text>
                  <Text fontSize="sm"  mt={-6} >{format(f.release_date, "MMM dd yyyy")}</Text>
                  </VStack>
                  </CardFooter>

                </Card>
              })
              }
              </SimpleGrid>
              </InfiniteScroll>
              
              

              :
              <Box pb={10}>
              <ScrollMenu>
              <Box transform={mobileView&& "scaleX(0.8)"} style={{marginLeft: mobileView&& -130}} >
              <YoutubeMagic />
              </Box>
              </ScrollMenu>
                
              </Box>
              }
              
            

            


  

        </div>
    )
}



export default FilmsTitles;