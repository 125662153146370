import { Heading, useBreakpointValue, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel, textDecoration } from "@chakra-ui/react"
import {faEye, faEyeSlash, faFileCircleXmark, faMultiply, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

import FloatingLabel from "react-bootstrap/FloatingLabel"
//import Form from "react-bootstrap/Form"


import React, {useState, useEffect, useContext} from "react"
import {Formik, Field, Form as Form, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"

import * as Yup from 'yup'

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';

import { useNavigate, Link } from "react-router-dom";
import { MyContext } from "../contexts/AuthContext";
import useResetAuth from "../Components/ResetAuth";
import axios from 'axios'

import netflixbg from "../Assets/netflix-image.jpg"
import netflixlogo from "../Assets/Netflix_Logo.png"





function Signin({successLogin, setsuccessLogin, selected, setSelected, signPage, setSignPage}){

  useResetAuth()


  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })

 

  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg:false
  })



  let navigate = useNavigate()
  let [hover, sethover] = useState(false)
  let [alerterrorMessage, setAlerterrormessage] = useState("")
  let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
 

  // This is the minor (MyContext) that has been imported  
  let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)


  

  let onClose = () => {
    setAlerterrormessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    email: "",
    password: "",
    rememberMe: false
  })


  let validationSchema = Yup.object({
    email: Yup.string().required("Email required").email("Invalid email format").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email must be a valid email adddress"),
    password: Yup.string().required("Password required"),
    rememberMe: Yup.string()
  })

  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();



  let onSubmit = async(values, actions) =>{
    
    try{  

      let response= await axios.post("https://api-0be9.onrender.com/auth/login/", values, {
        headers:{
          'X-CSRFToken': csrftoken
        }
      })
      console.log("Signed in successfully:", response)
      setIsAuthenticated(true)
      setsuccessLogin(response.data.key)
      //storing user data in the browser's local storage
      values.rememberMe && localStorage.setItem("UserLogin", JSON.stringify(values))
      localStorage.setItem("UserAuthtoken", JSON.stringify(response.data.key) )
      actions.setSubmitting(false)
      navigate("/dashboard", {replace: true})
      setSelected("Dashboard")
      actions.resetForm()
    }
    
    catch (error){
      console.error("There was an error signing in:", error.message)
      actions.setSubmitting(false)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
      seterrorResponseMessage(error.response.data.non_field_errors)
    }

  }




  // Getting the user data from the browser's local storage
  
  useEffect((e)=>{
    setSignPage(true)
    let storedData = localStorage.getItem("UserLogin")
    // Converting stored data back to Javascript object
    if (storedData){
    let parseUserData = JSON.parse(storedData)
    initialValues.username = parseUserData.username
    initialValues.password = parseUserData.password
    
    }

 },[])


let [user, setUser] = useState(null)
let [token, setToken] = useState()


let inputStyle = {
  background: "#333",
  border: 0,
  color: "#fff",
  height: "50px",
  lineHeight: "50px",
  padding: "16px 20px",
  //marginBottom: "20px",
  fontSize:14,
}
 
let btnStyle = {
  //background: "#e50914",
  fontWeight: 700,
  border: "none",
  outline: "none",
  padding: "16px",
  fontSize: "16px",
  cursor: "pointer",
}

  let {loadedImage, setLoadedImage} = useContext(MyContext)

  let onLoad = (e)=>{
    setLoadedImage(true)
  }


    return(
      
        
          <div>
              <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8} backgroundImage={loadedImage? netflixbg: "none"} backgroundColor={loadedImage? "transparent":"rgba(0, 0, 0, 0.9)"} backgroundSize="cover" >  

              {/* Creating Overlay */}
              <div style = {{
                background: "rgba(0, 0, 0, 0.4)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>

              {/* Just a loading listener */}
              <img style={{display: "none"}} src={netflixbg} onLoad={onLoad} />

                     
            <div className="flex items-center justify-center min-h-screen flex-grow container" style={{zIndex:1, marginTop:tabletView?0: 20, marginBottom:tabletView &&-20}} >
              <div className="p-12 shadow-md rounded-lg max-w-md w-full" style={{background: "rgba(0, 0, 0, 0.9)", color: "#fff"}} >

              <Heading  style={{width:"90%"}} className="mx-auto" marginBottom={8} color="white" fontSize="3xl">Sign In </Heading>
              <Heading style={{width:"90%"}} className="mx-auto" fontSize="2xl">Unlimited movies, TV shows, and more.</Heading>


                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form style={{width:"90%"}} className="mx-auto">

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={5}  style={{marginBottom: "20px"}} >
                <Field as={Input} label="email" fontSize="sm" placeholder="Email or phone number" name="email" id="email" style={inputStyle} />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>        

 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} style={{marginBottom: "20px"}} >
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} fontSize="sm" type={visiblePass? "text": "password"} placeholder="Password" name="password" style={inputStyle} />
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password"/>
                </FormErrorMessage>


                </FormControl>

                          
                <Button type="submit" style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} isLoading={formik.isSubmitting} mt={4} width="full" color="white"  fontWeight="bold" >Sign In</Button>


                <HStack width="100%" justifyContent="space-between"  >
                <Field as={Checkbox} size="sm" name="rememberMe" id="rememberMe">Remember me</Field>  
                <Text fontSize="small" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4}><span onClick={(e)=> navigate("/users/password/new", {replace:true})} style={{color: "white", fontWeight: "bold", textDecoration: "underline",cursor: "pointer", marginLeft:5}}>Forgot pasword?</span></Text>    
              
                </HStack>


                <div class="help-text" align="center" style={{marginTop: 14, color: "#b3b3b3"}} >
                <Text fontSize="small" >Ready to watch? Enter your email to create or restart your membership.</Text>
                <Text fontSize="small" >Dont have an account? <Text to="/signup" as={Link} _hover={{textDecoration:"underline"}} >Signup</Text></Text>
                </div>
              
              

                </Form>
                )}
                </Formik>

                
              
                {/* <Form onSubmit={onSubmit} style={{width:"90%"}} className="mx-auto" >
                  <FormControl isInvalid={isError} mt={4}>
                  <FloatingLabel label="Username">
                    <Form.Control as={Input} size="sm" value={name} onChange={(e)=>setName(e.target.value)} style={inputStyle} onBlur={(e)=>setTouched(true)} type="text" name="username" id="username" placeholder="Enter email of phone number" />
                  </FloatingLabel>
                  <FormErrorMessage>
                 {isError && "This field is required"}
                  </FormErrorMessage>
                  </FormControl>

                  <Button type="submit" style={btnStyle} mt={4} width="full" color="white"  fontWeight="bold" >Sign In</Button>

                </Form>
                   */}
                
              </div>
            </div>


                
                 {/* Creating logo*/}
                  {/*  <Image src={logo} position="absolute" top= "10%" left="5" boxSize="50px" /> */}
                  <Link to="/">
                  <Image src={netflixlogo} className="netflixlogo" zIndex={2} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%" left="3" width="auto" height="75px" /> 
                  </Link>

                
              

                </VStack>



                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage}
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


               
            </div> 


                  
  
    )
  }


export default Signin