import Signin from "./NetflixCompoDjango/Pages/Signin";
import Signup from "./NetflixCompoDjango/Pages/Signup";
import ActivateEmail from "./NetflixCompoDjango/Pages/ActivateEmail";
import PasswordConfirm from "./NetflixCompoDjango/Pages/PasswordConfirm";
import Password from "./NetflixCompoDjango/Pages/PasswordReset";
import Landing from "./NetflixCompoDjango/Pages/Landing";
import Header from "./NetflixCompoDjango/Components/Header";
import Dashboard from "./NetflixCompoDjango/Pages/Dashboard";
import MoviePlay from "./NetflixCompoDjango/Pages/MoviePlay";
import MovieList from "./NetflixCompoDjango/Pages/MovieList";
import Search from "./NetflixCompoDjango/Pages/Search"
import Genre from "./NetflixCompoDjango/Pages/Genre";
import SeriesAll from "./NetflixCompoDjango/Pages/SeriesAll";
import Films from "./NetflixCompoDjango/Pages/Films";
import NewAndPopular from "./NetflixCompoDjango/Pages/NewAndPopular";
import Person from "./NetflixCompoDjango/Pages/Person";
import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import Footer from "./NetflixCompoDjango/Components/Footer";
import Loadspinner from "./NetflixCompoDjango/Components/Loadspinner"
import NotFoundPage from "./NetflixCompoDjango/Pages/NotFoundPage";
import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import {ProSidebarProvider} from "react-pro-sidebar"
import React, {useState, useEffect, useContext} from 'react'
import MyContextProvider from "./NetflixCompoDjango/contexts/AuthContext"
import ProtectedRoute from "./NetflixCompoDjango/Components/ProtectedRoute";
import { ChakraProvider } from "@chakra-ui/react";
import { createTheme, ThemeProvider } from "@mui/material";
import Keywords from "./NetflixCompoDjango/Pages/Keywords";




function App(){

    const theme = createTheme()

    let navigate = useNavigate()
    let location = useLocation()
    
    

    let [successLogin, setsuccessLogin] = useState(false)
    let [selected, setSelected] = useState("signin")
    let [movieId, setMovieId] = useState()
    


    let [signPage, setSignPage] = useState(false)
    let [moviePlay, setMoviePlay] = useState(false)
    let [searchResults, setSearchResults] = useState()
    let [youtubePlay, setYoutubePlay] = useState(false)
    // Solve useContext to remove this
    let [descrip, setDecrip] = useState()


    let [splashLoading, setSplashLoading] = useState(true)

    useEffect((e)=>{
        let timer = setTimeout(() => {
            setSplashLoading(false)
        }, 3000);

        return ()=>{
            clearTimeout(timer)
        }
    }, [])

 


    return(
        <div>
            <ThemeProvider theme={theme}>

            <ChakraProvider>

            <MyContextProvider>

            <ToastContainer theme="dark" closeOnClick />

            {splashLoading? <Loadspinner /> 
            :
            <>
            <Header successLogin={successLogin} setsuccessLogin={setsuccessLogin} moviePlay={moviePlay} setMoviePlay={setMoviePlay} searchResults={searchResults} setSearchResults={setSearchResults}  /> 
        
            <Routes>
                <Route path="/" element={<Landing successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected}  signPage={signPage} setSignPage={setSignPage} />} />
                <Route path="/signin" element={<Signin successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected} signPage={signPage} setSignPage={setSignPage} />} />
                <Route path="/signup" element={<Signup successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected}  signPage={signPage} setSignPage={setSignPage}  />} />                
                <Route path="/activate/:uid" element={<ActivateEmail signPage={signPage} setSignPage={setSignPage} />} />
                <Route path="/users/password/new" element={<Password signPage={signPage} setSignPage={setSignPage} />} />
                <Route path="/password/reset/confirm/:uid/:token" element={<PasswordConfirm signPage={signPage} setSignPage={setSignPage} />} />
                <Route path="/dashboard" element={
                    <ProtectedRoute>
                    <Dashboard moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute> } 
                    />
                {/* will be expecting name in the pathname */}
                <Route path="/movie/:name" element={
                    <ProtectedRoute>
                    <MoviePlay moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    /> 
                <Route path="/movie-list" element={
                    <ProtectedRoute>
                    <MovieList  moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} />
                    </ProtectedRoute>} 
                    />
                <Route path="/search" element={
                    <ProtectedRoute>
                    <Search moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} searchResults={searchResults} setSearchResults={setSearchResults} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    />                
                <Route path="/movie-genre/:name" element={
                    <ProtectedRoute>
                    <Genre moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    />
                <Route path="/movie-series" element={
                    <ProtectedRoute>
                    <SeriesAll moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    />
                <Route path="/films" element={
                    <ProtectedRoute>
                    <Films moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    />                            
                <Route path="/new&popular" element={
                    <ProtectedRoute>
                    <NewAndPopular moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    /> 
                <Route path="/person/:id/:name" element={
                    <ProtectedRoute>
                    <Person moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    />                  
                    <Route path="/keyword/:id/:name/movie" element={
                    <ProtectedRoute>
                    <Keywords moviePlay={moviePlay} setMoviePlay={setMoviePlay} movieId={movieId} setMovieId={setMovieId} searchResults={searchResults} setSearchResults={setSearchResults} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} descrip={descrip} setDecrip={setDecrip} />
                    </ProtectedRoute>} 
                    />
                     
                <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <Footer signPage={signPage} /> 
            </>}
            </MyContextProvider>


            </ChakraProvider>
            
            </ThemeProvider>

        </div>
    )
}


export default App;