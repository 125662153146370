import { Spinner, Box, Image, useBreakpointValue, Center, Text, VStack } from "@chakra-ui/react"
import BarLoader from "react-spinners/BarLoader"
import RingLoader from "react-spinners/RingLoader"
import netflixSymbol from "../Assets/Netflix_Symbol.png"

function Loadspinner({toggleProp}){

    let mobileView = useBreakpointValue({
        base: true,
        md: false
      })
    
     
    
      // This is to target the md or tablet/ipad view
      let tabletView = useBreakpointValue({
        md: true,
        lg:false
      })
    

    return(
        <div>
            <Center minHeight="100vh"   style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}} >
                <VStack >
                <Box>
                <Image src={netflixSymbol}  width="auto" height={mobileView? "200px": "275px"} />
                </Box>
                <BarLoader width={150} color="#fff" />

                {/* <Spinner size="xl" width={70} height={70} borderWidth={5} color="blue.600" /> */}
                {/* <Text fontSize="xl">Loading...</Text> */}

                </VStack>

            </Center>
            

        </div>
    )
}

export default Loadspinner