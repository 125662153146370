import { Container,Card, CardBody, CardFooter, SimpleGrid, IconButton, Tooltip, useBreakpointValue, Box, Button, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter,ButtonGroup, Heading, Image, Center, Text, HStack, VStack } from "@chakra-ui/react";
import { faCheck, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import InfiniteScroll from "react-infinite-scroll-component"
import ClassicPostLoader from "../Components/Images Skeleton";
import YoutubeMagic from "../Components/Youtube";
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import React, {useState, useEffect, useContext} from "react"
import { MyContext } from "../contexts/AuthContext";

import axios from "axios";
import { faVimeoV } from "@fortawesome/free-brands-svg-icons";



function Genre({moviePlay, setMoviePlay, movieId, setMovieId, youtubePlay, setYoutubePlay, descrip, setDecrip}){

  let {genreResults, setGenreResults} = useContext(MyContext)
  let {genreNextPage, setGenreNextPage} = useContext(MyContext)
  let {name} = useParams()
  let replaceNameHyphens = name? name.replace("-", " "): ""
  let capitalTitle = title => title.replace(/\b\w/g, char=>char.toUpperCase())
  let titleCap = capitalTitle(replaceNameHyphens)
  


    let [imageCover, setImageCover] = useState()
    //let [descrip, setDecrip] = useState()
    let [length, setLength] = useState()
    let [releaseDate, setReleaseDate] = useState()
    let [title, setTitle] = useState()

    let replaceTiltleSpaces = title? title.replace(/\s+/g, "-").toLowerCase(): "pirate"
  
    // Split the time string into hours and minutes components. Basically we're unpacking those two variables
    let [hours, minutes] = length? length.split(":") : ""
    // Convert to numbers removing leading zeros. Eg 03 to 3
    let newHours = Number(hours)
    let newMinutes = Number(minutes)
    let Newtime= `${newHours}${newHours==1? "hr":"hrs"} ${newMinutes}mins`


    

  let [genreList, setGenreList] = useState([])
  let [isOpen, setIsOpen] = useState()
  let [movieAddMessage, setMovieAddMessage] = useState(false)
  let [movieAddErrorMessage, setMovieAddErrorMessage] = useState()
  let [nextPage, setNextPage] = useState(1)
  let [hasMore, setHasMore] = useState()
  let [hasLess, setHasLess] = useState()
  let [genreCount, setGenreCount] = useState()



  let onClose = (e)=>{
    setIsOpen(false)
    setMovieAddMessage(false)
    setMovieAddErrorMessage(false)
  }





    function getCSRFToken(e){
        let cookievalue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookievalue? cookievalue[1] : null
    }

    let csrftoken = getCSRFToken()

    //axios.get("https://api.themoviedb.org/3/discover/movie?language=en-US&page=1&with_genres=12", options)

    let addToMovieList = async(e)=>{
      try{
        let response = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": movieId},{
          headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken':  csrftoken
          }
        })
        console.log("Movie has been added to Movie List successfully", response)
        toast.success(`${title} added to Movie List`)
        setMovieAddMessage(true)
      }
      catch (error){
        console.error("Error adding movie to Movie List:", error.response.data.detail)
        toast.error("Error adding movie")
        setMovieAddErrorMessage(`${error.response.data.detail}`)
      }
  
    }
  

    
    let btnStyle = {
      //background: "#e50914",
      fontWeight: 700,
      border: "none",
      outline: "none",
      height: "45px",
      }


        
 //Creating a Hamburger Menu / base represents mobile & md represents desktop
 let mobileView = useBreakpointValue({
  base: true,
  lg: false
 })


 let tabletView = useBreakpointValue({
  md: true,
  lg:false
  
 })





     
 // This function add TMDB movies to model database
 let [imageCard, setImageCard] = useState()

      
 let addTMDBToMovieList = async()=>{    
  let details = {
    title: title,
    description: descrip,
    genre: "action",
    category: "popular",
    length: length,
    backdrop_path: imageCard,
    poster_path: imageCover,
    tmdb_movieid:  movieId
  }
  const loading = toast.loading("Adding movie...")

  try{
    let responseVidz = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}/videos?language=en-US`, options)
     
    let response = await axios.post("https://api-0be9.onrender.com/api/movies/", {...details,youtube_video_url:responseVidz.data.results[0].key},{
      headers:{
        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
        'X-CSRFToken':  csrftoken
      }
    })
    
    let response2 = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": response.data.id},{
      headers:{
        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
        'X-CSRFToken':  csrftoken
      }
    })

    console.log("Movie has been added to Movie List successfully", response)
    toast.dismiss(loading)
    toast.success(`${title} added to Movie List`)
    setMovieAddMessage(true)
  }
  catch (error){
    console.error("Error adding TMDB movie to Model:", error.response.data)
    console.error("Error adding TMDB to Movie List:", error.response.data)
    toast.dismiss(loading)
    toast.error("Error adding movie")
    setMovieAddErrorMessage(`${error.response.data.detail}`)
  }
}



let [TMDBGenreId, setTMDBGenreId] = useState()


  const opt = {
    headers:{
      'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
      'X-CSRFToken': csrftoken
    }
  }
 
 let options = {
  //method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
  }
};
  
     


        let func = async(e)=>{
     
            try{
                let [response, responseTMDB] = await axios.all([
                  axios.get(`https://api-0be9.onrender.com/api/movies/?genre=${titleCap}`, opt), 
                  axios.get(`https://api.themoviedb.org/3/discover/movie?language=en-US&page=${genreNextPage}&with_genres=${genreResults}`, options)
                ])
                const result1 = response.data.results
                const results2 = responseTMDB.data.results
                const combinedResults = genreNextPage===1? result1.concat(results2): results2
                
                
                setGenreCount(responseTMDB.data.total_pages)
                //console.log("Genre List fetch successfully:", response.data.results)
                console.log("TMDB genre List fetch successfully:", responseTMDB.data.results)
                //setGenreList(prev => [...prev, nextPage===1? result1.concat(results2):results2])
                setGenreList(prev => [...prev, ...combinedResults])
                setHasMore(responseTMDB.data.page < responseTMDB.data.total_pages)
                //setHasLess(responseTMDB.data.page > 1)
                //setGenreList(nextPage===1? result1.concat(results2):results2)
                setGenreNextPage(prev=>prev+1)
            }
            catch (error){
                console.error("Error getting Genre List", error.response.data.detail)
                setGenreList(false)
            }
        }



      
      useEffect((e)=>{
  
        setGenreList([])
        setHasMore()
        func()
  
      },[genreResults])


      // }, [genreResults, TMDBGenreId, nextPage])
    
    
    
      return(
        <div>
            <Box pt={20} pb={20} minHeight="100vh" px={4} style={{background: "rgba(0, 0, 0, 1)", position:"relative", color:"white" }} >
              <Heading mt={10} size="md" textAlign="center" mb={5}>'{titleCap}' Films</Heading>
 
                
          {genreList && genreList.length>0? 
          <InfiniteScroll dataLength={genreList.length} next={func} hasMore={hasMore} 
          loader={<h4>Loading...</h4>} 
          endMessage={<p>All movies loaded!</p>}>
            <SimpleGrid columns={{base:2, md:3, lg:5}} gap={10} ml={2} >

              {genreList.map((f, index)=>{
                return <Card key={index} transform={mobileView && "scale(0.9)"} style={{width:"195px",height:"100%"}}>
                <CardBody p={0}>
                  <div style={{ height:"181px", overflow:"hidden" }}>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    f.overview? setYoutubePlay(true):setYoutubePlay(false)
                    setImageCover(f.image_cover? f.image_cover:"https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.description? f.description:f.overview)
                    setLength(f.length? f.length:"03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path && "https://image.tmdb.org/t/p/w500" + f.backdrop_path)
                    setTitle(f.title)
                  }} src={f.image_card? f.image_card:"https://image.tmdb.org/t/p/w500" + f.backdrop_path}  objectFit="cover" width="195px" height="265px"  _hover={{transform:"scale(1.2)"}} transition="transform 0.3s ease-in-out " />
                </div>
                  </CardBody>
                  <CardFooter p={2} style={{background:"#18181b", color: "#fff"}}>      
                  <VStack alignItems="start">
                  <Text fontWeight="bold" noOfLines={1}>{f.original_title}</Text>
               {f.release_date &&  <Text fontSize="sm"  mt={-6} >{format(f.release_date?? "01-01-2023", "MMM dd yyyy")}</Text> }
                  </VStack>
                  </CardFooter>

                </Card>
              }) }      
            </SimpleGrid> 
            </InfiniteScroll>

            :
    
            <>
         

            <Box pb={10}>
            <ScrollMenu>
              <Box transform={mobileView&& "scaleX(0.8)"} style={{marginLeft: mobileView&& -130}} >
            <YoutubeMagic />
              </Box>
            </ScrollMenu>
                
            </Box>
            </>}
            


          
          

                
            <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
            scrollBehavior="inside" 
            preserveScrollBarGap={true}>
              <ModalOverlay>
                <ModalContent style={{background:"#18181b", color: "#fff"}} >
                  <ModalHeader>
                  <ModalCloseButton />
                   {title}  
                  </ModalHeader>

                  <ModalBody>
                    <Image src={imageCover} width="100%" />
                    <HStack mt={5} width="100%" justifyContent="space-between">
                      <Text>Year: {releaseDate && format(releaseDate, "MMMM-yyyy")}</Text>
                      <Text>Length: {Newtime}</Text>
                    </HStack>
                    <Box mt={5}>
                    <Text>{descrip}</Text>
                    <ButtonGroup class="flex space-x-4 " mt={2}>
                      <Button as={Link} to={"/movie/" + replaceTiltleSpaces} onClick={(e)=>setMoviePlay(true)} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} color="white" fontWeight="bold">Play</Button>
                      <Button background="transparent" height="43px" border="3px solid white" color="white" fontWeight="bold" onClick={youtubePlay? addTMDBToMovieList: addToMovieList} _hover={{color: "black", background: "#EDF2F7"}} >{movieAddMessage? <span> Added <FontAwesomeIcon icon={faCheck} /></span> : (movieAddErrorMessage? movieAddErrorMessage:"Watch Later")}</Button>
                    </ButtonGroup>
                    </Box>
                  </ModalBody>
                  
                  <ModalFooter>

                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>



             
                
            </Box>


        </div>
    )
}



export default Genre;