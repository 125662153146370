import { Container, IconButton, Tooltip, useBreakpointValue, Box, Button, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter,ButtonGroup, Heading, Image, Center, Text, HStack, VStack } from "@chakra-ui/react";
import { faCheck, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import FilmsTitles from "../Components/FilmsTitles";
import { MyContext } from "../contexts/AuthContext";
import React, {useState, useEffect, useContext} from "react"

import axios from "axios";




function Films({moviePlay, setMoviePlay, movieId, setMovieId, genreResults, setGenreResults, youtubePlay, setYoutubePlay, descrip, setDecrip}){

    let [imageCover, setImageCover] = useState()
    //let [descrip, setDecrip] = useState()
    let [length, setLength] = useState()
    let [releaseDate, setReleaseDate] = useState()
    let [title, setTitle] = useState()

    let replaceTiltleSpaces = title? title.replace(/\s+/g, "-").toLowerCase(): "pirate"
  
    // Split the time string into hours and minutes components. Basically we're unpacking those two variables
    let [hours, minutes] = length? length.split(":") : ""
    // Convert to numbers removing leading zeros. Eg 03 to 3
    let newHours = Number(hours)
    let newMinutes = Number(minutes)
    let Newtime= `${newHours}${newHours==1? "hr":"hrs"} ${newMinutes}mins`


    

  let [genreList, setGenreList] = useState()
  let [isOpen, setIsOpen] = useState()
  let [movieAddMessage, setMovieAddMessage] = useState(false)
  let [movieAddErrorMessage, setMovieAddErrorMessage] = useState()
  let [nextPage, setNextPage] = useState(1)
  let [hasMore, setHasMore] = useState()
  let [hasLess, setHasLess] = useState()
  let [genreCount, setGenreCount] = useState()



  let onClose = (e)=>{
    setIsOpen(false)
    setMovieAddMessage(false)
    setMovieAddErrorMessage(false)
  }





    function getCSRFToken(e){
        let cookievalue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookievalue? cookievalue[1] : null
    }

    let csrftoken = getCSRFToken()

    //axios.get("https://api.themoviedb.org/3/discover/movie?language=en-US&page=1&with_genres=12", options)

    let addToMovieList = async(e)=>{
      try{
        let response = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": movieId},{
          headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken':  csrftoken
          }
        })
        console.log("Movie has been added to Movie List successfully", response)
        toast.success(`${title} added to Movie List`)
        setMovieAddMessage(true)
      }
      catch (error){
        console.error("Error adding movie to Movie List:", error.response.data.detail)
        toast.error("Error adding movie")
        setMovieAddErrorMessage(`${error.response.data.detail}`)
      }
  
    }
  

    
    let btnStyle = {
      //background: "#e50914",
      fontWeight: 700,
      border: "none",
      outline: "none",
      height: "45px",
      }


        
 //Creating a Hamburger Menu / base represents mobile & md represents desktop
 let mobileView = useBreakpointValue({
  base: true,
  lg: false
 })


 let tabletView = useBreakpointValue({
  md: true,
  lg:false
  
 })


 function LeftArr(){
  let {scrollPrev} = React.useContext(VisibilityContext)
  return(
    <IconButton size="xs" isDisabled={!hasLess? true:false} colorScheme="messenger" style={{position:"absolute", top:125, right:80 }} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>setNextPage(prev=>prev-1)} />
  )
  }
function RightArr(){
  let {scrollNext} = React.useContext(VisibilityContext)
  return(
    <Tooltip hasArrow label={`Page ${nextPage}/${genreCount}`}>
    <IconButton size="xs" isDisabled={!hasMore? true:false} colorScheme="messenger" style={{position:"absolute", top:125, right:40 }} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>setNextPage(prev=>prev+1)} />
    </Tooltip>
  )
}


     
 // This function add TMDB movies to model database
 let [imageCard, setImageCard] = useState()
 let {tvShows, setTvShows} = useContext(MyContext)


      
 let addTMDBToMovieList = async()=>{    
  let details = {
    title: title,
    description: descrip,
    genre: "action",
    category: "popular",
    length: length,
    backdrop_path: imageCard,
    poster_path: imageCover,
    tmdb_movieid:  movieId
  }
  const loading = toast.loading("Adding movie...")

  try{
    let responseVidz = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}/videos?language=en-US`, options)
     
    let response = await axios.post("https://api-0be9.onrender.com/api/movies/", {...details,youtube_video_url:responseVidz.data.results[0].key},{
      headers:{
        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
        'X-CSRFToken':  csrftoken
      }
    })
    
    let response2 = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": response.data.id},{
      headers:{
        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
        'X-CSRFToken':  csrftoken
      }
    })

    console.log("Movie has been added to Movie List successfully", response)
    toast.dismiss(loading)
    toast.success(`${title} added to Movie List`)
    setMovieAddMessage(true)
  }
  catch (error){
    console.error("Error adding TMDB movie to Model:", error)
    console.error("Error adding TMDB series to Movie List:", error)
    toast.dismiss(loading)
    toast.error("Error adding movie")
    setMovieAddErrorMessage(`${error.response.data.detail}`)
  }
}



let [TMDBGenreId, setTMDBGenreId] = useState("28")

 
 let options = {
  //method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
  }
};
  
     
  
      
    
    
    
      return(
        <div>
            <Box pt={20} minHeight="100vh" px={4} style={{background: "rgba(0, 0, 0, 1)", position:"relative", color:"white" }} >
              <Heading mt={10} size="md" textAlign="center" mb={5}>Films To Watch</Heading>
 
                
              {/* Fetched data from TMDB database */}
            
             {/* All external Series titles*/}  
            <FilmsTitles isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}
            movieId={movieId} setMovieId={setMovieId} setImageCard={setImageCard} imageCover={imageCover} setImageCover={setImageCover} 
            length={length} setLength={setLength} descrip={descrip} setDecrip={setDecrip} releaseDate={releaseDate} 
            setReleaseDate={setReleaseDate} title={title} setTitle={setTitle} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} 
            setTubeDetails/>
            


          
          

                
            <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
            scrollBehavior="inside" 
            preserveScrollBarGap={true}>
              <ModalOverlay>
                <ModalContent style={{background:"#18181b", color: "#fff"}} >
                  <ModalHeader>
                  <ModalCloseButton />
                   {title}  
                  </ModalHeader>

                  <ModalBody>
                    <Image src={imageCover} width="100%" />
                    <HStack mt={5} width="100%" justifyContent="space-between">
                      <Text>Year: {releaseDate && format(releaseDate, "MMMM-yyyy")}</Text>
                      <Text>Length: {Newtime}</Text>
                    </HStack>
                    <Box mt={5}>
                    <Text>{descrip}</Text>
                    <ButtonGroup class="flex space-x-4 " mt={2}>
                      <Button as={Link} to={"/movie/" + replaceTiltleSpaces} onClick={(e)=>{
                        setMoviePlay(true) 
                        }} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} color="white" fontWeight="bold">Play</Button>
                      <Button background="transparent" height="43px" border="3px solid white" color="white" fontWeight="bold" onClick={addTMDBToMovieList} _hover={{color: "black", background: "#EDF2F7"}} >{movieAddMessage? <span> Added <FontAwesomeIcon icon={faCheck} /></span> : (movieAddErrorMessage? movieAddErrorMessage:"Watch Later")}</Button>
                    </ButtonGroup>
                    </Box>
                  </ModalBody>
                  
                  <ModalFooter>

                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>



             
                
            </Box>


        </div>
    )
}



export default Films;