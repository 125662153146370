import { useContext, useEffect } from "react";
import { MyContext } from "../contexts/AuthContext";


// Just like regular component but the name starts with "use". Just call it where needed like this useResetAuth(). Just like a function
// The prefix "use" ensures React recognises it as the normal/regular hook(hooks rules apply) and must be called at the top level of component
// This helps avoid repetitive tasks
let useResetAuth = ()=>{
    let {IsAuthenticated, setIsAuthenticated} = useContext(MyContext)

    useEffect((e)=>{
        setIsAuthenticated(false)
        localStorage.removeItem("UserAuthtoken")
    }, [setIsAuthenticated])
}


export default useResetAuth


//Just like a Javascript function as chatGPT will put it