import { VStack, useBreakpointValue, Tooltip, IconButton,Button,Box, Text, Heading,Image, Container, ButtonGroup, Card, CardBody, HStack, Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalFooter, ModalCloseButton, SimpleGrid } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import ClassicPostLoader from "./Images Skeleton"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'


import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import 'react-horizontal-scrolling-menu/dist/styles.css'
import React,{ useEffect, useState, useRef, useContext } from "react"


import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlay, faInfo, faInfoCircle, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { MyContext } from "../contexts/AuthContext"



  // // This function add TMDB movies to model database
  // export let [imageCard, setImageCard] = useState()


function TitleCards({setIsOpen, descrip,title,setTitle, movieId,setMovieId, imageCover, setImageCover, setDecrip, setLength, setReleaseDate, setYoutubePlay, }){

  let {imageCard, setImageCard} = useContext(MyContext)
  let [movieAddMessage, setMovieAddMessage] = useState(false)
  let [movieAddErrorMessage, setMovieAddErrorMessage] = useState()

  let {tvShows, setTvShows} = useContext(MyContext)

  function getCSRFToken(e){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken()


// Fetching data from TMDB database
  const options = {
    //method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
    }
  };


    let [apiData, setApiData] = useState()
    let [blockBuster, setBlockBuster] = useState()
    let [upcoming, setUpcoming] = useState()
    let [topRated, setTopRated] = useState()
    //For Now Playing
    let [nextPage, setNextPage] = useState(1)
    let [hasMore, setHasMore] = useState()
    let [hasLess, setHasLess] = useState()
    let [count, setCount] = useState()
    //For BlockBuster
    let [nextPageBlock, setNextPageBlock] = useState(1)
    let [hasMoreBlock, setHasMoreBlock] = useState()
    let [hasLessBlock, setHasLessBlock] = useState()
    let [blockCount, setBlockCount] = useState()
    //For Upcoming
    let [nextPageUp, setNextPageUp] = useState(1)
    let [hasMoreUp, setHasMoreUp] = useState()
    let [hasLessUp, setHasLessUp] = useState()
    let [UpCount, setUpCount] = useState()
     //For Top-rated
     let [nextPageTop, setNextPageTop] = useState(1)
     let [hasMoreTop, setHasMoreTop] = useState()
     let [hasLessTop, setHasLessTop] = useState()
     let [topCount, setTopCount] = useState()






    useEffect((e)=>{
      let func = async(e)=>{
        try{
          let [response, responseUp, responseBlock, responseTop] = await axios.all([
            axios.get(`https://api.themoviedb.org/3/movie/now_playing?language=en-US&page=${nextPage}`, options),
            axios.get(`https://api.themoviedb.org/3/movie/popular?language=en-US&page=${nextPageUp}`, options),
            axios.get(`https://api.themoviedb.org/3/movie/top_rated?language=en-US&page=${nextPageBlock}`, options),
            axios.get(`https://api.themoviedb.org/3/movie/upcoming?language=en-US&page=${nextPageTop}`, options)
          ])
          console.log("Fetched external TMDB data successfully:", response.data )
          setApiData(response.data.results)
          setUpcoming(responseUp.data.results)
          setBlockBuster(responseBlock.data.results)
          setTopRated(responseTop.data.results)
          
          setCount(response.data.total_pages)
          setUpCount(responseUp.data.total_pages)
          setBlockCount(responseBlock.data.total_pages)
          setTopCount(responseTop.data.total_pages)
          
          setHasMore(response.data.page < response.data.total_pages)
          setHasLess(response.data.page > 1)
          setHasMoreUp(responseUp.data.page < response.data.total_pages )
          setHasLessUp(responseUp.data.page > 1)
          setHasMoreBlock(responseBlock.data.page < response.data.total_pages)
          setHasLessBlock(responseBlock.data.page > 1)
          setHasMoreTop(responseTop.data.page < response.data.total_pages)
          setHasLessTop(responseTop.data.page > 1)
        }
        catch(error){
          console.error("Error fectching external TMDB data:", error.message)
        }
      }

      func()
 
    }, [nextPage, nextPageBlock, nextPageUp, nextPageTop])


 

     //Creating a Hamburger Menu / base represents mobile & md represents desktop
     let mobileView = useBreakpointValue({
      base: true,
      md: false
    })


    let tabletView = useBreakpointValue({
      md: true,
      lg:false
      
    })
   


   
    return(
        <div>
            {/* Fetched data from TMDB database */}
            <div style={{position: "relative"}}>
            <Heading size="md" mb={5}pt={10} >Now Playing</Heading>
            <ButtonGroup spacing={4}  style={{position:"absolute", top:45, right: mobileView?0: 20}} >
            <IconButton size="xs" isDisabled={!hasLess? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
            nextPage>1 && setNextPage(prev=>prev-1) }} />
            <Tooltip hasArrow label={`Page ${nextPage}/${count}`}>
            <IconButton size="xs" isDisabled={!hasMore? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
            nextPage < count && setNextPage(prev=>prev+1) }} />
            </Tooltip>
            </ButtonGroup>
            </div>

            <ScrollMenu >
              {apiData? apiData.map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px",marginRight:mobileView?"0px": "25px", position:"relative", transform:mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5}>{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setYoutubePlay(true)
                    setTvShows(false)
                    setImageCover("https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.overview)
                    setLength("03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path)
                    setTitle(f.original_title)
                  }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" width="195px" height="265px" _hover={{transform: mobileView?"scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >

              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
              </>
              }

            </ScrollMenu>

            <div style={{position: "relative"}}>
            <Heading size="md" mb={5}pt={10} >Upcoming</Heading>
            <ButtonGroup spacing={4}  style={{position:"absolute", top:45, right: mobileView?0: 20}} >
            <IconButton size="xs" isDisabled={!hasLessUp? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
            nextPageUp>1 && setNextPageUp(prev=>prev-1) }} />
            <Tooltip hasArrow label={`Page ${nextPageUp}/${UpCount}`}>
            <IconButton size="xs" isDisabled={!hasMoreUp? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
             nextPageUp < UpCount && setNextPageUp(prev=>prev+1) }} />
            </Tooltip>
            </ButtonGroup>
            </div>
            
            <ScrollMenu >
              {upcoming? upcoming.map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px",marginRight: mobileView?"0px": "25px", position:"relative", transform:mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5}>{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setYoutubePlay(true)
                    setTvShows(false)
                    setImageCover("https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.overview)
                    setLength("03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path)
                    setTitle(f.original_title)
                  }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" width="195px" height="265px" _hover={{transform:mobileView? "scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >

              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
              </>}

            </ScrollMenu>


            <div style={{position: "relative"}}>
            <Heading size="md" mb={5}pt={10} >Blockbuster Movies </Heading>
            <ButtonGroup spacing={4}  style={{position:"absolute", top:45, right:mobileView?0: 20}} >
            <IconButton size="xs" isDisabled={!hasLessBlock? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
            nextPageBlock>1 && setNextPageBlock(prev=>prev-1) }} />
            <Tooltip hasArrow label={`Page ${nextPageBlock}/${blockCount}`}>
            <IconButton size="xs" isDisabled={!hasMoreBlock? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
            nextPageBlock < blockCount && setNextPageBlock(prev=>prev+1) }} />
            </Tooltip>
            </ButtonGroup>
            </div>
 
            <ScrollMenu >
              {blockBuster? blockBuster.map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px",marginRight:mobileView?"0px": "25px", position:"relative", transform:mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5}>{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setYoutubePlay(true)
                    setTvShows(false)
                    setImageCover("https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.overview)
                    setLength("03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path)
                    setTitle(f.original_title)
                  }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" width="195px" height="265px" _hover={{transform:mobileView?"scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >

              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>

              </>}

            </ScrollMenu>


            <div style={{position: "relative"}}>
            <Heading size="md" mb={5}pt={10} >Top Rated</Heading>
            <ButtonGroup spacing={4}  style={{position:"absolute", top:45, right:mobileView?0: 20}} >
            <IconButton size="xs" isDisabled={!hasLessTop? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
            nextPageTop>1 && setNextPageTop(prev=>prev-1) }} />
            <Tooltip hasArrow label={`Page ${nextPageTop}/${topCount}`}>
            <IconButton size="xs" isDisabled={!hasMoreTop? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
            nextPageTop < topCount && setNextPageTop(prev=>prev+1) }} />
            </Tooltip>
            </ButtonGroup>
            </div>

            <ScrollMenu >
              {topRated? topRated.map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px", marginRight:mobileView?"0px": "25px", position:"relative", transform:mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5}>{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setYoutubePlay(true)
                    setTvShows(false)
                    setImageCover("https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.overview)
                    setLength("03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path)
                    setTitle(f.original_title)
                  }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" width="195px" height="265px" _hover={{transform:mobileView?"scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
              
              </>}

            </ScrollMenu>


        </div>
    )
}



export default TitleCards