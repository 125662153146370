import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { background } from "@chakra-ui/react"
import Netflix_Logo from "../Assets/Netflix_Logo.png"
import {Sidebar, Menu, MenuItem, SubMenu, Logo, Submenu} from "react-mui-sidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBraille, faBridge, faBuildingShield, faComment, faDashboard, faEnvelope, faFaceDizzy, faFaceSmile, faFilm, faGear, faGhost, faGun, faHeart, faHome, faList, faPeopleGroup, faUserGear, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faSpaceAwesome, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Chaticon from "@mui/icons-material/Chat"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import LiveTvIcon from "@mui/icons-material/LiveTv"
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay"
import HomeSharpIcon from "@mui/icons-material/HomeSharp"
import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp"






import { MyContext } from "../contexts/AuthContext";
import { useContext } from "react";
import { Button, ThemeProvider, createTheme } from "@mui/material";
import PlayListPlay from "@mui/icons-material/PlaylistPlay";


function SidebarNav({djangoQuotes}){

  const theme = createTheme()

  let {username, setUserName} = useContext(MyContext)
  let {genreResults, setGenreResults} = useContext(MyContext)
  let {genreNextPage, setGenreNextPage} = useContext(MyContext)


  let navigate = useNavigate()

    return(
        <div >
            <Sidebar mode="dark" width="100%" userimg="https://www.bk.com" designation="Welcome" textColor="white"  userName={username && username?.username} >
     
           <Menu subHeading="HOME">
            <Link to="/dashboard">  
            <MenuItem textFontSize="20px" icon={<HomeSharpIcon />}>Home</MenuItem> 
            </Link>
            <Submenu icon={<ManageAccountsSharpIcon />} textFontSize="20px" title="Genres">
            <Link to="/movie-genre/action" onClick={(e)=>{
              setGenreResults(28)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faGun} />} >Action</MenuItem>
            </Link>
            <Link to="/movie-genre/animation" onClick={(e)=>{
              setGenreResults(16)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faBridge} />}>Animation</MenuItem>
            </Link>
            <Link to="/movie-genre/comedy" onClick={(e)=>{
              setGenreResults(35)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faFaceSmile} />}>Comedy</MenuItem>
            </Link>
            <Link to="/movie-genre/drama" onClick={(e)=>{
              setGenreResults(18)
              setGenreNextPage(1)
            }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faFaceDizzy} />}>Drama</MenuItem>
            </Link>
            <Link to="/movie-genre/horror" onClick={(e)=>{
              setGenreResults(27)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faGhost} />}>Horror</MenuItem>
            </Link>
            <Link to="/movie-genre/romance" onClick={(e)=>{
              setGenreResults(10749)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faHeart} />}>Romance</MenuItem>
            </Link>
            <Link to="/movie-genre/science-fiction" onClick={(e)=>{
              setGenreResults(878)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faSpaceAwesome} />}>Science Fiction</MenuItem>
            </Link>
            <Link to="/movie-genre/fantasy" onClick={(e)=>{
              setGenreResults(14)
              setGenreNextPage(1)
              }}>
            <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faBuildingShield} />}>Fantasy</MenuItem>
            </Link>

          </Submenu>
        <HashLink smooth to="/movie-list#1" >
        <MenuItem  textFontSize="20px" icon={<PlayListPlay />}>My List</MenuItem>
        </HashLink>

        </Menu>

        <Menu subHeading="APPS" >
        <MenuItem textFontSize="20px" icon={<Chaticon />}>Chat</MenuItem>
        <MenuItem textFontSize="20px" icon={<CalendarTodayIcon />} >Calender</MenuItem>
        </Menu>

        <Menu subHeading="OTHERS">
        <Link to="/movie-series">
        <MenuItem  textFontSize="20px" icon={<LiveTvIcon />}>Series</MenuItem>
        </Link>
        <Link to="/films">
        <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faFilm} size="lg" />}>Films</MenuItem>
        </Link>
        <Link to="/new&popular"  >
        <MenuItem  textFontSize="20px" icon={<FontAwesomeIcon icon={faPeopleGroup}  />}>New & Popular</MenuItem>
        </Link>
        <Link to="#"  >
        <MenuItem badge="true" badgeContent={djangoQuotes? `${djangoQuotes.length}`:"0"} textFontSize="20px" icon={<FontAwesomeIcon icon={faEnvelope} size="lg" />}  >Notification</MenuItem>
        </Link>



        </Menu>

      </Sidebar>
        </div>
        
    )
}


export default SidebarNav