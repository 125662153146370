import { Heading, useBreakpointValue, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel, textDecoration } from "@chakra-ui/react"
import {faAngleLeft, faEye, faEyeSlash, faFileCircleXmark, faMultiply, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import React, {useState, useEffect, useContext} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import FloatingLabelInput from "react-floating-label-input"
import * as Yup from 'yup'

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';
import { MyContext } from "../contexts/AuthContext";
import useResetAuth from "../Components/ResetAuth";

import { useNavigate, Link } from "react-router-dom";
import axios from 'axios'

import netflixbg from "../Assets/netflix-image.jpg"
import netflixlogo from "../Assets/Netflix_Logo.png"





function Password({successLogin, setsuccessLogin, selected, setSelected, signPage, setSignPage}){

  useResetAuth()

  let navigate = useNavigate()
  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrormessage] = useState("")

  

  let onClose = () => {
    setAlerterrormessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    email: "",
  })


  let validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email required").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email must be a valid email adddress"),
  })


  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  // No authorization token required for djoser reset password endpoint
  let onSubmit = async(values, actions) =>{
    // let token = '450f1a988a818c59ed81784e02667a89b2e196d7'
    try{
      let response= await axios.post("https://api-0be9.onrender.com/djoser/users/reset_password/", values,{
        headers: {
          //'Authorization': `token ${token}`,
          'X-CSRFToken': csrftoken
        }
      })
      console.log("Password reset intructions sent to email")
      actions.setSubmitting(false)
      setAlertmessage(`If you have a Netflix account, you should receive a password recovery email sent to ${values.email} in a few minutes, Check your email`)
      setTimeout(() => {
       setAlertmessage(false)
      }, 9000);
      actions.resetForm()
    }

    catch (error){
      actions.setSubmitting(false)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
    }
  }


 
  
    

let inputStyle = {
  background: "#333",
  border: 0,
  color: "#fff",
  height: "50px",
  lineHeight: "50px",
  padding: "16px 20px",
  //marginBottom: "20px",
  fontSize:14,
}
 
let btnStyle = {
  //background: "#e50914",
  fontWeight: 700,
  border: "none",
  outline: "none",
  padding: "16px",
  fontSize: "16px",
  cursor: "pointer",
}


let {loadedImage, setLoadedImage} = useContext(MyContext)

let onLoad = (e)=>{
  setLoadedImage(true)
}

// let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)

// useEffect((e)=>{
//   setIsAuthenticated(false)

// }, [])


let mobileView = useBreakpointValue({
  base: true,
  lg: false
})



// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
  md: true,
})



    return(
      
        
          <div>
              <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8} backgroundImage={loadedImage? netflixbg:"none"} backgroundColor={loadedImage? "transparent":"rgba(0, 0, 0, 0.9)"} backgroundSize="cover" >  

              {/* Creating Overlay */}
              <div style = {{
                background: "rgba(0, 0, 0, 0.4)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>
              
              {/* Just a loading listener */}
              <img style={{display: "none"}} src={netflixbg} onLoad={onLoad} />

                     
            <div className="flex items-center justify-center min-h-screen flex-grow container" style={{zIndex:1}} >
              <div className="p-12 shadow-md rounded-lg max-w-md w-full" style={{background: "rgba(0, 0, 0, 0.9)", color: "#fff"}} >

              <Text ml={4} fontSize="small" fontWeight="bold" as={Link} to="/signin"  color="white" _active={{bg: "rgba(34,167,240,0.5)"}} ><FontAwesomeIcon icon={faAngleLeft} /> Back</Text> 
              <Heading mt={5}  style={{width:"90%"}} className="mx-auto" marginBottom={8} color="white" fontSize="3xl">Forgot Password.</Heading>
              {/* <Heading style={{width:"90%"}} className="mx-auto" fontSize="2xl">Unlimited movies, TV shows, and more.</Heading> */}
              <Text style={{width:"90%"}} className="mx-auto">Enter your email address, and we'll send you an email with instructions for how to reset your password</Text>



                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form style={{width:"90%"}} className="mx-auto">
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={5}  style={{marginBottom: "20px"}} >
                <Field as={Input} label="email" fontSize="sm" placeholder="Email address" name="email" id="email" style={inputStyle} />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>        
                
                          
                <Button type="submit" style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} isLoading={formik.isSubmitting} mt={4} width="full" color="white"  fontWeight="bold" >Send Instructions</Button>

              

                </Form>
                )}
                </Formik>

                  
              </div>
            </div>


                
                 {/* Creating logo*/}
                  {/*  <Image src={logo} position="absolute" top= "10%" left="5" boxSize="50px" /> */}
                  <Link to="/">
                  <Image src={netflixlogo} className="netflixlogo" zIndex={2} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%" left="3" width="auto" height="75px" /> 
                  </Link>

                
              
                </VStack>


                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

                <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#81C784">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>


               <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>


               
            </div> 


                  
  
    )
  }


export default Password