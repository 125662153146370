import React, {useState, useEffect} from "react";
import {Box, useBreakpointValue,Flex, HStack, Select,IconButton, Text, Link, VStack, SimpleGrid, InputGroup, InputLeftElement} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import  XIcon from "@mui/icons-material/X"
import {Formik, Form, Field, ErrorMessage} from "formik"

import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { faFacebook, faFacebookF, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";


const Footer = ({signPage}) => {

// Creating a visible up icon upon scroll up
let [scrollingUp, setScrollingUp] = useState(true)
let [initialScroll, setInitialscroll] = useState(0)
  
    useEffect((e) => {

      let handleScroll = () => {
        let verticalScroll = window.scrollY
        
        setScrollingUp(verticalScroll < initialScroll)
        setInitialscroll(verticalScroll)
      }
      
      document.addEventListener("scroll", handleScroll)
      
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    })


  let [initialValues, setInitialvalues] = useState({
    language: ""
  })



  
    
    let mobileView = useBreakpointValue({
      base: true,
      md: false
    })


    let tabletView = useBreakpointValue({
      md:true,
      lg:false
    })
  

  
    let style = {
    position: "fixed",
    bottom: "0",
    marginBottom: mobileView? 20 :10
  }



  
  return (
    <div style={{backgroundColor:"rgba(0, 0, 0, 1)", maxWidth:"full", position:"relative", zIndex:5, paddingBottom:40}} >
      <footer  >
        <Flex
          margin="0 auto"
          px={12}
          color="white"
          justifyContent="center"
          alignItems="center"
          maxWidth="1024px"
          height={16}
          paddingTop={10}
          paddingBottom={7}
        >
        {/*  <p>Ben • © 2023</p> */}
        <Text p={4} fontSize="lg"  textAlign="center" color="white" >Powered by <Link href="https://benasenso.site"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} isExternal >Ben</Link></Text>

 
        </Flex>

        <VStack color="white" px={mobileView? 5:12} >
        <HStack mb={10} width="16%" justifyContent="space-between" alignSelf="start" >
          <Link href="https://www.facebook.com/netflix" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
          <FontAwesomeIcon  size="2xl" icon={faFacebookF} />
          </Link>
          <Link href="https://www.instagram.com/netflix" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
          <FontAwesomeIcon size="2xl" icon={faInstagram} />
          </Link>
          <Link href="https://www.x.com/netflix" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
          <XIcon style={{fontSize:33}} />
          </Link>
          <Link href="https://www.youtube.com/netflix" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
          <FontAwesomeIcon size="2xl" icon={faYoutube} />
          </Link>

        </HStack> 
        <Text alignSelf="start" fontSize="xl" >Questions? Call <Link href="tel:+18445052993" style={{textDecoration:"underline"}} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>1-844-505-2993</Link></Text>
        <SimpleGrid columns={{base:1, md:4, lg:4}} alignSelf="start" gap={mobileView? 10:(tabletView? 20:40)}>
   {signPage?  
          <>
          <VStack alignItems="start" >
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>FAQ</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Privacy</Text>            
          </VStack> 
          
          <VStack alignItems="start" >
          <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Help Center</Text>
          <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Cookie Preferences</Text>            
          </VStack> 

          <VStack alignItems="start" >
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Netflix Shop</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Corporate Information</Text>            
          </VStack> 

          <VStack alignItems="start" >
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Terms of Use</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Do Not Sell or Share My Personal Information</Text>            
          </VStack> 
          </>
          
          :
          <>
         <VStack alignItems="start" >
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>FAQ</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>investor Relations</Text>            
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Buy gift cards</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Cookie Preferences</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Legal Notices</Text>
          </VStack>

          <VStack alignItems="start" >
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Help Center</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Jobs</Text>            
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Ways to Watch</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}> Corporate Information</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Only on Netflix</Text>
          </VStack>

          <VStack alignItems="start">
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Account</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Netflix Shop</Text>            
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Terms of Use</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Contact Us</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Do not sell or share my personal information</Text>
          </VStack>

          <VStack alignItems="start" >
            <Text as={RouterLink} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Media Center</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Redeem gift cards</Text>            
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Privacy</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Speed Test</Text>
            <Text as={RouterLink} mt={2} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Advert choices</Text>
          </VStack> 
          </>} 

        </SimpleGrid>

        <Box mr="auto" mt={10}>
        <Formik
        initialValues={initialValues}>
          {(formik)=>(
            <Form>
              <InputGroup>
              <InputLeftElement pr={2} children={<FontAwesomeIcon icon={faLanguage}/>} />
              <Field as={Select} width={(!mobileView&&!tabletView) && "10vw"} style={{textAlign: "center"}}  name="language" id="language">
                <option style={{color:"black"}}>English</option>
                <option style={{color:"black"}}>Espanol</option>
              </Field>
              </InputGroup>
            </Form>
          )}
        </Formik>
        </Box>

        <Text mt={10} color="gray" alignSelf="start">© 1997-2023 Netflix, Inc.</Text>

        </VStack>
        
        {scrollingUp && 
        <Box box-shadow="md"  >
        <HStack justifyContent="end" paddingRight={20} >
        <div class="iconbutton">
          <a onClick={(e)=>{
            window.scrollTo({
              top: 0,
              behaviour: true
            })

          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" width="63px">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>    
        </a>
        </div>

        </HStack>
        </Box>}
      </footer>

    </div>
  );
};
export default Footer;
