import { Container, Tooltip, Input, useBreakpointValue, FormControl, FormErrorMessage ,Link, Button, Collapse,TableContainer, Table, Tbody, Tr, Td, Box, Card, CardBody, CardFooter, Image,Heading, Center, Text, HStack, VStack, CardHeader, Avatar, SimpleGrid, Badge, Slider, Textarea, ButtonGroup, IconButton, Menu, MenuList, MenuItem, MenuButton, FormLabel, Popover, PopoverAnchor, PopoverContent, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverArrow, PopoverTrigger, Portal, PopoverFooter, Divider, Tabs, TabList, Tab, TabPanels, TabPanel, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Wrap, WrapItem, TabIndicator } from "@chakra-ui/react";
import { faAngleLeft, faAngleRight, faBold, faBucket, faComment, faDigitalTachograph, faFaceSmile, faFilm, faItalic, faListDots, faMobileScreen, faPencil, faReceipt, faReply, faStar, faThumbsUp, faUpload, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {Formik, Form, Field, ErrorMessage, FormikConsumer} from "formik"
import * as Yup from "yup"
import { Navigate, Link as RouterLink, useParams } from "react-router-dom";
import React, {useState, useEffect, useRef} from "react"
import { useContext } from "react";
import YoutubeFresh from "../Components/Video Skeleton";
import ClassicPostLoader from "../Components/Images Skeleton"
import FacebookStyle from "../Components/Facebook";
import GlobalSidebar from "../Components/Sidebar Skeleton";
import HeadBodyGrid from "../Components/ContentHeadBody";
import googlePlay from "../Assets/provider/googleplay.webp"
import appletv from "../Assets/provider/appletv.webp"
import amazon from "../Assets/provider/amazon.webp"
import youtube from "../Assets/provider/youtube.webp"
import primevideo from "../Assets/provider/primevideo.webp"
import microsoft from "../Assets/provider/microsoft.webp"
import appletvplus from "../Assets/provider/appletv+.webp"
import spectrum from "../Assets/provider/spectrum.webp"
import showmax from "../Assets/provider/showmax.webp"
import sunnext from "../Assets/provider/sunnext.webp"
import filmbox from "../Assets/provider/filmbox.webp"
import takflix from "../Assets/provider/takflix.webp"
import mubi from "../Assets/provider/mubi.webp"
import plex from "../Assets/provider/plex.webp"
import eventive from "../Assets/provider/eventive.webp"
import ctv from "../Assets/provider/ctv.webp"
import classix from "../Assets/provider/classix.webp"
import cultpix from "../Assets/provider/cultpix.webp"
import zee5 from "../Assets/provider/zee5.webp"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"



import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import InfiniteScroll from "react-infinite-scroll-component"
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import 'react-horizontal-scrolling-menu/dist/styles.css'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"


import "./Pages.css"
import ReactPlayer from "react-player"
import axios from "axios";
import { MyContext } from "../contexts/AuthContext";
import { format, intlFormat } from "date-fns";
import { faReadme } from "@fortawesome/free-brands-svg-icons";



function MoviePlay({moviePlay, setMoviePlay, movieId, setMovieId, youtubePlay, setYoutubePlay, descrip, setDecrip}){

    let [movieVideo, setMovieVideo] = useState()
    let [youtubeVideo, setYoutubeVideo] = useState()
    let [movieDetails, setMovieDetails] = useState()

    let youtubeUrl = "https://www.youtube.com/embed/".concat(movieDetails && (movieDetails.videos?.results[0]?.key ?? ""))    // youtubeVideo
    //let [clip, setClip] = useState()
    //let [youtubeName, setYoutubeName] = useState()
    //let [publishedDate, setPublishedDate] = useState()
    let [streaming, setStreaming] = useState()
    let [movieCredits, setMovieCredits] = useState()
    let [reviews, setReviews] = useState()
    let [reviewsCount, setReviewsCount] = useState(0)
    let [reviewsCountDjango, setReviewsCountDjango] = useState(0)

    let [doneSendingReview, setDoneSendingReview] = useState(false)


    // Apparently Formik manages its own internal state with setFieldValue(). setInitialValues won't work unless resetting all to initial
    let [initialValues, setInitialValues] = useState({
        message: "",
    })
    let [sliderValue, setSliderValue] = useState(7) 


    let validationSchema = Yup.object({
        message: Yup.string().required("Message is required").min(50, "minimum characters is 50")
    })
    


    
    // Trick to know - Remember you can use useParams() to pass the 
    // movieid in the browser url path and then extract it at the destination
    let {name} = useParams()
    let replaceNameHyphens = name? name.replace("-", " "): ""
    let capitalTitle = title => title.replace(/\b\w/g, char=>char.toUpperCase())
    let titleCap = capitalTitle(replaceNameHyphens)
    


    let {tvShows, setTvShows} = useContext(MyContext)
    let {username, setUserName} = useContext(MyContext)
    let {userImage, setUserImage} = useContext(MyContext)
    let [showMore, setShowMore] = useState(false)
    let [bigShow, setBigShow] = useState(false)
    let [formShow, setFormShow] = useState(false)
    let [hoveredIndex, setHoveredIndex] = useState(null)

    let [muiHover, setMuiHover] = useState(false)


   
    
    const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
        }
      };
      


    function getCSRFToken(e){
        let cookievalue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookievalue? cookievalue[1] : null
    }

    let csrftoken = getCSRFToken()



    const opt = {
        headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken': csrftoken
            }
        }
    
    
    useEffect((e)=>{
        setMoviePlay(true)
        // if (youtubePlay){
        //     setTvShows(false)
        //}

        let func = async(e)=>{
            try{
                let [response, creditsResponse, reviewsResponse, detailsResponse, streamingResponse, reviewDjango] = await axios.all([
               (!youtubePlay && !tvShows) && axios.get(`https://api-0be9.onrender.com/api/movies/${movieId}/`, opt), 
               
                youtubePlay && axios.get(`https://api.themoviedb.org/3/${tvShows?"tv":"movie"}/${movieId}/credits?language=en-US`, options),
                youtubePlay && axios.get(`https://api.themoviedb.org/3/${tvShows?"tv":"movie"}/${movieId}/reviews?language=en-US`, options),
                youtubePlay && axios.get(`https://api.themoviedb.org/3/${tvShows?"tv":"movie"}/${movieId}?append_to_response=images,videos,recommendations,keywords`, options),
                youtubePlay && axios.get(`https://api.themoviedb.org/3/${tvShows?"tv":"movie"}/${movieId}/watch/providers`, options),
                axios.get(`https://api-0be9.onrender.com/api/review/?search=${movieId}`, opt)
                ])
                const djangoRev = reviewDjango.data.results
             
                console.log("Movie video for id fetch successfully:", response.data)
                setMovieVideo(response.data)
                //youtubeVideo && console.log("Youtube video for id fetch successfully:", youtubeResponse)
                //setYoutubeVideo(tvShows? tvShowResponse.data.results[0].key: youtubeResponse.data.results[0].key)
                setMovieCredits(creditsResponse.data)
                setReviews(djangoRev? djangoRev.reverse().concat(reviewsResponse.data.results.reverse()): reviewsResponse.data.results.reverse())
                setReviewsCount(reviewsResponse? reviewsResponse.data.total_results:0)
                setReviewsCountDjango(reviewDjango? reviewDjango.data.count:0)
                setMovieDetails(detailsResponse.data)
                setStreaming(streamingResponse.data.results)
          
                youtubeVideo && console.log("Movie credits success:", creditsResponse.data)
                console.log("Movie details success:", detailsResponse.data)
                console.log("Movie Reviews success:", reviewsResponse.data.results)
                console.log("Streaming providers:", streamingResponse.data.results)

                console.log("reviews data:", djangoRev)

            }
            catch (error){
                console.error("Error getting video for movie id", error.message)
            }
        }

        func()

      }, [doneSendingReview, movieId, userImage])


    // useEffect((e)=>{

    //     setMoviePlay(true)
        
    //     let func = async(e)=>{
    //         try{
    //             let [response, reviewsResponse, detailsResponse] = await axios.all([
    //            (!youtubePlay && !tvShows) && axios.get(`https://api-0be9.onrender.com/api/movies/${movieId}/`, opt), 
               
              
    //             axios.get(`https://api-0be9.onrender.com/api/review/?search=${movieId}`, opt)
    //             ])
    //             const djangoRev = reviewsResponse.data.results
             
    //             console.log("Movie video for id fetch successfully:", response.data)
    //             setMovieVideo(response.data)
    //             //youtubeVideo && console.log("Youtube video for id fetch successfully:", youtubeResponse)
    //             //setYoutubeVideo(tvShows? tvShowResponse.data.results[0].key: youtubeResponse.data.results[0].key
    //             setReviews(reviewsResponse.data.results.reverse())
    //             console.log("User reviews here:", reviewsResponse.data.results.reverse())
    //             setReviewsCount(reviewsResponse? reviewsResponse.data.total_results:0)
    //             setMovieDetails(detailsResponse.data)
                
          
    //             console.log("Movie details success:", detailsResponse.data)
    //             console.log("Movie Reviews success:", reviewsResponse.data.results)

    //             console.log("reviews data:", djangoRev)

    //         }
    //         catch (error){
    //             console.error("Error getting video for movie id", error.message)
    //         }
    //     }

    //     func()

    //   }, [doneSendingReview, movieId, userImage])



      
     
      
      let [havingMore, setHavingMore] = useState()
      let [nextPage, setNextPage] = useState(1)

      let funcReview = async()=>{
        try{
            let response = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}/reviews?language=en-US&page=${nextPage}`, options)
            console.log("Next reviews fetched successfully", response.data.results)
            setReviews(prev => [...prev, response.data.results])
            setHavingMore(response.data.page < response.data.total_pages)
            setNextPage(prev => prev+1)
        }
        catch(error){
            console.error("Error getting next reviews:", error.message)
        }
      }


      let reviewSent = {
        movie_id: movieId,
        author: username.username,
        rating: sliderValue
    }

    let onSubmit = async(values, actions)=>{
        try{
            // let response = await axios.post(`https://api-0be9.onrender.com/api/review/?search=${movieId}`,{...reviewSent, content:values.message}, {
            let response = await axios.post(`https://api-0be9.onrender.com/api/review/`,{...reviewSent, content:values.message}, {

                headers:{
                    'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
                    'X-CSRFToken': csrftoken
                }
            })
            setDoneSendingReview(!doneSendingReview)
            toast.info("Review has been submitted")
            console.log("Reviews sent succcessfully", response)
            actions.resetForm()
            
        }
        catch(error){
            console.error("Error submitting review", error)
            toast.error("Error submitting review")
        }
    }



    
    let [reviewId, setReviewId] = useState()
    let [captureReview, setReviewcapture] = useState(null)
    // Accessing initialValues like a normal object
    initialValues.message = captureReview? captureReview: ""
    let global = useRef()

    useEffect((e)=>{
        if(captureReview){
            global.current("message", captureReview)
        }
    }, [captureReview])
  

   
    // Deleting Review
    let deleteReview = async(values, actions)=>{
        const loading= toast.loading("Deleting review...")
        try{
            let response = await axios.delete(`https://api-0be9.onrender.com/api/reviewupdel/${reviewId}/`, opt)
            setDoneSendingReview(!doneSendingReview)
            toast.dismiss(loading)
            toast.info("Review has been deleted")
            console.log("Review deleted succcessfully", response)           
        }
        catch(error){
            console.error("Error submitting review", error)
            toast.dismiss(loading)
            toast.error("Error deleting review")
        }
    }


     // Updating Review
    let UpdateReview = async(values, actions)=>{
        const loading= toast.loading("Updating review...")
        try{
            let response = await axios.put(`https://api-0be9.onrender.com/api/reviewupdel/${reviewId}/`, {...reviewSent, content:values.message}, opt)
            setDoneSendingReview(!doneSendingReview)
            toast.dismiss(loading)
            toast.info("Review has been updated")
            console.log("Review updated succcessfully", response)           
            actions.resetForm()
            setReviewcapture(null)
        }
        catch(error){
            console.error("Error updating review", error)
            toast.dismiss(loading)
            toast.error("Error updating review")
        }
    }


     



   


      let btnStyle = {
        //background: "#e50914",
        fontWeight: 700,
        border: "none",
        outline: "none",
        padding: "16px",
        fontSize: "16px",
        cursor: "pointer",
      }


    let provider = [{title:"Google play", img:googlePlay}, {title:"Apple TV Plus", img:appletvplus},{title:"Apple TV", img:appletv,}, {title:"Amazon", img:amazon},{title:"Youtube", img:youtube}, {title:"Amazon Prime Video", img:primevideo},
        {title:"Microsoft", img:microsoft}, {title:"Spectrum", img:spectrum}, {title:"Filmbox", img:filmbox}, {title:"Plex", img:plex}, {title:"Sun Next", img:sunnext}, {title:"Mubi", img:mubi}, {title:"Showmax", img:showmax}, {title:"Takflix", img:takflix},
        {title:"Eventive", img:eventive}, {title:"Ctv", img:ctv}, {title:"Classix", img:classix}, {title:"Cultpix", img:cultpix}, {title:"Zee5", img:zee5},
    ]
    

          

     //Creating a Hamburger Menu / base represents mobile & md represents desktop
     let mobileView = useBreakpointValue({
        base: true,
        md: false
      })
  
  
      let tabletView = useBreakpointValue({
        md: true,
        lg:false   
      })
  

    
    
      return(
        <div>
            <Box px={mobileView? 5: (tabletView? 7: 14)} maxW="full" pt={20} pb={20} minHeight="100vh" style={{background: "rgba(0, 0, 0, 1)", color: "#fff", }}>
                <HStack mt={10} width="100%" justifyContent="space-between">
                    <Heading as="h3" class={mobileView? "text-xl font-semibold": "text-3xl font-semibold"}>{movieVideo && movieVideo.title}  {movieDetails && titleCap}</Heading>
                    <Text to="/dashboard" onClick={(e)=>setMoviePlay(false)} class="text-gray-400 hover:text-white" as={RouterLink} _active={{bg: "rgba(34,167,240,0.5)"}} ><FontAwesomeIcon icon={faAngleLeft} /><span style={{marginLeft:5}}>Back to movies</span></Text>
                </HStack>
                
            {movieVideo || movieDetails? 
                <>
                <div class={movieDetails? "w-full h-screen mt-8": (movieVideo.video? "w-full h-auto mt-8":"w-full h-screen mt-8")} >
                    <ReactPlayer url={movieDetails? youtubeUrl: (movieVideo.video? movieVideo.video:movieVideo.youtube_video_url)} width="100%" height="100%" controls type="video/mp4" config={{
                        youtube:{
                            playerVars: {
                                //Disabling SSL validation
                                // This is only for develoopment purpose
                                origin: window.location.origin,
                                disablekb:1
                            }
                        }
                    }} />
                    {/* Your browser does not support the video tag. */}
                </div>
            
                <Box alignSelf="start" mt={6} mb={10}>
                    <Heading as="h3" class="text-xl font-semibold mb-2" >About the Movie</Heading>
                    <Text>{movieVideo && movieVideo.description}</Text>
                    <Text>{movieDetails && descrip}</Text>
                    {/* <Text>{youtubeVideo && `A ${youtubeName} of the movie ${clip} published on ${publishedDate}`}</Text> */}
                    <Text>{movieDetails && `A ${movieDetails.videos.results[0]?.name ?? "Short film"} of the movie ${movieDetails.videos.results[0]?.type ?? "clip"} published on ${movieDetails.videos.results[0]?.published_at.split("T")[0] ?? "unknown date"}`}</Text>
                </Box>
                </>
                    
                :
                <div  class="w-full mt-8"  style={{marginBottom: mobileView? -50: -100}} >
                 <YoutubeFresh  width="100%" height="auto" /> 
                </div>
                }




    {youtubePlay &&
    <>

                {/*Movie Credits */}

                <div style={{position: "relative"}}>
                <Heading size="md" mb={5} pt={10} >Top Billed Cast </Heading>
                </div>
                
                <ScrollMenu >
                {movieCredits? movieCredits.cast.map((f, index)=>{
                    return <div key={index} style={{width:"195px", height:"265px",marginRight: mobileView?"0px":"25px"}}>
                    <Card overflow="hidden"  transform={mobileView && "scale(0.9)"} >
                        <CardBody p={0} overflow="hidden" as={RouterLink} to={`/person/${movieId}/${name}`}  >
                    <div style={{ height:"181px" }}>
                    <Image onClick={(e)=>{
                        setMovieId(f.id)
                        setYoutubePlay(true)
                    }} src={"https://image.tmdb.org/t/p/w500" + f.profile_path} objectFit="cover" height="240px" width="195px"_hover={{transform:"scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                    </div>
                        </CardBody>
                        <CardFooter p={2} style={{background:"#18181b", color: "#fff"}}>
                        <VStack alignItems="start">
                        <Text fontWeight="bold">{f.name}</Text>
                        {f.known_for_department && <Text mt={-6} fontSize="sm" noOfLines={2} >{f.character? f.character:""}</Text> }
                        </VStack>
                        </CardFooter>
                    </Card>
                    </div>
                })
            
                :
                <>
                <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack> 
                </>    
                }
          
                {movieCredits? movieCredits.cast.length===0 &&
                <Center mt={0}>
                <VStack>
                <FontAwesomeIcon icon={faFilm} size={mobileView?"4x": (tabletView?"6x" : "9x")} />
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >{`No movie credits available for ${replaceNameHyphens}`}</Heading>
                </VStack>
                </Center>
                : ""}

                </ScrollMenu>






                {/*Reviews */}                
                <div >
                <Heading size="md" mb={0} pt={10} >Reviews {reviewsCount +reviewsCountDjango}</Heading>
                </div>

                <Container mt={10}  maxW="full" display="grid" gridTemplateColumns={mobileView? "1fr": "2fr 1fr"} gap={10}>
                
            
                <Box ml={-4} mr={mobileView&& -4} width={tabletView? "59vw":(mobileView? "91vw":"")} >
                {reviews?

                <InfiniteScroll dataLength={reviews.length} next={funcReview} hasMore={havingMore} 
                loader={<h4>Loading...</h4>} 
                endMessage={<p>All reviews loaded!</p>}>
                    
                <Collapse in={bigShow} startingHeight={300} >
                {reviews && reviews.map((f, index)=>{
                return <div key={index} style={{marginBottom:40}} onClick={(e)=>setHoveredIndex(index)} >
                <Card   style={{background:"#18181b", color: "#fff"}}>
                    <CardHeader>
                        <HStack spacing={5}>
                            {/* Use optional chaining operator (?.) to handle cases where imageUrl might not be defined or present. Optional chaining operator (?.) to safely access the property. This (?.) ensures if something doesn't exist the code doesn't throw an error */}
                            {/* Another different purpose one is nullish coalescing operator('??'). (Eg item.imageUrl ?? "") If item.imageUrl is 'null' or 'undefined', it will default to empty string "" */}
                           <Avatar src={f.user_profile?.image?f.user_profile.image: "https://image.tmdb.org/t/p/w500".concat(f.author_details?.avatar_path ?? '')} name={f.author} size={mobileView? "md":"lg"} />
                            <VStack alignItems="start">
                            <Text fontSize={mobileView? "md":"xl"} fontWeight="bold">A review by {f.author}</Text>
                            <HStack mt={-6} alignItems="start">
                                <Badge transform="scale(0.8)" borderRadius={8} fontSize="1.0em" bg="#1A365D" color="white" p={1} px={3} ><FontAwesomeIcon icon={faStar} /> {f.rating?f.rating: f.author_details.rating}0%</Badge>
                                <Text fontSize={mobileView? "xs":"sm"} my="auto" >Written by <span style={{fontWeight:"bold"}}>{f.author}</span> on {format(f.created_at.split("T")[0], "MMMM dd, yyyy") }</Text>
                            </HStack>

                            </VStack>
                            <Box ml="auto">
                             <Menu >
                        {username.username === f.author && <MenuButton as={IconButton} size={mobileView? "xs":"sm"} colorScheme="facebook" icon={<MoreVertIcon />} >Options</MenuButton> }
                            <MenuList color="black" onMouseEnter={(e)=>setReviewId(f.id)}>
                                <MenuItem onClick={(e)=>{
                                    setReviewcapture(f.content)
                                    setFormShow(true)
                                    let element = document.getElementById("review")
                                    if (element){
                                        setTimeout(() => {
                                            element.scrollIntoView({behavior: "smooth"})
                                        }, 50);
                                    }

                                    }} icon={<FontAwesomeIcon icon={faUpload} />}>Update</MenuItem>
                                <MenuItem onClick={deleteReview} icon={<FontAwesomeIcon icon={faBucket} />}>Delete</MenuItem>
                            </MenuList>
                            </Menu>
                            </Box>

                        </HStack>
                    </CardHeader>
                    
                    <CardBody pt={5}>
                        {/* text.length counts the number of characters in a text including spaces, punctuations */}
                        <Collapse startingHeight={f.content.length<500? 25:100} in={hoveredIndex===index&& showMore} >
                        {f.content.split(/(?:\r\n|\r|\n){2,}/).map(f=>(
                            <Text dangerouslySetInnerHTML={{__html: f}} />
                        ))}
                        </Collapse>
                    </CardBody>

                    <CardFooter pt={0} display="flex" flexDirection="column" >
                    {f.content.length>145 &&    <Link as={Link} to="#" width="fit-content" color="#319795" onClick={(e)=> hoveredIndex===index && setShowMore(!showMore)} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} colorScheme="facebook" style={{justifyContent: "center"}}> {showMore && hoveredIndex===index? "Show Less":"Read More"} 
                        <span style={{marginLeft:5}}><FontAwesomeIcon fontSize="small" icon={faAngleRight}/> </span>
                        </Link> }
                        
                        <HStack mx="auto" justifyContent="space-between" width={mobileView? "100%": (tabletView? "70%": "50%")} >
                            <Button variant="unstyled" leftIcon={<FontAwesomeIcon icon={faThumbsUp} />} color="white">Like</Button>
                            <Button variant="unstyled" leftIcon={<FontAwesomeIcon icon={faComment} />} color="white">Comment</Button>
                            <Button variant="unstyled" leftIcon={<FontAwesomeIcon icon={faReply} />} color="white">Share</Button>
                        </HStack>
                        
                    </CardFooter>
                </Card>

                </div>
                })}

            {reviews? reviews.length===0 &&
            <Center mt={0}>
              <VStack>
              <FontAwesomeIcon icon={faReadme} size={mobileView?"4x": (tabletView?"6x" : "9x")} />
              <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >{`We don't have any reviews for ${replaceNameHyphens}. Would you like to write one?`}</Heading>
              </VStack>
            </Center>
            : ""}
                </Collapse>

             


                <Box width="fit-content" pt={10} >
                <Link as={RouterLink} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
                <Heading size="md" onClick={(e)=>setBigShow(!bigShow)}  mb={0} >{bigShow? "Show Less Reviews":"Read All Reviews"}</Heading>
                </Link>
                </Box>


                </InfiniteScroll> 
                :
                <div>
                <Box className="facebookstyle" transform={tabletView&& "scaleX(1.0)"} style={{marginLeft: tabletView&& 0}} >
                <FacebookStyle />
                </Box>
            
                </div>}

             {reviews&&  <Divider h={3} /> }

                
        
                {/* Media */}                
                <Box  width={mobileView? "": (tabletView? "xl": "2xl")}>
                {reviews?
                <>
                  <Tabs >
                    <HStack alignItems={mobileView && "start"} flexDirection={mobileView && "column"} mb={0} pt={5} >
                    <Heading size="md" mt={2} mr={!tabletView&& 10} >Media</Heading>
    
                    <TabList style={{marginRight:tabletView&& -10}} width={tabletView&& "sm"} >
                        
                    {mobileView?    
                        <Wrap spacing="5px">
                        <WrapItem> <Tab >Most Popular </Tab> </WrapItem>
                        <WrapItem> <Tab>Videos  {movieDetails && movieDetails.videos.results.length}</Tab> </WrapItem>
                        <WrapItem> <Tab>Backdrops {movieDetails && movieDetails.images.backdrops.length}</Tab> </WrapItem>
                        <WrapItem> <Tab>Posters {movieDetails && movieDetails.images.posters.length}</Tab> </WrapItem>
                        <WrapItem> <Tab>Logos {movieDetails && movieDetails.images.logos.length}</Tab> </WrapItem>
                        </Wrap>                          
                        :
                        <>
                        <Tab >Most Popular </Tab> 
                        <Tab>Videos  {movieDetails && movieDetails.videos.results.length}</Tab>
                        <Tab>Backdrops {movieDetails && movieDetails.images.backdrops.length}</Tab> 
                        <Tab>Posters {movieDetails && movieDetails.images.posters.length}</Tab> 
                    {!tabletView&&  <Tab>Logos {movieDetails && movieDetails.images.logos.length}</Tab> }
                        </> }
                
                    </TabList>

                    </HStack>


                    <TabPanels>
                        <TabPanel>
                          <Box ml={-4} width={mobileView? "90vw": (tabletView? '57vw': "694px")} >
                            <ScrollMenu >
                                <div style={{width:"680px", height:"100%"} } >
                                <Image src={"https://image.tmdb.org/t/p/w500".concat(movieDetails && movieDetails.backdrop_path)} objectFit="cover" width="100%" height={375} />
                                </div>
                                <div style={{width:"280px", height:"100%"}} >
                                <Image src={"https://image.tmdb.org/t/p/w500".concat(movieDetails && movieDetails.poster_path)} objectFit="cover" width="100%" height={375} />
                                </div>   
                                <div style={{height:"100%", width:tabletView&& "100%"}} >
                                <ReactPlayer width={mobileView? "90vw": (tabletView? '57vw': "694px")} url={movieDetails && "https://www.youtube.com/embed/".concat(movieDetails.videos?.results[1]?.key ?? "")} height={375} controls type="video/mp4" />
                                {/* <ReactPlayer width={mobileView? "90vw": (tabletView? '57vw': "694px")} url={ movieVideo.video}  height={375} controls type="video/mp4" /> */}
                                </div>                                             
                            </ScrollMenu>
                            </Box>
                        </TabPanel>

                        <TabPanel>
                        <Box width={mobileView?"90vw":(tabletView?"57vw": "694px")}>
                            <ScrollMenu >
                                {movieDetails && movieDetails.videos.results.reverse().map((f, index)=>{
                               return <div  key={index} style={{height:"100%", width:tabletView&& "100%"}} >
                                <ReactPlayer width={mobileView? "90vw": (tabletView? '57vw': "694px")} url={"https://www.youtube.com/embed/".concat(f.key? f.key:"") } height={375} controls type="video/mp4" />
                                </div>
                                })}
                            </ScrollMenu>
                            </Box>
                        </TabPanel>

                        <TabPanel>
                            <Box ml={-4} width={mobileView? "90vw": (tabletView? "57vw":"694px")}>
                            <ScrollMenu >
                                {movieDetails && movieDetails.images.backdrops.map((f, index)=>{
                               return <div key={index} style={{width:"680px", height:"100%"}} >
                                <Image src={"https://image.tmdb.org/t/p/w500" + f.file_path} objectFit="cover" width="100%" height={375} />
                                </div>
                                })}
                            </ScrollMenu>
                            </Box>
                        </TabPanel>

                        <TabPanel>
                            <Box ml={-4} width={mobileView? "90vw": (tabletView? "57vw": "694px")}>
                         <ScrollMenu >
                                {movieDetails && movieDetails.images.posters.map((f, index)=>{
                               return <div key={index} style={{width:"280px", height:"100%"}} >
                                <Image src={"https://image.tmdb.org/t/p/w500" + f.file_path} objectFit="cover" width="100%" height={375} />
                                </div>
                                })}
                            </ScrollMenu>
                            </Box>
                        </TabPanel>

                        
                        <TabPanel>
                            <Box width={mobileView? "300px": "694px"}>
                         <ScrollMenu >
                                {movieDetails && movieDetails.images.logos.map((f, index)=>{
                               return <div key={index} style={{width:"280px", height:"100%"}} >
                                <Image class="my-auto" src={"https://image.tmdb.org/t/p/w500" + f.file_path} objectFit="cover" width="100%" height={175} />
                                </div>
                                })}
                            </ScrollMenu>
                            </Box>
                        </TabPanel>

                    </TabPanels>
                </Tabs>  

                <Box width={mobileView?"" :(tabletView? "57vw": "708px")}>
                <Divider h={3}  />
                </Box> 

                
                <Container width={tabletView&& "67vw"} transform={tabletView&& "scale(0.9)"} ml={tabletView&& -10}  maxW="full" backgroundSize="cover" position="relative" mt={!tabletView&& 10} backgroundImage={"https://image.tmdb.org/t/p/w500".concat(movieDetails && movieDetails.images.backdrops.at(-1).file_path)}  >
    
                <Box position="relative" pt={5}   zIndex={1}>
                <Heading size="lg"  >Streaming providers available for this movie includes </Heading>       
                <SimpleGrid mt={8} pb={10} class="my-auto" columns={{base:4, md:6, lg:7}} gap={10} >
                    {streaming && streaming?.AT?.rent?.map((f, index)=>{
                        return <div key={index}>
                        <Tooltip hasArrow label={f.provider_name}>
                        <Image src={"https://image.tmdb.org/t/p/w500" + f.logo_path} boxSize={50} cursor="pointer" />
                        </Tooltip>
                        </div>
                    })}
                    {provider.map((f, index)=>{
                        return <div key={index}>
                        <Tooltip hasArrow label={f.title}>
                        <Image src={f.img} boxSize={50} cursor="pointer" />
                        </Tooltip>
                        </div>
                    })}
                </SimpleGrid>
                </Box>
                  
                <div style = {{
                      background:  "linear-gradient(to right, rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.4))",
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 0,
                      pointerEvents: "none", //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
                      transition: "background 0.3s ease-in-out"
                    }}>    
                </div>

                </Container>  
                </>
                
                :
                <Box className="headbodygrid" mt={-10} mb={mobileView? -24:-16}  transform={tabletView&& "scaleX(0.8)"} style={{marginLeft:tabletView&& -75}} >
                <HeadBodyGrid />
                </Box> }
              
                

                 
                {reviews&&  <Box width={mobileView?"" :(tabletView? "57vw": "708px")}>
                <Divider h={3}  />
                </Box> } 

                 {/*Recommendation*/}                
                <Heading size="md" mb={5} pt={10} >Recommendations </Heading>
                <Box width={mobileView? "91vw": (tabletView?"57vw": "708px")}>
                <ScrollMenu >
                {movieDetails? movieDetails.recommendations.results.map((f, index)=>{
                    return <div key={index} style={{width:"195px", height:"265px", marginRight: mobileView? "0px":"25px"}}>
                    <Card overflow="hidden" transform={mobileView && "scale(0.9)"}>
                        <CardBody p={0} overflow="hidden" as={RouterLink}  to={"/movie/" .concat(f.title?.replace(/\s+/g, "-").toLowerCase()?? f.name?.replace(/\s+/g, "-").toLowerCase())}>
                    <div style={{ height:"181px" }}>
                    <Image onClick={(e)=>{
                        setMovieId(f.id)
                        setDecrip(f.overview)
                        setYoutubePlay(true)
                        setYoutubeVideo()
                        setReviews()
                        setMovieCredits()
                        setMovieDetails()
                    }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" height="240px" width="195px"_hover={{transform:"scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                    </div>
                        </CardBody>

                        <CardFooter display="flex" flexDirection="column" p={2} style={{background:"#18181b", color: "#fff"}}>        
                        <HStack width="100%" justifyContent="space-between">
                        <Text fontWeight="bold" noOfLines={1}>{f.original_title?? f.name}</Text>
                        <Text fontSize="sm"  >{`${Math.round(f.vote_average * 10)}%`}</Text>
                        </HStack>

                    {(f.release_date || f.first_air_date)  && <Text fontSize="sm" mt={-4}>{format(f.release_date?? f.first_air_date, "MMM dd yyyy")}</Text> } 
                
                        </CardFooter>
                    </Card>
                    </div>
                })
            
                :
                <>
                <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
                </>}

                {movieDetails? movieDetails.recommendations.results.length===0 &&
                <Center mt={0}>
                <VStack>
                <FontAwesomeIcon icon={faVideo} size={mobileView?"4x": (tabletView?"6x" : "9x")} />
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >{`No movie recommendations available for ${replaceNameHyphens}`}</Heading>
                </VStack>
                </Center>
                : ""}

                </ScrollMenu>
                </Box>  

                

                </Box>
                
                </Box>
                
            



                {reviews? 
                <VStack alignItems="start"  >
                <Text fontSize="xl" fontWeight="bold">Status</Text>
                <Text mt={-5} pt={0}>{movieDetails? movieDetails.status: "Released"}</Text>

            {movieDetails?.networks?.at(0)?.logo_path &&    
                <>
                <Text fontSize="xl" fontWeight="bold">Networks</Text>
                <Image mt={-5} pt={0} src={"https://image.tmdb.org/t/p/w500"+ movieDetails?.networks?.at(0)?.logo_path?? ""} /> 
                </>}

            {movieDetails?.type &&  
                <>
                <Text fontSize="xl" fontWeight="bold">Type</Text>
                <Text mt={-5} pt={0}>{movieDetails?.type?? ""}</Text>
                </>}

                <Text fontSize="xl" fontWeight="bold">Original Language</Text>
                <Text mt={-5} pt={0}>{movieDetails? movieDetails.spoken_languages[0].name: "English"}</Text>

                <Text fontSize="xl" fontWeight="bold">Budget</Text>
                <Text mt={-5} pt={0}>{movieDetails? new Intl.NumberFormat("en-US", {style:"currency", currency:"USD",minimumFractionDigits:0}).format(movieDetails?.budget?? 0).replace('$','$ '): "$ 100,000,000"}</Text>

                <Text fontSize="xl" fontWeight="bold">Revenue</Text>
                <Text mt={-5} pt={0}>{movieDetails? new Intl.NumberFormat("en-US", {style:"currency", currency:"USD",minimumFractionDigits:0}).format(movieDetails?.revenue?? 0).replace('$','$ '): "$ 395,707,401.00"}</Text>

                <Text fontSize="xl" mt={5} fontWeight="bold">Keywords</Text>
    
                <SimpleGrid columns={{base:3, md:3, lg:3}} gap={2}>
                {(movieDetails && !tvShows) && movieDetails.keywords.keywords.map((f, index) =>{
                    return  <div key={index} style={{cursor:"pointer"}}>
                    <RouterLink to={`/keyword/${f.id}/${f.name.replace(" ", "-")}/movie`} >
                    <Chip size="small" onClick={(e)=>setMuiHover(true)} label={f.name} color="primary" />
                    </RouterLink>
                    </div> 
                }) }

                {(movieDetails && tvShows) && movieDetails.keywords.results.map((f, index) =>{
                    return  <div key={index} style={{cursor:"pointer"}}>
                    <RouterLink to={`/keyword/${f.id}/${f.name.replace(" ", "-")}/movie`} >
                    <Chip size="small" onClick={(e)=>setMuiHover(true)} label={f.name} color="primary" />
                    </RouterLink>
                    </div> 
                }) }
                </SimpleGrid>

                
                
                
                <Divider h={3} />
                <Text mt={5} width="max-content">Content Score</Text>
                <TableContainer mt={-5}>
                      <Table variant="striped" colorScheme="teal" color="black">
                        <Tbody>
                          <Tr>
                            <Td>100</Td>
                          </Tr>
                          <Tr color="white">
                            <Td>Yes! Looking good</Td>
                          </Tr>

                        </Tbody>
                      </Table>
                </TableContainer>                 


                <Text fontSize="xl" mt={5} fontWeight="bold">Production Companies</Text>
                {movieDetails&& movieDetails.production_companies.map((f, index)=>{
                    return <VStack key={index} alignItems="start" >
                        <Avatar src={"https://image.tmdb.org/t/p/w500"+ f.logo_path} size="lg" name={f.name} />
                        <Text fontSize="xl" fontWeight="bold" noOfLines={1}>{f.name}</Text>
                    </VStack>
                })}

                <Text fontSize="xl" mt={5} fontWeight="bold">Production Countries</Text>
                {movieDetails&& movieDetails.production_countries.map((f, index)=>{
                    return <VStack key={index} alignItems="start" >
                            <Text mt={-5} pt={0}>{f.name}</Text>                            
                    </VStack>
                })}

                <Text fontSize="xl" fontWeight="bold">Tagline</Text>
                <Text mt={-5} pt={0}>{movieDetails && movieDetails.tagline !==""? movieDetails.tagline: `No tagline available for ${replaceNameHyphens}`}</Text>




                    <Box width="full" mb={10}  mt={5} id="review" position="sticky" top={32} >
                    <Collapse in={formShow}>
                    <Heading size="md">A review by {username?.username}</Heading>
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={captureReview? UpdateReview: onSubmit}>
                        {(formik)=>{
                            global.current = formik.setFieldValue
                                    
                          return  <Form>
                                <FormControl isInvalid={!!formik.errors.score && formik.touched.score} >
                                <Tooltip  >
                                <Field as={Slider} name="score" id="score" />
                                </Tooltip>
                                <FormErrorMessage>
                                <ErrorMessage name="score" />
                                </FormErrorMessage>
                                </FormControl>
                                
                                <Box p={4} mb={5} mx={2} >
                                <Slider defaultValue={7} min={1} max={10} onChange={(val)=> setSliderValue(val)} >                                
                                <SliderMark mt="2" ml="-2.5" fontSize="sm" value={3}>30%</SliderMark>
                                <SliderMark mt="2" ml="-2.5" fontSize="sm" value={5} >50%</SliderMark>
                                <SliderMark mt="2" ml="-2.5" fontSize="sm" value={7} >70%</SliderMark>
                                <SliderMark value={sliderValue} textAlign="center" bg="blue.500" color="white" mt="-10" ml="-5" w="12" >
                                    {sliderValue}0%
                                </SliderMark>

                                <SliderTrack>
                                <Tooltip hasArrow arrowSize={15} label="Rate the movie" >
                                <SliderFilledTrack />
                                </Tooltip>
                                </SliderTrack>
                                <SliderThumb />

                                </Slider>
                                </Box>

                                {/* <HStack mb={5}>
                                <Popover >
                                    <PopoverTrigger>
                                        <IconButton size="sm" icon={<FontAwesomeIcon icon={faFaceSmile} />} />
                                    </PopoverTrigger>
                            
                                    <PopoverContent backgroundColor="transparent" border={0} >
                                        <PopoverArrow />
                                            <Box mt={-10} width="sm" height="sm" transform="scale(0.7)">                                
                                            <Picker data={data} theme="dark" onEmojiSelect={(emoji)=>formik.setFieldValue("message", formik.values.message + emoji.native)} navPosition="bottom" /> 
                                            </Box>
                                        </PopoverContent>
                                </Popover>

                                <IconButton size="sm" icon={<FontAwesomeIcon icon={faBold} onClick={(e)=> formik.setFieldValue("message", "<b>"+ formik.values.message + "</b>")} />} />
                                <IconButton size="sm" icon={<FontAwesomeIcon icon={faItalic} onClick={(e)=> formik.setFieldValue("message", "<i>"+ formik.values.message + "</i>")} />} />

                                </HStack> */}

                               

                                {/* <FormControl isInvalid={!!formik.errors.message && formik.touched.message} >             
                                <Field as={Textarea} name="message" style={{background:"#18181b", color: "#fff"}} id="message" placeholder="You can start writing your review here." minHeight={250} />
                                <FormErrorMessage>
                                <ErrorMessage name="message"/>
                                </FormErrorMessage>

                                </FormControl> */}
                                <Box mb={5}  >                                    
                                <FormControl isInvalid={!!formik.errors.message && formik.touched.message} >             
                                <Box width="small" >
                                <ReactQuill name="message" id="message" placeholder="You can write your review here" as={Field} theme="snow" style={{ background:"#18181b",}} value={formik.values.message} onChange={(message)=>{formik.setFieldValue("message", message)}} 
                                modules={{
                                    toolbar:[
                                        [{'header':'1'}, {'header':'2'}, {'font':[] } ],
                                    
                                        [{'list':'ordered'}, {'list':'bullet'}, {'indent':'-1'}, {'indent': '+1'}],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        ['link', 'image', 'video'],
                                        ['clean'],
                                        ['emoji']
                                    ]
                                }} 
                                formats={[
                                'header', 'font',
                                'list', 'bullet',
                                'bold', 'italic', 'underline', 'strike',
                                'link', 'image', 'emoji'  
                                ]}
                                />
                                </Box>
                                 <FormErrorMessage>
                                <ErrorMessage name="message"/>
                                </FormErrorMessage>
                                </FormControl>
                                </Box>


                                <HStack justifyContent="end">
                                <ButtonGroup spacing={4} size="sm" mt={4} >
                                    <Button onClick={(e)=>{
                                        setFormShow(false)
                                        setReviewcapture(null)
                                    }} background="#6d6d6eb3"  border="3px solid white" color="white" fontWeight="bold"  _hover={{color: "black", background: "#EDF2F7"}}> Cancel</Button>
                                    <Button type="submit"  isLoading={formik.isSubmitting} style={btnStyle} bg="#e50914"_hover={{background:"red.500"}}  color="white"  fontWeight="bold">Submit</Button>
                                </ButtonGroup>
                                </HStack>

                            </Form>
                        }}
                    </Formik>
                    </Collapse>

            
                    {!formShow && 
                    <Center>
                    <Button onClick={(e)=>setFormShow(!formShow)} leftIcon={<FontAwesomeIcon icon={faPencil} />} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} width="full" color="white" fontWeight="bold" >WRITE REVIEW</Button> 
                    </Center>}

                    </Box>
                    
                    
            

                </VStack>

                :
                // <Box className="globalsidebar" transform="scaleX(0.5)" style={{marginLeft:tabletView&& -280}}>
                <Box mx={mobileView&& "auto"} className="globalsidebar" mt={mobileView&& 5} >
                <GlobalSidebar />
                </Box>
                }

                </Container>

    </>
            }
             
                
            </Box>




        </div>
    )
}



export default MoviePlay;