import { Heading, Center,Input, InputLeftElement, Select, FormErrorMessage,Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,useBreakpointValue, CircularProgress, CardBody, Card, HStack, Container, Text, VStack, Button, Box, Image, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel, textDecoration, SimpleGrid, position, FormControl, Flex } from "@chakra-ui/react"
import {faAngleRight, faCheckCircle, faCheckDouble, faLanguage, faCheckSquare, faCheckToSlot, faCircleStop, faCircleXmark, faClose, faClosedCaptioning, faCloudDownload, faCloudDownloadAlt, faDownload, faEye, faEyeSlash, faFileCircleXmark, faFileDownload, faMultiply, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Formik, Form as FmForm, ErrorMessage, Field} from "formik"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Form from "react-bootstrap/Form"

import * as Yup from "yup"


import React, {useState, useEffect, useContext} from "react"

import useResetAuth from "../Components/ResetAuth";

import { useNavigate, Link } from "react-router-dom";
import axios from 'axios'

import netflixbg from "../Assets/netflix-image.jpg"
import netflixlogo from "../Assets/Netflix_Logo.png"
import netflix from "../Assets/netflix.jpg"
import tv from "../Assets/tv.png"
import net from "../Assets/NetLogo.gif"
import netSports from "../Assets/netSports.gif"
import pic from "../Assets/pic.jpg"
import photo from "../Assets/photo.png"
import device from "../Assets/device-pile.png"
import poster from "../Assets/Internet.png"
import { MyContext } from "../contexts/AuthContext";








function Landing({successLogin, setsuccessLogin, selected, setSelected,  signPage, setSignPage}){

useResetAuth()


let navigate = useNavigate()

let [progress, setProgress] = useState(true)
let [check, setCheck] = useState(false)
let [download, setDownload] = useState(false)

let [initialValues, setInitialvalues] = useState({
  email:  ""
})

let validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email must be a valid email adddress")
})

let onSubmit = (values, actions)=>{
  
    setTimeout((e) => {
      localStorage.setItem("userEmail", JSON.stringify(values))
      navigate("/signup", {replace: true})
      actions.setSubmitting(false)      
    }, 2000);
    console.log("user email stored ")
}


useEffect((e)=>{
  setSignPage(false)

  let func = (e)=>{
    setTimeout(() => {
      setProgress(false)
      setCheck(true)
    }, 3000);
    
    setTimeout(() => {
      setCheck(false)
      setDownload(true)
    }, 6000);
    
    setTimeout(() => {
      setDownload(false)
      setProgress(true)
    }, 9000);    

  }

  func()

  setInterval(() => {
    func()
  }, 9000);

}, [])


let faqs = [
  {
    question: "What is Netflix?",
    answer: "Netflix is a streaming service that offers a wide variety of award-winning TV shows, movies, anime, documentaries,\
    and more on thousands of internet-connected devices. <br/><br/> You can watch as much as you want without a single commercial-all for one\
    low monthly price. There's always something new to discover and new TV shows and movies are added every week!"
  },
  {
    question: "How much does Netflix cost?",
    answer: "Watch Netflix on your smartphone tablet, Smart TV, laptop, or streaming device, all for one fixed monthly fee. Plans range from US$2.99 to US$9.99 a month. No \
    extra costs, no contracts."
  },
  {
    question: "Where can I watch?",
    answer: "Watch anywhere, anytime. Sign in with your Netflix account to watch instantly on the web at netflix.com from your personal computer or on any internet-connected device \
    that offers the Netflix app, including smart TVs, smartphones, tablets, streaming media players and game consoles. <br/><br/> You can also download your favourite shows with the IOS or \
    Android app. Use downloads to watch while you're on the go and without an internet connection. Take Netflix with you anywhere."
  },
  {
  question: "How do I cancel?",
  answer: "Netflix is flexible. There are no pesky contracts and no commitments. You can easily cancel your account online in two clicks. There are no cancellation fees-start or stop your account anytime."
  },
  {
    question: "What can I watch on Netflix?",
    answer: "Netflix has an extensive library of feature films, documentaries, TV shows, anime, award-winning Netflix originals, and more. Watch as much as you want, anytime you want."
  },
  {
    question: "Is Netflix good for kids?",
    answer: "The Netflix kids experience is included in your membership to give parents control while kids enjoy family-friendly TV shows and movies in their own space.\
    <br/><br/> Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don't want kids to see."

  }
]
 

let inputStyle = {
  //background: "#333",
  border: 0,
  color: "#fff",
  height: "50px",
  lineHeight: "50px",
  padding: "16px 20px",
  //marginBottom: "20px",
  fontSize:14,
}

 
let btnStyle = {
  //background: "#e50914",
  fontWeight: 700,
  border: "none",
  outline: "none",
  padding: "16px",
  fontSize: "16px",
  cursor: "pointer",
  paddingRight:"30px",
  paddingLeft:"30px"

  }


  let {landLoadedImage, setlandLoadedImage} = useContext(MyContext)
  let [loadedImage2, setLoadedImage2] = useState(false)


  let onLoad = (e)=>{
    setlandLoadedImage(true)
  }

  let onLoad2 = (e)=>{
    setLoadedImage2(true)
  }



  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })

 

  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg:false
  })



    return(
      
        
          <div>
              <VStack justifyContent="center" maxWidth="full" minHeight={tabletView? "40vh":"60vh"} backgroundImage={landLoadedImage? netflix:"none"} backgroundColor={landLoadedImage? "transparent":"rgba(0, 0, 0, 1)"} backgroundSize="cover"  spacing={8} >  

              {/* Creating Overlay */}
              <div style = {{
                //background: "rgba(0, 0, 0, 0.4)",
                background: "linear-gradient(to right, rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.7))",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>

              {/* Just a loading listener */}
              <img style={{display: "none"}} src={netflix} onLoad={onLoad} />

    
          
              <VStack pt={40} pb={20} position="relative"  style={{zIndex:1, color:"white"}} > 
                     
              <Center position="relative"  style={{color:"white", zIndex:1}} textAlign="center" width={tabletView? "2xl": (mobileView?"xs" :"4xl")}>
              <Box width={mobileView&& "xs"} >
              <Heading as= "h1"  size={tabletView? "xl":(mobileView?"lg": "2xl")} style={{lineHeight:1.3 }} >Unlimited films, TV programmes and more</Heading>
              </Box>
              </Center>
        
               {/* <Heading textAlign="center" style={{lineHeight:1.3 }} as= "h1" size={tabletView? "2xl":(mobileView?"xl":"3xl")}>more</Heading>    */}
               <Text textAlign="center" fontSize= {tabletView? "xl": (mobileView?"md": "2xl")}>WATCH ANYWHERE. CANCEL ANYTIME.</Text> 
            
              <Center width={mobileView&& "xs"}>
               <Text mt={5} fontSize={tabletView? "xl":(mobileView?"md": "2xl")}>Ready to watch? Enter your email to create or restart your membership.</Text>
              </Center> 

              
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>

                {(formik)=>(
                  <FmForm >
              
                      <Center ml={!mobileView && 4}>
                      <Box display={!mobileView && "flex"}  width={!mobileView&& "91%"} justifyContent={!mobileView&& "space-between"}  >
                      <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                      
                      <Box  width={mobileView||tabletView && "xs"}   >
                      {/* <Field as={Input} bg="gray.700"  style={inputStyle} name="email" id="email" width={mobileView? "full": "md"} color="black" placeholder="Email address" /> */}
                      <FloatingLabel style={{transform:"scale(0.85)", marginTop:-4, width:mobileView?"95vw":(tabletView? "55vw":"45vw")}} label="Email address" >
                      <Form.Control size={mobileView&& "lg"} isInvalid={!!formik.errors.email && formik.touched.email} as={Field} isValid={!formik.errors.email&&formik.touched.email} style={{ background:"#1b1b1b", color:"white"}} name="email" id="email" placeholder="name@example.com" />
                      </FloatingLabel>
                      </Box>
                  
                      <Center mb={(mobileView&&formik.errors.email&&formik.touched.email)? 5:0} position="relative" >
                      <FormErrorMessage top={mobileView?0:1} position="absolute"  >
                      <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                      <ErrorMessage  name="email"  /> 
                      </FormErrorMessage>
                      </Center>
                    
                      </FormControl>
                      
                      <Box mx={mobileView&& "auto"} width="xs" textAlign={mobileView&& "center"} ml={(!mobileView&&!tabletView) && -4} >
                      <Button type="submit"  mt={mobileView? 5:1} bg="#e50914" _hover={{background:"red.500"}}  transform="scale(1.25)" style={btnStyle} isLoading={formik.isSubmitting} color="white"  fontWeight="bold" ><span style={{paddingRight: 5}}>Get Started</span> <FontAwesomeIcon icon={faAngleRight} /> </Button>
                      </Box>
                      
                      </Box>
                      </Center>
                      
                  </FmForm>
                )}

              </Formik> 
              
            

                         
               </VStack> 
            
              

           
                 {/* Creating logo*/}  
                   <Link to="/">
                  <Image transform={mobileView&& "scale(0.7)"} zIndex={2}  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} src={netflixlogo} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%" left={mobileView?"-5": "3"} width="auto" height="75px"  /> 
                  </Link> 

                  
                  <HStack gap={6} zIndex={2} transform={mobileView&& "scale(0.7)"} position="absolute" top={mobileView? "0.1%":"0%"} right={mobileView?"-2": "8"}>
                  
                  <Formik
                  initialValues={initialValues}>
                    {(formik)=>(
                      <Form>
                        <InputGroup  mt={4} >
                        <InputLeftElement  color="white" pr={2} children={<FontAwesomeIcon icon={faLanguage}/>} />
                        <Field color="white" as={Select} width={!mobileView&&!tabletView && "10vw"} style={{textAlign: "center"}}  name="language" id="language">
                          <option style={{color:"black"}}>English</option>
                          <option style={{color:"black"}}>Espanol</option>
                        </Field>
                        </InputGroup>
                      </Form>
                    )}
                  </Formik>
                
                  <Button onClick={(e)=> navigate("/signin", {replace:true})} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} mt={4} color="white"  fontWeight="bold"  width="auto" >Sign In</Button>
            
                  </HStack> 
              
            
              </VStack>
          


                <Container borderTop="5px solid grey" borderBottom="5px solid grey" position="relative" maxW="full" minHeight={tabletView? "20vh":"30vh"} style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}}>
                <SimpleGrid columns={{base:1, md:2, lg:2}} ml={mobileView? 5:10} mr={mobileView? 5:10} className="my-auto" >
                <Box pt={mobileView&& 10} className="my-auto">
                <Heading style={{lineHeight:1.3 }} as= "h1" size={tabletView?"xl": (mobileView? "xl": "3xl")} >Enjoy on your TV</Heading>
                <Text fontSize={mobileView?"xl":"2xl"}>Watch on your Smart TVs, Playstation, Xbox, ChromeCast, Apple TV, Blu-ray players, and more.</Text>
                </Box>

                <Box className="tv-container"  >
             

                <Image position="relative" className="tv-image" src={tv} style={{zIndex: 1}} />
                <Image className="screen-image" src={net} />
                </Box>
                </SimpleGrid>

                </Container>   


                <Container borderBottom="5px solid grey" position="relative" maxW="full" minHeight={tabletView? "20vh":"30vh"}  style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}}>
                <SimpleGrid columns={{base:1, md:2, lg:2}} ml={mobileView? 5:10} mr={mobileView? 5:10} className="my-auto" > 
                <Box pt={mobileView&& 10}  mb={mobileView&& -10}  className="device-container" >
              
                <Image className="device" src={device} style={{zIndex: 2}} />
                <Image   style={{zIndex:0}} src={netSports} className="netsport" /> 
                 
                
                </Box>

                <Box mt={20} >
                <Heading style={{lineHeight:1.3 }} as= "h1" size={tabletView?"xl": (mobileView? "xl": "3xl")}  >Watch everywhere</Heading>
                <Text fontSize={mobileView?"xl":"2xl"}>Stream unlimited movies and TV shows on your phone, tablet, laptop, and TV.</Text>
                </Box>
                </SimpleGrid>

                </Container>   
 
                <Container borderBottom="5px solid grey" position="relative" maxW="full" minHeight={tabletView? "20vh":"30vh"}  style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}}>
                <SimpleGrid   pt={tabletView? 10:(mobileView? 10:"")}  columns={{base:1, md:2, lg:2}} ml={mobileView? 5:10} mr={mobileView? 5:10} className="my-auto" >
                <Box  className="my-auto">
                <Heading style={{lineHeight:1.3 }} as= "h1"  size={tabletView?"xl": (mobileView? "xl": "3xl")} >Create profiles for kids</Heading>
                <Text fontSize={mobileView?"xl":"2xl"}>Send kids on adventures with their favorite characters in a space made just for them-free with your membership.</Text>
                </Box>                
                
                <Box>
                <Image src={photo} />
                </Box>

                </SimpleGrid>

                </Container>   


                <Container borderBottom="5px solid grey" position="relative" maxW="full" minHeight={tabletView? "20vh":"30vh"}  style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}}>
                <SimpleGrid pt={tabletView? 10:(mobileView? 10:"")}  columns={{base:1, md:2, lg:2}} ml={mobileView?5: 10} mr={mobileView?5: 10} className="my-auto" >
                <Box className="card-container" >
                <Image src={pic} position="relative" />
            {loadedImage2?  <Card width="sm" className="card"  mb={-20} transform= {tabletView&& "scale(0.7)"}  style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}} border="3px solid grey" borderRadius={14} >
                  <CardBody>
                    <HStack mr={2} fontWeight="semibold" >
                      <HStack >
                        <Image src={poster} boxSize={90} width="30%" />
                        <Box width="full" ml={1} >
                        <Text fontSize="xl">Stranger Things</Text>
                        <Text color="blue.500" mt={-5}>Downloading...</Text> 
                        </Box>
                      </HStack>
                      <VStack style={{marginLeft:"auto"}}>
                    {progress?  <CircularProgress isIndeterminate /> 
                    :
                    (check? <FontAwesomeIcon icon={faCheckCircle} size="2xl" color="#3182ce" />
                    :<FontAwesomeIcon icon={faDownload} size="2xl" />  )}                    
                      </VStack>
                    </HStack>
                  </CardBody>
                </Card> : ""}
                </Box>

                <Box className="my-auto">
                <Heading style={{lineHeight:1.3 }} as= "h1"  size={tabletView?"xl": (mobileView? "xl": "3xl")}  >Download your shows to watch offline</Heading>
                <Text  fontSize={mobileView?"xl":"2xl"}>Save your favourites easily and always have something to watch on a plane, train or submarine...</Text>
                </Box>
                </SimpleGrid>

                {/* Just a loading listener */}
                <img style={{display: "none"}} src={pic} onLoad={onLoad2} />

                </Container>     



                <Container  pb={20} borderBottom="5px solid grey" position="relative" maxW="full" minHeight={tabletView? "30vh":"60vh"}  style={{background: "rgba(0, 0, 0, 1)", color: "#fff"}} paddingLeft= {mobileView? 25:50} paddingRight= {mobileView? 25:50} >
                <Center>
                <Heading mt={14} mb={10} style={{lineHeight:1.3 }} as= "h1" size={tabletView?"xl": (mobileView? "xl": "3xl")} >Frequently Asked Questions</Heading>
                </Center>

                <Accordion allowMultiple={false} allowToggle={true}>
                  {faqs.map((faq, index) =>{
                  return <AccordionItem key={index}>
              
                  <AccordionButton mt={5} _expanded={{bg: "#e50914", color: "white"}} fontSize={mobileView?"lg": "xl"} justifyContent="space-between" bg="#18181b" fontWeight="bold">              
                      {faq.question}
                    <AccordionIcon />
                  </AccordionButton>
                  
                  <AccordionPanel dangerouslySetInnerHTML={{__html: faq.answer}} pb={5} fontSize={mobileView?"lg": "xl"}></AccordionPanel>

                </AccordionItem>
                })}

                </Accordion>  

                <Center>
                <Text mt={20} fontSize={mobileView?"xl": "2xl"}>Ready to watch? Enter your email to create or restart your membership.</Text>
                </Center>

                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>

                {(formik)=>(
                  <FmForm >
                    
                      {/* <Flex flexDirection={mobileView && "column"} justifyContent="space-between"> */}
                     <Center ml={(!tabletView&&!mobileView) && -14}>
                      <Box display={!mobileView&& "inline-flex"} ml={tabletView&& -10} >
                      <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                      <Center>
                      {/* <Field as={Input} style={inputStyle} name="email" id="email" bg="gray.700" width={mobileView?"xs": "md"} color="black" placeholder="Email address" /> */}
                      
                      <Box width={mobileView&& "95vw"} >
                      <FloatingLabel style={{transform:"scale(0.85)", marginTop:-4, width:mobileView?"":(tabletView? "55vw":"45vw")}} label="Email address" >
                      <Form.Control  size={mobileView&& "lg"} isValid={!formik.errors.email && formik.touched.email} isInvalid={!!formik.errors.email&&formik.touched.email} as={Field} style={{ background:"#1b1b1b", color:"white"}} name="email" id="email" placeholder="name@example.com" />
                      </FloatingLabel>
                      </Box>
                      
                      </Center>
                      <Center>
                      <FormErrorMessage>
                      <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                      <ErrorMessage  name="email" /> 
                      </FormErrorMessage>
                      </Center>
                      </FormControl>

                      
                      <Box mx={mobileView&& "auto"}   ml={!mobileView&& -8} width={mobileView&& "xs"} mt={mobileView && 5} textAlign={mobileView && "center"} >
                      <Button zIndex={1} type="submit" mt={1} bg="#e50914" _hover={{background:"red.500"}} transform="scale(1.25)" style={btnStyle} isLoading={formik.isSubmitting} color="white"  fontWeight="bold" ><span style={{paddingRight: 8}}>Get Started</span> <FontAwesomeIcon icon={faAngleRight} /> </Button>
                      </Box>
                      {/* </Flex> */}
                      </Box>
                      </Center>
                           
                  </FmForm>
                )}

                </Formik>
                


   

                </Container> 

            </div> 


                  
  
    )
  }


export default Landing