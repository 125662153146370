import React, {useState, useEffect, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faBars, faAngleDown, faBell, faBellConcierge, faBellSlash, faBridge, faBuilding, faBuildingShield, faEnvelope, faFaceDizzy, faFaceLaugh, faFaceSmile, faFaceSurprise, faGhost, faGun, faHatCowboy, faHatCowboySide, faHeart, faHeartbeat, faKissWinkHeart, faSearch, faShuttleSpace, faSignOut, faSkull, faSpaceShuttle, faClock, faClose, faStairs, faUser, faDeleteLeft, faRecycle, faBucket, faL, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import {faSpaceAwesome, faInstagram, faLinkedin, faGithub, faMedium, faStackOverflow, faTwitter, faFacebook, faFacebookF, faYoutube} from "@fortawesome/free-brands-svg-icons";
import Xicon from "@mui/icons-material/X"
import MenuIcon from "@mui/icons-material/Menu"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate, Link as RouterLink} from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import Netflix_Logo from "../Assets/Netflix_Logo.png"
import { Box, useToast, Tab, TabPanels, Tabs,Avatar,IconButton,Center, Text, HStack, Flex, Input, FormControl, Heading, VStack, Image, Link, useBreakpointValue, Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody, transition, InputLeftElement, InputGroup, Menu, MenuButton, MenuList, MenuItem, background, SkeletonText, Skeleton, Popover, PopoverContent, PopoverHeader, PopoverBody, PopoverCloseButton, PopoverArrow, PopoverTrigger, Collapse, PopoverFooter, TabList, TabPanel, MenuDivider} from "@chakra-ui/react";
import {IconButton as IconB} from "@mui/material";
import { Divider } from "@mui/material";
import {Formik, Form, Field} from "formik"
import SearchBar from "@mkyy/mui-search-bar"
import profileIcon from "../Assets/profile_img.png"
import {format} from "date-fns"
import "./Compo.css"
//import {Sidebar, Menu as ProMenu, MenuItem as ProMenuItem, SubMenu, useProSidebar} from "react-pro-sidebar"

import { HashLink } from "react-router-hash-link";
import { MyContext } from "../contexts/AuthContext";
import axios from "axios";
import SidebarNav from "./Sidebars";




function Header ({selected, setSelected, successLogin, setsuccessLogin, moviePlay, setMoviePlay, searchResults, setSearchResults }){

  
  let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)
  let {username, setUserName} = useContext(MyContext)
  let {userImage, setUserImage} = useContext(MyContext)
  // The Infinitescroll apparently responds to onClick event so remember to put the setGenreNextPage in onClick event
  let {genreNextPage, setGenreNextPage} = useContext(MyContext)
  let {genreResults, setGenreResults} = useContext(MyContext)
  // The Infinitescroll apparently responds to onClick event so remember to put the setSearchNextPage in onClick event
  let {searchNextPage, setSearchNextPage} = useContext(MyContext)  





  let chaktoast = useToast()
  let navigate = useNavigate()




  let [token, setToken] = useState()
  let [user, setUser] = useState()
  let [doneUploading, setDoneUploading] = useState(false)


   // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
   function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken()

  
  let opt = {
     headers: {
      "Authorization": `token ${successLogin}`,
      'X-CSRFToken': csrftoken
    }
  }



  
  useEffect((e)=>{

    let func = async(e)=>{
    
      try{
        let [response, response2] = await axios.all([
          axios.get("https://api-0be9.onrender.com/djoser/users/me/", opt),
          axios.get("https://api-0be9.onrender.com/api/userprofile/100/", opt)        
        ])
        const userprofile = response2.data
        // Add the object as an element in the Array
        //const combine = response2.data.results.concat(response.data)

        console.log("Fetch data successfully:", response.data)
        console.log("User profile data combine:", response2.data)
        setUser(response.data)
        setUserModel(response2.data)
        setImageSrc(userprofile.image)
        setImageId(userprofile.id)
        setUserName(response.data)
        setUserImage(userprofile.image)
      }
      catch(error){
        console.error("Error fetching data", error.response.data)
      
    }
  }

    func()

  }, [successLogin, isAuthenticated, doneUploading])





let submitSignout = async(e)=>{
  const loading = toast.loading("Logging out...")
  
  try{
  // The null indicates that no data is been sent to the request body else axios will take headers as the data
  const response = await axios.post("https://api-0be9.onrender.com/djoser/token/logout", null,{
    headers: {
      "Authorization": `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
      'X-CSRFToken': csrftoken
    }
  })
  localStorage.removeItem("UserLogin")
  localStorage.removeItem("UserAuthtoken")
  setsuccessLogin(false)
  setIsAuthenticated(false)
  // navigate("/signin")
  toast.dismiss(loading)
  console.log("You've been logged out successfully:", response.data)
  
 chaktoast ({
    title: "Logged Out",
    description: "You were logged out of your session!",
    status: "info",
    duration: 5000,
    position: "top-right",
    isClosable: true
  })

  }

  catch(error){
    console.error("There was an error signing out:", error.message)
    console.error("Error signing out:", error.response.data)
  }
}


 

 let [isOpen, setIsOpen] = useState(false)
 let [isOpenDrawer, setIsOpenDrawer] = useState(false)
 let [MenuOpen, setMenuOpen] = useState(false)



let onClose = (e)=>{
  return setMenuOpen(false)
}

let onCloseDrawer =()=>{
  setIsOpenDrawer(false)
}




  
 //Creating a Hamburger Menu / base represents mobile & md represents desktop
 let mobileView = useBreakpointValue({
  base: true,
  lg: false
 })


 let tabletView = useBreakpointValue({
  md: true,
  lg:false  
 })



 let [captureInputValue, setCaptureInputValue] = useState()
 let [imageSrc, setImageSrc] = useState("")
 let [imageId, setImageId] = useState() 
 let [userModel, setUserModel] = useState()
 let fileInputRef = useRef(null)
 let tabRef = useRef(null)
 



 const handleSearch = (e) =>{
  setSearchNextPage(1)
  setSearchResults(captureInputValue)
  navigate(`/search`, {replace:true})
  console.log("Search result:", searchResults)
 }


 let onSubmit = (values, actions)=>{
  setTimeout((e) => {
    setSearchResults(values.search)
    //setSearchNextPage(1)
    navigate(`/search`, {replace:true})
    console.log("Value from search:", values.search)
    actions.resetForm()
    actions.setSubmitting(false)
  }, 3000);
 }




 let [scrolling, setScrolling] = useState(false)

 useEffect((e)=>{
  let func = (e)=>{
    if (window.scrollY >=60){  //80
      setScrolling(true)
    }
    else{
      setScrolling(false)
    }
  }

  window.addEventListener("scroll", func)

 }, [])


 
 // Ninja API 
 const options ={
  headers:{
    'X-Api-key': "FAc2SLn/JWm1wZgg0B2F6A==liO9VnlTDfvX2XPF"
  }
 }

 let [quotes, setQuotes] = useState()
 let [djangoQuotes, setDjangoQuotes] = useState([])
 let [quoteId, setQuoteId] = useState()
 let [doneDeletingQuote, setDoneDeletingQuote] = useState(false)
 let [hasMore, setHasMore] = useState()
 let [nextPage, setNextPage] = useState(1)

 useEffect((e)=>{
  funcNinja()
 }, [successLogin, doneDeletingQuote])


 let funcNinja = async(e)=>{
  try{
    let [response, responseDjango] = await axios.all([
      axios.get("https://api.api-ninjas.com/v1/quotes?category=Movies", options),
      axios.get(`https://api-0be9.onrender.com/api/quote/?page=${nextPage}`, opt)
    ])
    let response2 = await axios.post("https://api-0be9.onrender.com/api/quote/", {"author":response.data[0].author, "quote":response.data[0].quote}, opt)
    console.log("Quotes fetched successfully", response)
    console.log("Django quotes here:", responseDjango.data.results)
    setQuotes(response.data[0])
    setDjangoQuotes(prev => [...prev, ...responseDjango.data.results])
    setHasMore(nextPage < Math.ceil(responseDjango.data.count/10))  
    nextPage < Math.ceil(responseDjango.data.count/10) && setNextPage(prev=> prev+1)
  }
  catch (error){
    console.error("Error fetching quotes", error.message)
  }
}


let deleteQuote = async(e)=>{
  try{
    let response = await axios.delete(`https://api-0be9.onrender.com/api/quote/${quoteId}/`, opt)
    console.log("Quote deleted successfully:", response)
    setDoneDeletingQuote(!doneDeletingQuote)
  }
  catch(error){
    console.error("Error deleting quote")
  }
}



 


 let Hstyle = {

  position: "fixed",
  background: (scrolling&&isAuthenticated) && "#141414",  //#18181b
  color: "white",
  padding: tabletView? "10px": (mobileView?"10px":"0px"),
  right:"0",
  left:"0",
  top: "0",
  transition: "top 0.3s ease-in-out",
  zIndex: 50,
  //background: "#141414",
  backgroundImage: "linear-gradient(180deg, rgba(0,0,0,0.7) 10%, transparent)"
 }


 let inputStyle = {
  background: "#333",
  border: 0,
  color: "#fff",
  height: "43px",
  //lineHeight: "50px",
  //marginBottom: "20px",
  //fontSize:14,
  textAlign: "center"
}

 
 let btnStyle = {
  //background: "#e50914",
  fontWeight: 700,
  border: "none",
  outline: "none",
  //padding: "16px",
  //fontSize: "16px",
  //cursor: "pointer",
  //paddingRight:"30px",
 // paddingLeft:"30px"
  height: "45px",
  }


  //Creating visibility of the menu depending on Scroll
 let [scrollUp, setscrollUp] = useState(true) 
 let [prevScroll, setprevScroll] = useState(0)

 useEffect((e) => {
  let  handleScroll=() => {
  let verticalScroll = window.scrollY

  setscrollUp(verticalScroll < prevScroll) // This is comparing the previous scroll with the current scroll ie verticalScroll
  setprevScroll(verticalScroll) //this now update the prevSroll(0) with the vertical scroll position number & the comparison continues 
}

  window.addEventListener("scroll", handleScroll)
//Remove Event listener to save Memory when the component is unmounted/Or no longer rendered in the current view/Or user navigates away from page
//The return is a cleanup function for all your cleanup activities in useEffect
return () => {
  window.removeEventListener("scroll", handleScroll)
}
 }, [ prevScroll]) 

 


 if (mobileView) {
  return (
    <div style={Hstyle}>
      {isAuthenticated?
      <>

      <HStack className="header" justifyContent="space-between" spacing={8} style={Hstyle} >
      
      <Box ml={2}>
      <IconButton icon= {<FontAwesomeIcon icon={faBars} size="xl"  />} onClick={(e)=>{
      setIsOpenDrawer(true)
    }}/>

      </Box>
    
      <Link href="#" ml={5} >
      <Image src={Netflix_Logo} alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer"  height={14}  /> 
      </Link>
    

      <Box paddingRight="2" >
      <HStack spacing={8} alignSelf="right">
      


      <HStack justifyContent="space-between">
      <div style={{marginRight: 5}}>
      <Popover closeOnBlur={true} closeOnEsc={true} placement="bottom-start" >
        <PopoverTrigger><FontAwesomeIcon cursor="pointer" icon={faBell}/></PopoverTrigger>
        <PopoverContent color="black" maxHeight="500px" overflowY="auto" >
          <PopoverHeader fontWeight="bold">
            <PopoverArrow />
            <PopoverCloseButton />
             {quotes? "Movie Quote of the Day": "Unread Notifications 0"}
          </PopoverHeader>
          <PopoverBody >

          <Tabs>
            <TabList>
              <Tab ref={tabRef}>Unread</Tab>
              <Tab>View All</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
              <VStack alignItems="start" mx={-2}>
              <Text>{quotes? `${quotes.quote} - ${quotes.author}`: "Good job! Looks like you're all caught up." } </Text>
              </VStack>
              </TabPanel>

              <TabPanel>
                <VStack alignItems="start" mx={-2} >
                <InfiniteScroll dataLength={djangoQuotes.length} next={funcNinja} hasMore={hasMore} 
                loader={<h4>Loading...</h4>} 
                endMessage={<p>All quotes loaded!</p>}>
                  {djangoQuotes&& djangoQuotes.map((f, index)=>{
                  return <div key={index} onMouseEnter={(e)=> setQuoteId(f.id)} >
                  <HStack width="100%" justifyContent="space-between">
                  <Text fontWeight="bold">{f.author}</Text>
                  <IconButton size="sm" onClick={deleteQuote} icon={<FontAwesomeIcon icon={faBucket} />} />
                  </HStack>                  
                  <Text mt={-2}>{f.quote}</Text>
                  <Divider h={5} color="blue"  />
                  </div>
                  })}
                  </InfiniteScroll>
                </VStack>
              
                 {(djangoQuotes && djangoQuotes.length==0) &&
                 <Center mx={-2}>
                  <VStack >
                  <FontAwesomeIcon size="3x" icon={faEnvelopeOpen} />
                  <Text>You don't currently have any quotes in your inbox. Check back in soon!</Text>
                  </VStack>
                  </Center> 
                  }
              </TabPanel>
            </TabPanels>
          </Tabs>
          </PopoverBody>

          <PopoverFooter>
           <HStack mb={-3} spacing={5}>
          <Text  _active={{bg: "rgba(34,167,240,0.5)"}} style={{textDecoration:"underline", cursor:"pointer"}}  onClick={(e)=>{
            funcNinja()
            tabRef.current.click()
          }} >Next</Text>
          </HStack>
          </PopoverFooter>
        </PopoverContent>

      </Popover>
      </div>

      <Menu
      isOpen={isOpen}
      onClose={(e)=>setIsOpen(false)}>
        {/* <Image cursor="pointer" onClick={(e)=>setIsOpen(true)} src={profileIcon} />  */}
        <Avatar borderRadius={0} size="sm" cursor="pointer" onClick={(e)=>setIsOpen(true)} src={imageSrc? imageSrc:profileIcon}  />
        <MenuButton onClick={(e)=>setIsOpen(true)} _active={{bg: "rgba(34,167,240,0.5)"}}> <FontAwesomeIcon icon={faAngleDown}/></MenuButton>
        <MenuList color="white" bg="#191919" border={0}>
          <MenuItem  bg="#191919" >
          <VStack mx="auto">
          <Avatar src={imageSrc? imageSrc:profileIcon}  />
          <Text fontWeight="bold">{userModel && userModel.user?.username}</Text>
          <Text mt={-5} fontSize="sm">{userModel && userModel.user?.email}</Text>
          {(userModel&&userModel.user) &&  <Text fontSize="small">Last login: {format(userModel.user?.last_login?.split(".")[0], "yyyy MMM dd 'at' hh:mm a")} </Text> }
          {(userModel&&userModel.user) &&  <Text mt={-5} fontSize="small" >Date joined: {format(userModel.user?.date_joined, "yyyy MMMM dd")} </Text> }
          </VStack>
          </MenuItem> 

          <MenuDivider mt={-2}mb={4} />

          <MenuItem bg="#191919" textDecoration="underline"  _active={{bg: "rgba(34,167,240,0.5)"}} icon={<FontAwesomeIcon icon={faUser} />}  onClick={(e)=>{
            // Programmatically triger the hidden file input
            fileInputRef.current.click()
            }}>Update Profile</MenuItem> 
     {imageId &&  <MenuItem bg="#191919" textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)"}} icon={<FontAwesomeIcon icon={faDeleteLeft} />} onClick={async(e)=>{
            try{
              let response = await axios.delete(`https://api-0be9.onrender.com/api/userprofile/${imageId}/`, opt)
              console.log("Success deleting user profile:", response)
              setDoneUploading(!doneUploading)
            }
            catch (error){
              console.error("Error deleting profile:", error.message)
            }
          }}>Delete profile</MenuItem>  }
          <MenuItem bg="#191919" onClick={submitSignout} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)"}} icon={<FontAwesomeIcon icon={faSignOut} />}>Sign Out of Netflix</MenuItem> 
        </MenuList>
      </Menu>
      
      {/*The display:"none" hides the file input. The image file selection happens behind the scene */}
      <input style={{display:"none"}} ref={fileInputRef} type="file" accept="image/*" onChange={async(event)=>{
        let file = event.target.files[0]
        if (file){      //The file (file object) is an object that contains metadata sbout the file such as its name, size, type and reference to its binary content ie. raw bytes which is hidden
          const imageURL = URL.createObjectURL(file)  //The createobjectURL is a jibberish(blob) URL that cannot be sent via post request directly
          //setImageSrc(imageURL)
        }        
        // Sending files via post request (file uploads) cannot be JSON object because it's a binary data and not string
        // and the server might not understand it. That's why we need "new FormData()" which is appended with key and value.
        // FormData allows you to send both files and other fields (like text, user IDs, etc) in one request
        let formData = new FormData()
        formData.append("image",file)

        try{
          let [response, response2] = await axios.all([
          !imageId && axios.post("https://api-0be9.onrender.com/api/userprofile/", formData, opt),
          imageId && axios.put(`https://api-0be9.onrender.com/api/userprofile/${imageId}/`,formData, opt)
          ]) 
          console.log("Success updating user profile", response)
          setDoneUploading(!doneUploading)
        }
        catch(error){
          console.error("Error updating user profile:", error.message)
        }

      }} />
    
      </HStack>


    
     
      </HStack>
      </Box>

      </HStack>

 
               
    {/* <div style={{display:"flex", marginLeft:-8, color:"black", background:"transparent"}}>
    <Sidebar  >
    <ProMenu >
    <ProMenuItem onClick={(e)=> collapseSidebar()} style={{marginLeft: -8,borderBottom:"2px solid #ccc",marginBottom:40, marginTop:20}} icon={<FontAwesomeIcon icon={faBars}/>}>
    </ProMenuItem>
      <ProMenuItem style={{fontSize: "large",fontWeight:"bold"}} >Dashboard</ProMenuItem>
      <ProMenuItem>Invoices</ProMenuItem>
      <SubMenu label="Charts">
      <ProMenuItem>Timeline Chart</ProMenuItem>
      <ProMenuItem>Bubble Chart</ProMenuItem>
      </SubMenu>
      <SubMenu label="Wallets">
      <ProMenuItem>Current Wallet</ProMenuItem>
      <ProMenuItem>Savings wallet</ProMenuItem>
      </SubMenu>
      <ProMenuItem>Transactions</ProMenuItem>
      <SubMenu label="Settings">
      <ProMenuItem>Account</ProMenuItem>
      <ProMenuItem>Privacy</ProMenuItem>
      <ProMenuItem>Notifications</ProMenuItem>
      </SubMenu>
      <ProMenuItem>Logout</ProMenuItem>

    </ProMenu>

    </Sidebar>
    </div> */}

    
    <Drawer 
    isOpen={isOpenDrawer}
    onClose={onCloseDrawer}
    size={ tabletView && "md"}
    placement="left"
     >
      <DrawerOverlay>
        <DrawerContent maxH="100vh"  bg="#141414" color="white">
         <DrawerCloseButton size="lg" />
          
         <DrawerHeader >
            <Link href="#">
            <Image src={Netflix_Logo} alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer"  height={14}  /> 
            </Link>
            
          </DrawerHeader>


          <DrawerBody paddingLeft={0} paddingRight={0} >

           <Box mx={5}  >
          <SearchBar width="100%" value={searchResults} onChange={newValue=>setCaptureInputValue(newValue)} onSearch={handleSearch} placeholder="Search for a movie, tv shows..." style={{background: "black"}} />
          </Box>
    
            <SidebarNav djangoQuotes={djangoQuotes}  />

                
            <HStack spacing={8} justifyContent="center"  mt={5}>
            <Link href="https://www.facebook.com/netflix" isExternal mt="40" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <FontAwesomeIcon icon={faFacebookF} size="2xl" />
            </Link>
            <Link href="https://www.instagram.com/netflix" isExternal mt="40" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
            </Link>
            <Link href="https://www.x.com/netflix" isExternal mt="40" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <Xicon style={{fontSize: 30}} />
            </Link>
            <Link href="https://www.youtube.com/netflix" isExternal mt="40" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <FontAwesomeIcon icon={faYoutube} size="2xl" />
            </Link>
            
           
            </HStack>
      
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>   

     {/* <ToastContainer theme="dark" limit={1} closeOnClick /> */}


    </>
    : ""}
    </div>
  )
 }


 
else{


  return(
    <div> 
      {isAuthenticated?

      
      <>
      <HStack className="header" justifyContent="space-between" spacing={8} style={Hstyle} >
      
      <Box ml={4}>
      <Image src={Netflix_Logo} width="auto" height="75px"  />
      </Box>
        

    

      <Box paddingRight="8" >
   
      <HStack spacing={8} alignSelf="right">

      <Text as={RouterLink} to="/dashboard" _active={{bg: "rgba(34,167,240,0.5)"}} fontWeight="bold" >Home</Text> 


{!moviePlay && <Menu isOpen={MenuOpen} 
        onClose={onClose}
        >
        <MenuButton fontWeight="bold" onClick={(e)=> setMenuOpen(true)} _active={{bg: "rgba(34,167,240,0.5)"}} >Genres ▼</MenuButton>
        <MenuList color="black">
          <MenuItem icon={<FontAwesomeIcon icon={faGun} />} onClick={(e)=>{
            setGenreResults(28)
            setGenreNextPage(1)
            navigate("/movie-genre/action", {replace:true})
          }} >Action</MenuItem>
            <MenuItem icon={<FontAwesomeIcon icon={faBridge} />} onClick={(e)=>{
            setGenreResults(16)
            setGenreNextPage(1)
            navigate("/movie-genre/animation", {replace:true})
          }} mt={2}>Animation</MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon={faFaceSmile} />} onClick={(e)=>{
            setGenreResults(35)
            setGenreNextPage(1)
            navigate("/movie-genre/comedy", {replace:true})
          }} mt={2}>Comedy</MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon={faFaceDizzy} />} onClick={(e)=>{
            setGenreResults(18)
            setGenreNextPage(1)
            navigate("/movie-genre/drama", {replace:true})
          }} mt={2}>Drama</MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon={faGhost} />} onClick={(e)=>{
            setGenreResults(27)
            setGenreNextPage(1)
            navigate("/movie-genre/horror", {replace:true})
          }} mt={2}>Horror</MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon={faHeart} />} onClick={(e)=>{
            setGenreResults(10749)
            setGenreNextPage(1)
            navigate("/movie-genre/romance", {replace:true})
          }} mt={2}>Romance</MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon={faSpaceAwesome} />} onClick={(e)=>{
            setGenreResults(878)
            setGenreNextPage(1)
            navigate("/movie-genre/science-fiction", {replace:true})
          }} mt={2}>Science Fiction</MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon={faBuildingShield} />} onClick={(e)=>{ 
            setGenreResults(14)
            setGenreNextPage(1)
            navigate("/movie-genre/fantasy", {replace:true})
          }} mt={2}>Fantasy</MenuItem>         
        </MenuList>
      </Menu> }

          
     {!moviePlay? 
     <>
     <Text as={HashLink} smooth to="/movie-list#1"  _active={{bg: "rgba(34,167,240,0.5)"}} fontWeight="bold" >My List</Text>       
     </>
     :
     <>
     <Text as={RouterLink} to="/movie-series" fontWeight="bold" _active={{bg: "rgba(34,167,240,0.5)"}} >Series</Text>
     <Text as={RouterLink} to="/films" fontWeight="bold" _active={{bg: "rgba(34,167,240,0.5)"}} >Films</Text>
     <Text as={RouterLink} to="/new&popular" fontWeight="bold" _active={{bg: "rgba(34,167,240,0.5)"}} >New & Popular</Text>
     </>
      }


        <Formik
                initialValues={{search:""}}
                onSubmit={onSubmit}>

                {(formik)=>(
                  <Form >
                      {/*You bind items with Flex as a single unit */} 
                      <Center>
                      <Flex gap={1} >
                      <Center>
                      <InputGroup>
                      <InputLeftElement children={<FontAwesomeIcon icon={faSearch} />} />
                      <Field as={Input} className="search-input" type="search" style={{marginLeft:10}}  bg="gray.700"  name="search" id="search" width="small" placeholder="Search for a movie..." />
                      </InputGroup>
                      </Center>                  
                      
                      <Button style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} onClick={(e)=> setSearchNextPage(1)} type="submit" isLoading={formik.isSubmitting} color="white"  fontWeight="bold" >Search</Button>
                      </Flex>
                      </Center>
                      
                  </Form>
                )}

        </Formik>



      
      <Text cursor={successLogin && "default"}  as={RouterLink} to= {!successLogin && "/signin"} _hover={{fontWeight: !successLogin && "bold", textDecoration: !successLogin && "underline"}} >
     {successLogin&& `Welcome ${user? user?.username:""}`}
      </Text> 
     {!user && <Text mx={-6} as={Skeleton}>Username</Text>}

      
  
  {successLogin? 
      
      <HStack width="5%" justifyContent="space-between">
      <Popover closeOnBlur={true} closeOnEsc={true} placement="bottom-start" >
        <PopoverTrigger ><FontAwesomeIcon cursor="pointer" color="white" icon={faBell}/></PopoverTrigger>
        <PopoverContent color="black" maxHeight="300px" overflowY="auto" >
          <PopoverHeader fontWeight="bold">
            <PopoverArrow />
            <PopoverCloseButton />
             {quotes? "Movie Quote of the Day": "Unread Notifications 0"}
          </PopoverHeader>
          <PopoverBody >
   
          <Tabs>
            <TabList>
              <Tab ref={tabRef}>Unread</Tab>
              <Tab>View All</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
              <VStack alignItems="start" mx={-2}>
              <Text>{quotes? `${quotes.quote} - ${quotes.author}`: "Good job! Looks like you're all caught up." } </Text>
              </VStack>
              </TabPanel>

              <TabPanel>
                <VStack alignItems="start" mx={-2} >
                <InfiniteScroll dataLength={djangoQuotes.length} next={funcNinja} hasMore={hasMore} 
                loader={<h4>Loading...</h4>} 
                endMessage={<p>All quotes loaded!</p>}>
                  {djangoQuotes&& djangoQuotes.map((f, index)=>{
                  return <div key={index} onMouseEnter={(e)=> setQuoteId(f.id)} >
                  <HStack width="100%" justifyContent="space-between">
                  <Text fontWeight="bold">{f.author}</Text>
                  <IconButton size="sm" onClick={deleteQuote} icon={<FontAwesomeIcon icon={faBucket} />} />
                  </HStack>                  
                  <Text mt={-2}>{f.quote}</Text>
                  <Divider h={5} color="blue"  />
                  </div>
                  })}
                  </InfiniteScroll>
                </VStack>
              
                 {(djangoQuotes && djangoQuotes.length==0) &&
                 <Center mx={-2}>
                  <VStack >
                  <FontAwesomeIcon size="3x" icon={faEnvelopeOpen} />
                  <Text>You don't currently have any quotes in your inbox. Check back in soon!</Text>
                  </VStack>
                  </Center> 
                  }
              </TabPanel>
            </TabPanels>
          </Tabs>
          </PopoverBody>

          <PopoverFooter>
           <HStack mb={-3} spacing={5}>
          <Text  _active={{bg: "rgba(34,167,240,0.5)"}} style={{textDecoration:"underline", cursor:"pointer"}}  onClick={(e)=>{
            funcNinja()
            tabRef.current.click()
          }} >Next</Text>
          </HStack>
          </PopoverFooter>
        </PopoverContent>
           
      </Popover>

      <Menu
      isOpen={isOpen}
      onClose={(e)=>setIsOpen(false)}>
        <Avatar borderRadius={0} size="sm" cursor="pointer" onClick={(e)=>setIsOpen(true)} src={imageSrc? imageSrc:profileIcon} /> 
        <MenuButton onClick={(e)=>setIsOpen(true)} _active={{bg: "rgba(34,167,240,0.5)"}}> <FontAwesomeIcon icon={faAngleDown}/></MenuButton>
        <MenuList color="white" bg="#191919" border={0}>
          <MenuItem textDecoration="none" bg="#191919"  >
          <VStack mx="auto">
          <Avatar src={imageSrc? imageSrc:profileIcon}  />
          <Text fontWeight="bold">{userModel && userModel.user?.username}</Text>
          <Text mt={-5} fontSize="sm">{userModel && userModel.user?.email}</Text>
          {(userModel&&userModel.user) &&  <Text fontSize="small">Last login: {format(userModel.user?.last_login?.split(".")[0], "yyyy MMM dd 'at' hh:mm a")} </Text> }
          {(userModel&&userModel.user) &&  <Text mt={-5} fontSize="small" >Date joined: {format(userModel.user?.date_joined, "yyyy MMMM dd")} </Text> }
         </VStack>
          </MenuItem> 

          <MenuDivider mt={-2}mb={4} />

          <MenuItem bg="#191919" textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)"}} icon={<FontAwesomeIcon icon={faUser} />}  onClick={(e)=>{
          // Programmatically triger the hidden file input
          fileInputRef.current.click()
          }}>Update Profile</MenuItem> 

     {imageId &&  <MenuItem bg="#191919" textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)"}} icon={<FontAwesomeIcon icon={faDeleteLeft} />} onClick={async(e)=>{
            try{
              let response = await axios.delete(`https://api-0be9.onrender.com/api/userprofile/${imageId}/`, opt)
              console.log("Success deleting user profile:", response)
              setDoneUploading(!doneUploading)
            }
            catch (error){
              console.error("Error deleting profile:", error.message)
            }
          }}>Delete Profile</MenuItem>  }

          <MenuItem bg="#191919" onClick={submitSignout} textDecoration="underline" _active={{bg: "rgba(34,167,240,0.5)"}} icon={<FontAwesomeIcon icon={faSignOut} />}>Sign Out of Netflix</MenuItem> 
        </MenuList>
      </Menu>
      
      {/*The display:"none" hides the file input. The image file selection happens behind the scene */}
      <input style={{display:"none"}} ref={fileInputRef} type="file" accept="image/*" onChange={async(event)=>{
        let file = event.target.files[0]
        if (file){      //The file (file object) is an object that contains metadata sbout the file such as its name, size, type and reference to its binary content ie. raw bytes which is hidden
          const imageURL = URL.createObjectURL(file)  //The createobjectURL is a jibberish(blob) URL that cannot be sent via post request directly
          //setImageSrc(imageURL)
        }        
        // Sending files via post request (file uploads) cannot be JSON object because it's a binary data and not string
        // and the server might not understand it. That's why we need "new FormData()" which is appended with key and value.
        // FormData allows you to send both files and other fields (like text, user IDs, etc) in one request
        let formData = new FormData()
        formData.append("image",file)

        try{
          let [response, response2] = await axios.all([
          !imageId && axios.post("https://api-0be9.onrender.com/api/userprofile/", formData, opt),
          imageId && axios.put(`https://api-0be9.onrender.com/api/userprofile/${imageId}/`,formData, opt)
          ]) 
          console.log("Success updating user profile", response)
          setDoneUploading(!doneUploading)
        }
        catch(error){
          console.error("Error updating user profile:", error.message)
        }

      }} />

      </HStack>
      :
      ""}

    
     
      </HStack>
      </Box>

      </HStack>

      {/* <ToastContainer theme="dark" limit={1} closeOnClick /> */}
      </>
:""}

  </div>
  )
 }

} 
  
export default Header 
 