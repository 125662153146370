import { Center, Select, HStack, InputGroup, InputLeftElement, Heading, Image,Link, useToast, useBreakpointValue,Button,Box,Text, VStack } from "@chakra-ui/react";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Formik, Form, Field } from "formik"
import { useNavigate, useParams } from "react-router-dom";
import useResetAuth from "../Components/ResetAuth";
import { useState, useEffect } from "react";
import axios from "axios";


import netflixlogo from "../Assets/Netflix_Logo.png"



function ActivateEmail({toggleProp, signPage, setSignPage}){

    useResetAuth()

    let [activateMessage, setActivateMessage] = useState()
    let [errorActivateMessage, setErorActivateMessage] = useState()
    let toast = useToast()


    let navigate = useNavigate()
    let {uid} = useParams()  // Extract the uid parameter and the token parameter from the URL


    // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
    function getCSRFToken(){
        let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookieValue ? cookieValue[1] : null
    }

    let csrftoken = getCSRFToken();
    

    useEffect((e)=>{
        setSignPage(true)

        let func = async(e)=>{

            // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
            let pro = new Promise((resolve, reject)=>{
                }) 
            // This ensures any existing toasts are closed before displaying new ones
             toast.closeAll()
             toast.promise(pro, {
             loading: {
             title: "Activation pending",
             description: "Please wait"
             },

             })

           
             try{
                let response = await axios.post("https://api-0be9.onrender.com/auth/registration/verify-email/", {
                    key: uid,
                    //token: token
                },{
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                })
                console.log("User account has been activated successfully", response.data)
                setActivateMessage(true)
                // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
                let pro = new Promise((resolve, reject)=>{
                    resolve()
                })
                // This ensures any existing toasts are closed before displaying new ones
                toast.closeAll()
                toast.promise(pro, {
                success: {
                title: "Activation resolved",
                description: "Looks great"
                }
                })
                
            }
            catch(error){
                console.error("error activating user account", error.response.data)
                setErorActivateMessage(true)
                // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
                let pro = new Promise((resolve, reject)=>{
                    reject()
                })
                // This ensures any existing toasts are closed before displaying new ones
                toast.closeAll()
                toast.promise(pro, {
                error: {
                title: "Activation failed",
                description: "Looks like we encountered a problem. Please wait a few minutes and try again. If the problem persist, please contact support@benasenso.site"            
                }
                })             
            }
        }
        func()
    }, [uid])



    let [initialValues, setInitialvalues] = useState({
        language: ""
    })
     
    let btnStyle = {

        //background: "#e50914",
        fontWeight: 700,
        border: "none",
        outline: "none",
        padding: "16px",
        fontSize: "16px",
        cursor: "pointer",
        paddingRight:"30px",
        paddingLeft:"30px"
    }



    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })

    let tabletView = useBreakpointValue({
        md: true,
        lg: false
    })





    return(
        <div>
        
            <Center minHeight="100vh"  backgroundColor= "rgba(0,0,0,1)" color="white">
                <VStack>
              
              {activateMessage?
                <>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")}>{activateMessage? "Your email has been verified.": "Error activating your account" }</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}> {activateMessage? "You can now sign in with your new account" : "You cannot sign in with your account. Please try again later!"} </Text>

                <Button mt={4} colorScheme="facebook" style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} onClick={(e)=> navigate("/") } >Back to homepage</Button>
                </>
                :
                
                (errorActivateMessage?
                <>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")}>Error activating your account</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}> You cannot sign in with your account. Please try again later!</Text>
                <Button mt={4} colorScheme="facebook" bg="#e50914" _hover={{background:"red.500"}} style={btnStyle} onClick={(e)=> navigate("/") } >Back to homepage</Button>
                </>
                :
                <>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")}>Activating your account</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}> Please wait! </Text>
                </>
                )
                
                }


                {/* Creating logo*/}  
                <Link href="#">
                  <Image transform={mobileView&& "scale(0.7)"} src={netflixlogo} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%"  left={mobileView?"-5": "3"} width="auto" height="75px" /> 
                </Link>

                
                <HStack gap={6}  transform={mobileView&& "scale(0.7)"}  position="absolute" top= {mobileView? "0.2%":"0%"}  right={mobileView?"-2": "8"}>
                  
                  <Formik
                  initialValues={initialValues}>
                    {(formik)=>(
                      <Form>
                        <InputGroup  mt={4} >
                        <InputLeftElement color="white" children={<FontAwesomeIcon icon={faLanguage}/>} />
                        <Field color="white" as={Select}  width={!mobileView&&!tabletView && "10vw"}  style={{textAlign: "center"}}  name="language" id="language">
                          <option style={{color:"black"}}>English</option>
                          <option style={{color:"black"}}>Espanol</option>
                        </Field>
                        </InputGroup>
                      </Form>
                    )}
                  </Formik>
                
                  <Button onClick={(e)=> navigate("/signin", {replace:true})} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} mt={4} color="white"  fontWeight="bold"  width="auto" >Sign In</Button>

                </HStack>


                

                </VStack>
            </Center>

        </div>
    )
}


export default ActivateEmail