import { Container, useBreakpointValue, SkeletonCircle, SkeletonText, IconButton, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ButtonGroup,Box, Link, Button,ModalFooter,Card,Tr,Image,Heading, Center, Text, HStack, VStack, TableContainer, Table, Thead, Tbody, Th, Td, CardBody, Divider, Collapse, Skeleton } from "@chakra-ui/react";
import { faAngleLeft, faArrowLeft, faArrowRight, faCircle, faCircleH, faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons"
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link as RouterLink, useParams } from "react-router-dom";
import React, {useState, useEffect} from "react"
import { useContext } from "react";
import {format} from "date-fns"

import ClassicPostLoader from "../Components/Images Skeleton";
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import 'react-horizontal-scrolling-menu/dist/styles.css'
import ProfileShow from "../Components/Profile Skeleton"
import GlobalSidebar from "../Components/Sidebar Skeleton";
import "./Pages.css"
import axios from "axios";
import { MyContext } from "../contexts/AuthContext";



function Person({moviePlay, setMoviePlay, movieId, setMovieId,youtubePlay, setYoutubePlay, descrip, setDecrip}){


  let [imageCover, setImageCover] = useState()
  
  let [length, setLength] = useState()
  let [releaseDate, setReleaseDate] = useState()
  let [title, setTitle] = useState()
  let [movieTMDB, setMovieTMDB] = useState()
  let [personDetails, setPersonDetails] = useState()
  let [combineCredits, setCombineCredits] = useState()
  let [producer, setProducer] = useState()
  let [movieAddMessage, setMovieAddMessage] = useState(false)
  let [movieAddErrorMessage, setMovieAddErrorMessage] = useState()
  let [listmessage2, setListMessage2] = useState()
  let [ListErrorMessage2, setListErrorMessage2] = useState()
  

  
  // Split the time string into hours and minutes components. Basically we're unpacking those two variables
  let [hours, minutes] = length? length.split(":") : ""
  // Convert to numbers removing leading zeros. Eg 03 to 3
  let newHours = Number(hours)
  let newMinutes = Number(minutes)
  let Newtime= `${newHours}${newHours==1? "hr":"hrs"} ${newMinutes}mins`

  let replaceTitleSpaces = title? title.replace(/\s+/g, "-").toLowerCase(): "pirate"


  let [isOpen, setIsOpen] = useState()

  let onClose = (e)=>{
    setIsOpen(false)
    setMovieAddMessage(false)
    setMovieAddErrorMessage(false)
    setListMessage2(false)
    setListErrorMessage2(false)
    setYoutubePlay(false)
  }



    let [movieVideo, setMovieVideo] = useState()
    let [youtubeVideo, setYoutubeVideo] = useState()
    let youtubeUrl = "https://www.youtube.com/embed/" + youtubeVideo
    let [clip, setClip] = useState()
    let [youtubeName, setYoutubeName] = useState()
    let [publishedDate, setPublishedDate] = useState()

    
    // Trick to know - Remember you can use useParams() to pass the 
    // movieid in the browser url path and then extract it at the destination
    let {name} = useParams()
    let replaceNameHyphens = name? name.replace("-", " "): ""
    let capitalTitle = title => title.replace(/\b\w/g, char=>char.toUpperCase())
    let titleCap = capitalTitle(replaceNameHyphens)


    let {tvShows, setTvShows} = useContext(MyContext)
    let {imageCard, setImageCard} = useContext(MyContext)
    
    let [nextPage, setNextPage] = useState(1)
    let [hasMore, setHasMore] = useState()
    let [hasLess, setHasLess] = useState()
    let [movieTMDBCount, setMovieTMDBCount] = useState()
    let [showMore, setShowMore] = useState(false)



   //For ScrollMenu Arrow Buttons. Apparently it uses custom LeftArrow and RightArrow components 
   function LeftArrow(){
    const {scrollPrev} = React.useContext(VisibilityContext) // Destructuring to get the scrollPrev/scrollNext function 
     return(
       <IconButton size="xs" colorScheme="messenger" style={{position:"absolute", top:0, right:tabletView? "6vw":40, transform: "translate(0.5%, -170.6%)" }} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={()=>scrollPrev()} />
     )
    }
   function RightArrow(){
    const {scrollNext} = React.useContext(VisibilityContext)
    return(
      <IconButton size="xs" colorScheme="messenger" style={{position:"absolute", top:0, right:tabletView? "1vw":0,  transform: "translate(0.5%, -170.6%)"}} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={()=>scrollNext()} />
    
    )
   }


    
    const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
        }
      };
      


    function getCSRFToken(e){
        let cookievalue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookievalue? cookievalue[1] : null
    }

    let csrftoken = getCSRFToken()


    
    // useEffect((e)=>{
    //     setMoviePlay(true)
    //     if (youtubePlay){
    //         setTvShows(false)
    //     }

    //     let func = async(e)=>{
    //         try{
    //             let [response, youtubeResponse, tvShowResponse] = await axios.all([
    //            (!youtubePlay && !tvShows) && axios.get(`https://api-0be9.onrender.com/api/movies/${movieId}/`, {
    //                 headers:{
    //                     'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
    //                     'X-CSRFToken': csrftoken
    //                 }}),
               
    //             youtubePlay && axios.get(`https://api.themoviedb.org/3/movie/${movieId}/videos?language=en-US`, options),
    //             tvShows && axios.get(`https://api.themoviedb.org/3/tv/${movieId}/videos?language=en-US`, options)
    //             ])
             
    //             console.log("Movie video for id fetch successfully:", response.data)
    //             setMovieVideo(response.data)
    //             youtubeVideo && console.log("Youtube video for id fetch successfully:", youtubeResponse)
    //             setYoutubeVideo(tvShows? tvShowResponse.data.results[0].key: youtubeResponse.data.results[0].key)
    //             setClip(tvShows? tvShowResponse.data.results[0].type:youtubeResponse.data.results[0].type)
    //             setPublishedDate(tvShows? tvShowResponse.data.results[0].published_at.split("T")[0]: youtubeResponse.data.results[0].published_at.split("T")[0])
    //             setYoutubeName(tvShows? tvShowResponse.data.results[0].name: youtubeResponse.data.results[0].name)

    //         }
    //         catch (error){
    //             console.error("Error getting video for movie id", error.message)
    //         }
    //     }

    //     func()

    //   }, [])

    
    useEffect((e)=>{
      setMoviePlay(false)

      let func = async(e)=>{
        try{
          // Using axios.all to prevent delay(waiting time) of waiting for one to finish before moving to another
          let [response, responseDetails, responseCombined] = await axios.all([
            axios.get(`https://api.themoviedb.org/3/person/${movieId}/movie_credits?language=en-US`, options),
            axios.get(`https://api.themoviedb.org/3/person/${movieId}?language=en-US`, options),
            axios.get(`https://api.themoviedb.org/3/person/${movieId}/combined_credits?language=en-US`, options),
          ])
  
          console.log("Sucess fetching movieTMDB movies:", response.data)
          console.log("Sucess fetching person details:", responseDetails.data)
          console.log("Sucess fetching combined Credits for person:", responseCombined.data)
          setMovieTMDB(response.data.cast)
          setPersonDetails(responseDetails.data)
          setCombineCredits(responseCombined.data.cast)
          setProducer(responseCombined.data.crew)

          setMovieTMDBCount(response.data.count)
          setHasMore(response.data.next)  
          setHasLess(response.data.previous)
        }
        catch(error){
          console.error("Error fetching movie data", error.message)
        }
      }

      func()

    }, [nextPage, hasLess, hasMore])


    let btnStyle = {
      //background: "#e50914",
      fontWeight: 700,
      border: "none",
      outline: "none",
      height: "45px",
      }


    let addTMDBToMovieList = async()=>{    
      let details = {
        title: title,
        description: descrip,
        genre: "action",
        category: "popular",
        length: length,
        backdrop_path: imageCard,
        poster_path: imageCover,
        //youtube_video_url: "key",
        tmdb_movieid:  movieId
      }
      const loading = toast.loading("Adding movie...")
    
      try{
        let responseVidz = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}/videos?language=en-US`, options)
         
        let response = await axios.post("https://api-0be9.onrender.com/api/movies/", {...details,youtube_video_url:responseVidz.data.results[0].key},{
          headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken':  csrftoken
          }
        })
        
        let response2 = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": response.data.id},{
          headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken':  csrftoken
          }
        })
    
        console.log("Movie has been added to Movie List successfully", response)
        toast.dismiss(loading)
        toast.success(`${title} added to Movie List`)
        setMovieAddMessage(true)
    
      }
      catch (error){
        console.error("Error adding TMDB movie to Model:", error.response.data)
        console.error("Error adding TMDB to Movie List:", error.response.data)
        toast.dismiss(loading)
        toast.error("Error adding movie")
        setMovieAddErrorMessage(`${error.response.data.detail}`)
      }
    }
    
      
      //Creating a Hamburger Menu / base represents mobile & md represents desktop
     let mobileView = useBreakpointValue({
        base: true,
        md: false
      })
  
  
      let tabletView = useBreakpointValue({
        md: true,
        lg:false   
      })
  

    
    
      return(
        <div>
            <VStack px={mobileView? 5:(tabletView?7:14)} maxW="full" pt={20} pb={20}  minHeight="100vh" style={{background: "rgba(0, 0, 0, 1)", color: "#fff", }}>
                <HStack mt={10} width="100%" justifyContent="space-between">
                    <Heading class="text-3xl font-semibold">{movieVideo && movieVideo.title}  {youtubeVideo && titleCap}</Heading>
                    <Text to="/new&popular" onClick={(e)=>setMoviePlay(false)} class="text-gray-400 hover:text-white" as={RouterLink} _active={{bg: "rgba(34,167,240,0.5)"}} ><FontAwesomeIcon icon={faAngleLeft} /><span style={{marginLeft:5}}>Back to movies</span></Text>
                </HStack>


                <Container  px={mobileView&& 0}  mt={20} maxW="full" display="grid" gridTemplateColumns={mobileView? "1fr":"1fr 2fr"} gap={10}>

                {!personDetails?  <VStack alignItems="start">
                  <Box mx={mobileView&& "auto"}>
                  {!mobileView? <ProfileShow  size="sm" transform={mobileView? "scale(1.2)": "scale(2.5)"}  /> 
                  :<SkeletonCircle size="xs" transform="scale(0.8)" /> }
                  </Box> 
                  <Box mx={mobileView&&"auto"} mt={20}>
                  <GlobalSidebar  />
                  </Box>
                  </VStack>

                :
                  <VStack alignItems="start">
                    <Card >
                      <CardBody p={0}>
                      <Image src={personDetails && "https://image.tmdb.org/t/p/w500" + personDetails.profile_path}  />
                      </CardBody>
                    </Card>

                    <HStack spacing={5} mt={10}>
                      <Link href={`https://www.facebook.com/${personDetails&& personDetails.name.replace(" ", "")}`} isExternal  _active={{bg: "rgba(34,167,240,0.5)"}} >
                      <FontAwesomeIcon  isExternal size="xl" icon={faFacebook} />
                      </Link>
                      <Link href={`https://www.instagram.com/${personDetails&& personDetails.name.replace(" ", "")}`} isExternal  _active={{bg: "rgba(34,167,240,0.5)"}} >
                      <FontAwesomeIcon as={Link} size="xl" icon={faInstagram}  />
                      </Link>
                    </HStack>

                    <Heading size="lg" mt={5} width="max-content">Personal Info</Heading>
                    <Text fontSize="xl" fontWeight="bold">Known For</Text>
                    <Text mt={-5} pt={0}>{personDetails && personDetails.known_for_department}</Text>

                    <Text fontSize="xl" fontWeight="bold">Known Credits</Text>
                    <Text mt={-5} pt={0}>69</Text>

                    <Text fontSize="xl" fontWeight="bold">Gender</Text>
                    <Text mt={-5} pt={0}>{personDetails && personDetails.gender===2? "Male":"Female"}</Text>

                    <Text fontSize="xl" fontWeight="bold">Birthday</Text>
                    <Text mt={-5} pt={0} width="max-content">{personDetails&& personDetails.birthday} ({new Date().getFullYear()-new Date(personDetails&& personDetails.birthday).getFullYear()} years old)</Text>

                    <Text fontSize="xl" fontWeight="bold">Place of Birth</Text>
                    <Text mt={-5} pt={0} width="max-content">{personDetails && personDetails.place_of_birth}</Text>

                    <Text fontSize="xl" fontWeight="bold">Also Known As</Text>
                    {personDetails && personDetails.also_known_as.map(f=>
                      <Text mt={-5} pt={0} width="max-content">
                        {f}  
                      </Text>
                    )}
                    


                    <Text mt={5} width="max-content">Content Score</Text>
                    <TableContainer mt={-5}>
                      <Table variant="striped" colorScheme="teal" color="black">
                        <Tbody>
                          <Tr>
                            <Td>100</Td>
                          </Tr>
                          <Tr color="white">
                            <Td>Yes! Looking good</Td>
                          </Tr>

                        </Tbody>
                      </Table>
                    </TableContainer>

                  </VStack>
                }


                  <VStack alignItems="start"  >
                   {!personDetails&& <Skeleton>
                    <Heading>Jason Statham</Heading>
                    </Skeleton> }
                  <Heading>{personDetails&& personDetails.name}</Heading>
                 

                  <Heading size="lg" mt={5} width="max-content">Biography</Heading>
                  <Box width="full" >
                 {!personDetails && <SkeletonText className="skeletontext" mt='4' noOfLines={10}  spacing='4' skeletonHeight='4' />}
                  </Box>

                  
                  {/*Split into paragraphs. \r is enter for macOs, \n is enter for unix, \r\n is enter for windows. {2,} means when it occurs twice or more. The ?: groups everything */}
                    <Collapse startingHeight={personDetails&& 200} in={showMore} >
                    {personDetails&& personDetails.biography.split(/(?:\r\n|\r|\n){2,}/)
                    .map(f=>(
                      <Text>{f}</Text>
                    )) 
                    }
                    </Collapse>


                    <Link as={RouterLink} to="#" onClick={(e)=>setShowMore(!showMore)} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color="green" style={{justifyContent: "center"}}> {showMore? "Show Less":"Read More"} 
                      <span style={{marginLeft:5}}><FontAwesomeIcon fontSize="small" icon={faAngleRight}/> </span>
                    </Link>

                  <Heading size="lg" mt={5} width="max-content">Known For</Heading>
                  <Box className={!movieTMDB&&tabletView?"personb": "personbox"} position="relative"  >


                  <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} >
              {movieTMDB? movieTMDB.filter(f=> f.backdrop_path!==null).map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px",marginRight:mobileView?"0px": "25px", position:"relative", transform: mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5}>{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setYoutubePlay(true)
                    setImageCover("https://image.tmdb.org/t/p/w500" + f.poster_path)
                    setDecrip(f.overview)
                    setLength("03:26:00")
                    setReleaseDate(f.release_date)
                    setImageCard(f.backdrop_path)
                    setTitle(f.original_title)
                    setTvShows(false)
                  }} src={"https://image.tmdb.org/t/p/w500" + f.backdrop_path} objectFit="cover" width="195px" height="265px" _hover={{transform:mobileView?"scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>  
              </>}

            </ScrollMenu>
            </Box>


            <Heading size="lg" mt={8} width="max-content">Acting</Heading>
            <Box className={!movieTMDB&&tabletView?"act":"acting"} >
            <Card size="lg" >
              <CardBody p={mobileView&& 4} style={{background:"#18181b", color: "#fff"}}>
                <ScrollMenu>
                {combineCredits && combineCredits.sort((a, b)=> new Date(b.release_date)-new Date(a.release_date))
                .map((f, index)=>{
                  return <div key={index}  style={{width:"max-content", height:"200px",marginRight: "50px", position:"relative"}}>
                  <VStack width="max-content" alignItems="start"  justifyContent="space-between" >
                    <HStack spacing={5} alignItems="start" >
                      <Text fontWeight="bold">{f.release_date? f.release_date:"-"}</Text>
                      <div >
                      <FontAwesomeIcon icon={faCircle}/>
                      </div>
                    </HStack>

                  <VStack alignItems="start" >
                    <Text fontWeight="bold">{f.original_title}</Text>
                    <Text mt={-5} ml={5} >{f.character? `as ${f.character}`:"-"}</Text>
                  </VStack>
                </VStack>
                <Divider h={2} color="white" />
                </div>
                })}
                </ScrollMenu>
              </CardBody>
              
            </Card>
            </Box>


            <Heading size="lg" mt={8} width="max-content">Production</Heading>
            <Box  className={!movieTMDB&&tabletView?"prod":"production"} >
            <Card size="lg" >
              <CardBody p={mobileView&& 4} style={{background:"#18181b", color: "#fff"}}>
                <ScrollMenu>
                {producer && producer.filter(f=>f.job!="Creator").sort((a, b)=> new Date(b.release_date)-new Date(a.release_date))
                .map((f, index)=>{
                  return <div key={index} style={{width:"max-content", height:"200px",marginRight: "50px", position:"relative"}}>
                  <VStack width="max-content" alignItems="start" justifyContent="space-between" >
                    <HStack spacing={5} alignItems="start" >
                      <Text fontWeight="bold">{f.release_date? f.release_date:"-"}</Text>
                      <div >
                      <FontAwesomeIcon icon={faCircle}/>
                      </div>
                    </HStack>

                  <VStack alignItems="start" >
                    <Text fontWeight="bold">{f.original_title}</Text>
                    <Text mt={-5} ml={5} >{f.job}</Text>
                  </VStack>
                </VStack>
                <Divider h={2} color="white" />
                </div>
                })}
                </ScrollMenu>
              </CardBody>
              
            </Card>
            </Box>

         

            <Heading size="lg" mt={8} width="max-content">Creator</Heading>
            <Box className={!movieTMDB&&tabletView?"creat":"creator"} >
            <Card size="lg" >
              <CardBody p={mobileView&& 4} style={{background:"#18181b", color: "#fff"}}>
                <ScrollMenu>
                {producer && producer.filter(f=>f.job==="Creator").map((f, index)=>{
                  return <div key={index} style={{width:"max-content", height:"200px",marginRight: "50px", position:"relative"}}>
                  <VStack width="max-content" alignItems="start" justifyContent="space-between" >
                    <HStack spacing={5} alignItems="start">
                      <Text fontWeight="bold">{f.release_date}</Text>
                      <div >
                      <FontAwesomeIcon icon={faCircle}/>
                      </div>
                    </HStack>

                  <VStack alignItems="start" >
                    <Text fontWeight="bold">{f.original_title}</Text>
                    <Text mt={-5} ml={5} >{f.job}</Text>
                  </VStack>
                </VStack>
                <Divider h={2} color="white" />
                </div>
                })}
                </ScrollMenu>
              </CardBody>
              
            </Card>

            </Box>
              
            </VStack>


          </Container>
                
           

          
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
            scrollBehavior="inside" 
            preserveScrollBarGap={true}
            >
              <ModalOverlay >
                <ModalContent style={{background:"#18181b", color: "#fff"}} >
                  <ModalHeader>
                  <ModalCloseButton />
                   {title} 
                  </ModalHeader>

                  <ModalBody>
                    <Image src={imageCover} width="100%" />
                    <HStack mt={5} width="100%" justifyContent="space-between">
                      <Text>Year: {releaseDate && format(releaseDate, "MMMM-yyyy")}</Text>
                      <Text>Length: {Newtime}</Text>
                    </HStack>
                    <Box mt={5}>
                    <Text>{descrip}</Text>
                    <ButtonGroup class="flex space-x-4 " mt={2}>
                      <Button as={RouterLink} to={"/movie/" + replaceTitleSpaces} onClick={(e)=>setMoviePlay(true)} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} color="white" fontWeight="bold">Play</Button>
                      <Button background="transparent" height="43px" border="3px solid white" color="white" fontWeight="bold" onClick={addTMDBToMovieList} _hover={{color: "black", background: "#EDF2F7"}} >{movieAddMessage? <span> Added <FontAwesomeIcon icon={faCheck} /></span> : (movieAddErrorMessage? movieAddErrorMessage:"Watch Later")}</Button>
                    </ButtonGroup>
                    </Box>
                  </ModalBody>
                  
                  <ModalFooter>

                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>


                
        </VStack>
      </div>
    )
}



export default Person;