import React, { useState, useEffect, createContext, useContext } from "react";





// Basically I'm doing one thing that is meant to be multiple things in other components

export let MyContext = createContext()   // This is the minor that gets imported in other componenets


function MyContextProvider({children}){    // Children here means children components

    let [isAuthenticated, setIsAuthenticated] = useState(false)
    // This state add TMDB movies imageCard to model database
    let [imageCard, setImageCard] = useState()
    let [tvShows, setTvShows] = useState(false)
    let [username, setUserName] = useState()
    let [userImage, setUserImage] = useState()
    let [genreNextPage, setGenreNextPage] = useState(1)
    let [genreResults, setGenreResults] = useState()
    let [searchNextPage, setSearchNextPage] = useState(1)
    let [loadedImage, setLoadedImage] = useState(false)
    let [landLoadedImage, setlandLoadedImage] = useState(false)

    

    

    

    


    // useEffect((e)=>{
    //   let token = localStorage.getItem("UserAuthtoken")
     
    //   if (token){
    //     setIsAuthenticated(true)
    //   }
      
      
    // }, [isAuthenticated])

    
    return(
        <div>
            <MyContext.Provider value={{isAuthenticated, setIsAuthenticated, imageCard, setImageCard, tvShows, setTvShows, username, setUserName, userImage, setUserImage, genreNextPage, setGenreNextPage, genreResults, setGenreResults, searchNextPage, setSearchNextPage, loadedImage, setLoadedImage, landLoadedImage, setlandLoadedImage}}> {/* All the children have access to the props provided here */}
                {children}
            </MyContext.Provider>
        </div>
    )
}






export default MyContextProvider;    // This is the major that gets use in App.js



