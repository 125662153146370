import React, { useContext, useEffect } from "react";
import { MyContext } from "../contexts/AuthContext";
import { Navigate} from "react-router-dom";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'


function ProtectedRoute ({children}){

    let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)


    if ( !isAuthenticated ) 

    return(
        <div>
            {/* This act as redirects to login page. Included replace prop 
            so that a user cannot click back button to go back to previous page (Protected Route page) */}
            <Navigate to="/Signin" replace />  {/* Replaces navigation history */}
        </div>
    )
    else{
        return children
    }

   
    
}



export default ProtectedRoute;