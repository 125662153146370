import { Container, Tooltip, Box, Button, useBreakpointValue,Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter,ButtonGroup, Heading, Image, Center, Text, HStack, VStack, IconButton, LinkBox } from "@chakra-ui/react";
import { faCheck, faClose, faArrowLeft, faArrowRight, faHeart, faListDots, faThList } from "@fortawesome/free-solid-svg-icons";
import { faVimeoV } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton as MIconButton } from "@mui/material";
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import { Link } from "react-router-dom";
import { format } from "date-fns";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import ClassicPostLoader from "../Components/Images Skeleton";
import React, {useState, useEffect} from "react"

import axios from "axios";



function MovieList({moviePlay, setMoviePlay, movieId, setMovieId, setYoutubePlay}){

    let [imageCover, setImageCover] = useState()
    let [descrip, setDecrip] = useState()
    let [length, setLength] = useState()
    let [releaseDate, setReleaseDate] = useState()
    let [title, setTitle] = useState()

    let replaceTiltleSpaces = title? title.replace(/\s+/g, "-").toLowerCase(): "pirate"
  
    // Split the time string into hours and minutes components. Basically we're unpacking those two variables
    let [hours, minutes] = length? length.split(":") : ""
    // Convert to numbers removing leading zeros. Eg 03 to 3
    let newHours = Number(hours)
    let newMinutes = Number(minutes)
    let Newtime= `${newHours}${newHours==1? "hr":"hrs"} ${newMinutes}mins`


    

  let [movieList, setMovieList] = useState()
  let [isOpen, setIsOpen] = useState()
  let [movieAddMessage, setMovieAddMessage] = useState(false)
  let [movieAddErrorMessage, setMovieAddErrorMessage] = useState()
  let [movieDeleteId, setMovieDeleteId] = useState()
  let [nextPage, setNextPage] = useState(1)
  let [hasMore, setHasMore] = useState()
  let [hasLess, setHasLess] = useState()
  let [movieListCount, setMovieListCount] = useState()



  let onClose = (e)=>{
    setIsOpen(false)
    setMovieAddMessage(false)
    setMovieAddErrorMessage(false)
  }





    function getCSRFToken(e){
        let cookievalue = document.cookie.match(/csrftoken=([^ ;]+)/)
        return cookievalue? cookievalue[1] : null
    }

    let csrftoken = getCSRFToken()


    let addToMovieList = async(e)=>{
      try{
        let response = await axios.post(" https://api-0be9.onrender.com/api/movie-list/", {"movie_id": movieId},{
          headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken':  csrftoken
          }
        })
        console.log("Movie has been added to Movie List successfully", response)
        setMovieAddMessage(true)
      }
      catch (error){
        console.error("Error adding movie to Movie List:", error.response.data.detail)
        toast.error("Error adding movie")
        setMovieAddErrorMessage(`${error.response.data.detail}`)
      }
  
    }


    let [doneDeleting, setDoneDeleting] = useState(false)

    let deleteMovieFromList = async(e)=>{
      try{
        let response = await axios.delete(` https://api-0be9.onrender.com/api/movie-list/${movieDeleteId}/`, {
          headers:{
            'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
            'X-CSRFToken': csrftoken
          }
        })
        setDoneDeleting(!doneDeleting)
        console.log("Movie has been deleted from list successfully", response)
        toast.info(`${title} has been deleted from list`)
      }

      catch (error){
        console.error("Error deleting movie from list:", error.message)
        toast.error(`Error deleting ${title} from list`)
      }
    }
  

    

    let btnStyle = {
      //background: "#e50914",
      fontWeight: 700,
      border: "none",
      outline: "none",
      height: "45px",
      }
  

      
    useEffect((e)=>{

        let func = async(e)=>{
            try{
                let response = await axios.get(` https://api-0be9.onrender.com/api/movie-list/?page=${nextPage}`, {
                    headers:{
                        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
                        'X-CSRFToken': csrftoken
                    }
                })
                console.log("Movie List fetch successfully", response.data.results)
                setMovieList(response.data.results)
                setMovieListCount(response.data.count)
                setHasMore(response.data.next)
                setHasLess(response.data.previous)
            }
            catch (error){
                console.error("Error getting Movie List", error.message)
            }
        }

        func()

      }, [nextPage, doneDeleting])


      //For ScrollMenu Arrow Buttons. Apparently it uses custom LeftArrow and RightArrow components 
      function LeftArr(){
      let {scrollPrev} = React.useContext(VisibilityContext)
      return(
        <IconButton size="xs" isDisabled={!hasLess? true:false} colorScheme="messenger" style={{position:"absolute", top:125, right:mobileView?60: 80}} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
        nextPage>1 && setNextPage(prev=>prev-1) }} />
      )
      }
    function RightArr(){
      let {scrollNext} = React.useContext(VisibilityContext)
      return(
        <Tooltip hasArrow label={`Page ${nextPage}/${Math.ceil(movieListCount/10)}`}>
        <IconButton size="xs" isDisabled={!hasMore? true:false} colorScheme="messenger" style={{position:"absolute", top:125, right:mobileView?20: 40 }} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
        nextPage < (Math.ceil(movieListCount/10)) && setNextPage(prev=>prev+1) }} />
        </Tooltip>
        // <FontAwesomeIcon style={{position:"absolute", right:40, transform:"translate(0.5%,-220.5%)" }} cursor="pointer" icon={faArrowRight} onClick={(e)=>scrollNext()} />
      )
    }



      // Using React state to keep track of wich index image is being hovered over
      // Basically keeping track of the index
      let [hoveredIndex, setHoveredIndex] = useState(null)


      //Creating a Hamburger Menu / base represents mobile & md represents desktop
      let mobileView = useBreakpointValue({
        base: true,
        md: false
      })
    
    
      let tabletView = useBreakpointValue({
        md: true,
        lg:false
          
      })
    
        
    

      return(
        <div>
            <Box pt={20} pb={20} minHeight="100vh" px={mobileView? 3:4} style={{background: "rgba(0, 0, 0, 1)", position:"relative", color:"white" }} >
   
            <Heading mt={10} size="md" textAlign="center" mb={5} id="1" >My List</Heading>

            {/* <div style={{position: "relative"}}>
            <ButtonGroup spacing={4} style={{position:"absolute", top:-40, right: 20}} >
            <IconButton size="xs" isDisabled={!hasLess? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
            nextPage>1 && setNextPage(prev=>prev-1) }} />
            <Tooltip hasArrow label={`Page ${nextPage}/${Math.ceil(movieListCount/10)}`}>
            <IconButton size="xs" isDisabled={!hasMore? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
            (nextPage < Math.ceil(movieListCount/10)) && setNextPage(prev=>prev+1) }} />
            </Tooltip>
            </ButtonGroup>
            </div> */}

            <ScrollMenu LeftArrow={LeftArr} RightArrow={RightArr}  >
              {movieList? movieList.map((f, index)=>{
                return <Box key={index} style={{width:"195px", height:"265px",marginRight:mobileView?"0px": "25px", position:"relative", transform:mobileView && "scale(0.9)"}} onMouseEnter={(e)=>setHoveredIndex(index)} onMouseLeave={(e)=> setHoveredIndex(null)} >
                  <div onClick={deleteMovieFromList} style={{position:"absolute", right:"5px",top:"5px", cursor:"pointer",zIndex:2}}>
                  {/* {hoveredIndex===index &&   <IconButton size="xs" icon={<FontAwesomeIcon icon={faClose} />}  /> } */}
                  {hoveredIndex===index &&   <MIconButton style={{color:"white"}}><ClearIcon /> </MIconButton> }

                  </div>

                      {/* Creating Overlay */}
                    <div style = {{
                      background: hoveredIndex===index && "rgba(0, 0, 0, 0.5)",
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 0,
                      pointerEvents: "none", //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
                      transition: "background 0.3s ease-in-out"
                    }}>    
                    </div>

                  <Text position="absolute" bottom={0} right={5} >{f.movie.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.movie.id)
                    f.overview? setYoutubePlay(true):setYoutubePlay(false)
                    setImageCover(f.movie.poster_path? f.movie.poster_path:f.movie.image_cover)
                    setDecrip(f.movie.description)
                    setLength(f.movie.length)
                    setReleaseDate(f.movie.release_date)
                    setTitle(f.movie.title)
                  }} src={f.movie.backdrop_path? f.movie.backdrop_path:f.movie.image_card} objectFit="cover" width="195px" height="265px" onMouseEnter={(e)=> {
                    setMovieDeleteId(f.id)
                    setTitle(f.movie.title)
                    }
                  } />                
                
                
                </Box>                
              })
              
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >

              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
        
              </>}

            </ScrollMenu>

            

            
            {movieList? movieList.length===0 &&
            <Center mt={20}>
              <VStack>
              <FontAwesomeIcon icon={faVimeoV} size={mobileView?"4x": (tabletView?"6x" : "9x")} />
              <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >No movies have been added to your list yet.</Heading>
              </VStack>
            </Center>
            : ""}

            
                
            <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
            scrollBehavior="inside" 
            preserveScrollBarGap={true}>
              <ModalOverlay>
                <ModalContent style={{background:"#18181b", color: "#fff"}} >
                  <ModalHeader>
                  <ModalCloseButton />
                   {title}  
                  </ModalHeader>

                  <ModalBody>
                    <Image src={imageCover} width="100%" />
                    <HStack mt={5} width="100%" justifyContent="space-between">
                      <Text>Year: {releaseDate && format(releaseDate, "MMMM-yyyy")}</Text>
                      <Text>Length: {Newtime}</Text>
                    </HStack>
                    <Box mt={5}>
                    <Text>{descrip}</Text>
                    <ButtonGroup class="flex space-x-4 " mt={2}>
                      <Button as={Link} to={"/movie/" + replaceTiltleSpaces} onClick={(e)=>setMoviePlay(true)} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} color="white" fontWeight="bold">Play</Button>
                      <Button background="transparent" height="43px" border="3px solid white" color="white" fontWeight="bold" onClick={addToMovieList} _hover={{color: "black", background: "#EDF2F7"}} >{movieAddMessage? <span> Added <FontAwesomeIcon icon={faCheck} /></span> : (movieAddErrorMessage? movieAddErrorMessage:"Watch Later")}</Button>
                    </ButtonGroup>
                    </Box>
                  </ModalBody>
                  
                  <ModalFooter>

                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>

                           
            </Box>





        </div>
    )
}



export default MovieList;