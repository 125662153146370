import { Heading, useBreakpointValue,Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel, textDecoration } from "@chakra-ui/react"
import {faAngleRight, faEye, faEyeSlash, faFileCircleXmark, faMultiply, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import React, {useState, useEffect, useContext} from "react"
import { MyContext } from "../contexts/AuthContext";
import useResetAuth from "../Components/ResetAuth";
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import { ref } from "yup";
import * as Yup from 'yup'

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';

import { useNavigate, Link } from "react-router-dom";
import axios from 'axios'

import netflixbg from "../Assets/netflix-image.jpg"
import netflixlogo from "../Assets/Netflix_Logo.png"





function Signup({successLogin, setsuccessLogin, selected, setSelected, signPage, setSignPage}){

  useResetAuth()

  let navigate = useNavigate()
 
  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
    

  let onClose = ()=>{
    setAlertmessage("")
  }
  
  let onClose2 = () => {
    setAlerterrromessage("")
  }  


  let [initialValues, setInitialvalues] = useState({
    email: "",
    username: "",
    password1: "",
    password2: ""
  })


  let validationSchema = Yup.object({
    email: Yup.string().required("Email required").email("Invalid email format").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email must be a valid email adddress"),
    username: Yup.string().required("Username required"),
    password1: Yup.string().required("Password required").min(8, "Password must be at least 8 characters").matches(/[0-9]/, "Password must contain at least one number").matches(/[!@#$%^&*-_]/, "Password must contain at least one special character"),
    password2: Yup.string().required("Confirm password required").oneOf([ref("password1"), null], "Passwords must match"),
  })

  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  let onSubmit = async(values, actions) =>{

    try{  

      let response= await axios.post("https://api-0be9.onrender.com/auth/registration/", values, {
        headers:{
          'X-CSRFToken': csrftoken
        }
      })
      console.log("Account created successfully")
      actions.setSubmitting(false)
      actions.resetForm()
      setAlertmessage(`Thanks for your submission ${values.username}, You have successfully registered on Netflix! Check your email for the verification link `)
      setTimeout(() => {
       setAlertmessage(false)
      }, 9000)
    }
    
    catch (error){
      console.error("Error submitting data", error)
      actions.setSubmitting(false)
      setAlerterrromessage(`Something went wrong, please try again later! ${error.message}`)
      seterrorResponseMessage(`${error.response.data.email? error.response.data.email:""} ${error.response.data.username? error.response.data.username:""}`)
      setTimeout(() => {
        setAlerterrromessage(false)
      }, 9000);
    }
  }


  useEffect((e)=>{
    setSignPage(true)
    
    let userData = localStorage.getItem("userEmail")
    let parseUserData = userData&& JSON.parse(userData)
    initialValues.email = userData&& parseUserData.email

  }, [])




let inputStyle = {
  background: "#333",
  border: 0,
  color: "#fff",
  height: "50px",
  lineHeight: "50px",
  padding: "16px 20px",
  //marginBottom: "20px",
}
 
let btnStyle = {
  //background: "#e50914",
  fontWeight: 700,
  border: "none",
  outline: "none",
  padding: "16px",
  fontSize: "16px",
  cursor: "pointer",
}


let {loadedImage, setLoadedImage} = useContext(MyContext)

let onLoad = (e)=>{
  setLoadedImage(true)
}



let mobileView = useBreakpointValue({
  base: true,
  lg: false
})


// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
  md: true,
})



    return(
      
        
          <div>
              <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8} backgroundImage={loadedImage? netflixbg:"none"} backgroundColor={loadedImage? "transparent":"rgba(0, 0, 0, 0.9)"} backgroundSize="cover" >  

              {/* Creating Overlay */}
              <div style = {{
                background: "rgba(0, 0, 0, 0.4)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>
              
              {/* Just a loading listener */}
              <img style={{display: "none"}} src={netflixbg} onLoad={onLoad} />


                     
            <div className="flex items-center justify-center min-h-screen flex-grow container" style={{zIndex:1, marginTop: tabletView? 20:60, marginBottom:tabletView?20:60}} >
              <div className="p-12 shadow-md rounded-lg max-w-md w-full" style={{background: "rgba(0, 0, 0, 0.9)", color: "#fff"}} >

              <Heading  style={{width:"90%"}} className="mx-auto" marginBottom={8} color="white" fontSize="3xl">Signup </Heading>
              <Heading style={{width:"90%"}} className="mx-auto" fontSize="2xl">Unlimited movies, TV shows, and more.</Heading>


                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form style={{width:"90%"}} className="mx-auto">
                  
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={5}  style={{marginBottom: "20px"}} >
                <Field as={Input} type="email" fontSize="sm" placeholder="Email address"  name="email" id="email" style={inputStyle} />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>
                  
                  <FormControl isInvalid={!!formik.errors.username && formik.touched.username} mt={5}  style={{marginBottom: "20px"}} >
                  <Field as={Input} fontSize="sm" placeholder="Username"  name="username" id="username" style={inputStyle} />
                  <FormErrorMessage>
                  <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                  <ErrorMessage name="username" />
                  </FormErrorMessage>
  
                </FormControl>
                
 
                <FormControl isInvalid={!!formik.errors.password1 && formik.touched.password1} style={{marginBottom: "20px"}} >
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} fontSize="sm" type={visiblePass? "text": "password"} placeholder="Password" name="password1" style={inputStyle} />
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password1"/>
                </FormErrorMessage>

                </FormControl>


                <FormControl isInvalid={!!formik.errors.password2 && formik.touched.password2} style={{marginBottom: "20px"}} >
                <Field as={Input} fontSize="sm" type="password" placeholder="Confirm Password" name="password2" style={inputStyle} />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password2"/>
                </FormErrorMessage>

                </FormControl>

                          
                <Button type="submit" style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} isLoading={formik.isSubmitting} mt={4} width="full" color="white"  fontWeight="bold" ><span style={{paddingRight: 5}}>Get Started</span> <FontAwesomeIcon icon={faAngleRight} /> </Button>


                <div class="help-text" align="center" style={{marginTop: 14, color: "#b3b3b3"}} >
                <Text fontSize="small" >Ready to watch? Enter your email to create or restart your membership.</Text>
                <Text fontSize="small" >Already have an account? <Text to="/signin" as={Link} _hover={{textDecoration:"underline"}} >Sign In</Text></Text>
                </div>
              
              

                </Form>
                )}
                </Formik>

              </div>
            </div>


                
                 {/* Creating logo*/}
                  {/*  <Image src={logo} position="absolute" top= "10%" left="5" boxSize="50px" /> */}
                  <Link to="/">
                  <Image src={netflixlogo} zIndex={2} className="netflixlogo" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%" left="3" width="auto" height="75px" /> 
                  </Link>

                
              
                </VStack>


              
              <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

                <AlertDialogOverlay>
                <AlertDialogContent backgroundColor= "#81C784">
                <AlertDialogHeader paddingTop={5} fontWeight="bold">
                <AlertDialogCloseButton/>  
                  All good!
                </AlertDialogHeader >
                <AlertDialogBody paddingBottom={5}>
                  {alertMessage}<br/>
              
                </AlertDialogBody>
                </AlertDialogContent>
                </AlertDialogOverlay>

              </AlertDialog>
                  
                
                
              <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose2}
                >

                <AlertDialogOverlay>
                <AlertDialogContent backgroundColor= "#FF8A65">
                <AlertDialogHeader paddingTop={5} fontWeight="bold">
                <AlertDialogCloseButton/>  
                  Oops!
                </AlertDialogHeader >
                <AlertDialogBody paddingBottom={5} >
                  {alerterrorMessage}<br/>
                  {alerterrorResponseMessage && alerterrorResponseMessage}
                </AlertDialogBody>
                </AlertDialogContent>
                </AlertDialogOverlay>

              </AlertDialog>  

               
            </div> 


                  
  
    )
  }


export default Signup