import { Heading, useBreakpointValue, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel, textDecoration } from "@chakra-ui/react"
import {faEye, faEyeSlash, faFileCircleXmark, faMultiply, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import React, {useState, useEffect, useContext} from "react"
import { MyContext } from "../contexts/AuthContext";
import useResetAuth from "../Components/ResetAuth";
import {Formik, Form, Field, ErrorMessage } from "formik"
import { ref } from "yup";
import { useParams } from "react-router-dom";
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';

import { useNavigate, Link } from "react-router-dom";
import axios from 'axios'

import netflixbg from "../Assets/netflix-image.jpg"
import netflixlogo from "../Assets/Netflix_Logo.png"





function PasswordConfirm({successLogin, setsuccessLogin, selected, setSelected, signPage, setSignPage}){

  useResetAuth()

  let {uid, token} = useParams()  // Extract the uid parameter and the token parameter from the URL

  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)


  

  let onClose = () => {
    setAlerterrromessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    password: "",
    confirmPassword: ""
  })


  let validationSchema = Yup.object({
    password: Yup.string().required("Password required").min(8, "Password must be at least 8 characters").matches(/[0-9]/, "Password must contain at least one number").matches(/[!@#$%^&*-_]/, "Password must contain at least one special character"),
    confirmPassword: Yup.string().required("Confirm password required").oneOf([ref("password"), null], "Passwords must match"),    
  })


  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  let onSubmit = async(values, actions) =>{
  
    try{  
      let response= await axios.post("https://api-0be9.onrender.com/djoser/users/reset_password_confirm/", {
        uid: uid,
        token: token,
        new_password: values.password
      }, {
        headers:{
          'X-CSRFToken': csrftoken
        }
      })
      console.log("New password has been set")
      actions.setSubmitting(false)
      setAlertmessage("New Password reset has been set successfully")
      setTimeout(() => {
       setAlertmessage(false)
      }, 9000);
      actions.resetForm()
    }

    catch (error){
      console.error("There was an error setting new password", error.message)
      actions.setSubmitting(false)
      setAlerterrromessage(`Something went wrong, please try again later! ${error.message}`)
    }
   }


  
    

let inputStyle = {
  background: "#333",
  border: 0,
  color: "#fff",
  height: "50px",
  lineHeight: "50px",
  padding: "16px 20px",
  //marginBottom: "20px",
  fontSize:14,
}
 
let btnStyle = {
  //background: "#e50914",
  fontWeight: 700,
  border: "none",
  outline: "none",
  padding: "16px",
  fontSize: "16px",
  cursor: "pointer",
}


let {loadedImage, setLoadedImage} = useContext(MyContext)

let onLoad = (e)=>{
  setLoadedImage(true)
}


let mobileView = useBreakpointValue({
  base: true,
  lg: false
})



// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
  md: true,
})



    return(
      
        
          <div>
              <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8} backgroundImage={loadedImage? netflixbg:"none"} backgroundColor={loadedImage? "transparent":"rgba(0, 0, 0, 0.9)"} backgroundSize="cover" >  

              {/* Creating Overlay */}
              <div style = {{
                background: "rgba(0, 0, 0, 0.4)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              </div>

              {/* Just a loading listener */}
              <img style={{display: "none"}} src={netflixbg} onLoad={onLoad} />

       
            <div className="flex items-center justify-center min-h-screen flex-grow container" style={{zIndex:1}} >
              <div className="p-12 shadow-md rounded-lg max-w-md w-full" style={{background: "rgba(0, 0, 0, 0.9)", color: "#fff"}} >

              <Heading  style={{width:"90%"}} className="mx-auto" marginBottom={8} color="white" fontSize="3xl">Reset your Password.</Heading>


                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form style={{width:"90%"}} className="mx-auto">
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={5}  style={{marginBottom: "20px"}} >
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} fontSize="sm" type={visiblePass? "text": "password"} placeholder="Enter your password..." name="password" id="password" style={inputStyle} />
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password" />
                </FormErrorMessage>

                </FormControl> 

                <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword} mt={5}  style={{marginBottom: "20px"}} >
                <Field as={Input} fontSize="sm" type="password" placeholder="Confirm your password..."  name="confirmPassword" id="confirmPassword" style={inputStyle} />
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="confirmPassword" />
                </FormErrorMessage>

                </FormControl>      
                
                          
                <Button type="submit" style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} isLoading={formik.isSubmitting} mt={4} width="full" color="white"  fontWeight="bold">Change Password</Button>


                </Form>
                )}
                </Formik>

                  
              </div>
            </div>


                
                 {/* Creating logo*/}
                  {/*  <Image src={logo} position="absolute" top= "10%" left="5" boxSize="50px" /> */}
                  <Link to="/">
                  <Image src={netflixlogo} className="netflixlogo" zIndex={2} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "0%" left="3" width="auto" height="75px" /> 
                  </Link>

                
              
                </VStack>


                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

                <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#81C784">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>


               <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>


               
            </div> 


                  
  
    )
  }


export default PasswordConfirm