import { VStack, useBreakpointValue, Modal,Button, Box, Text, Heading,Image, Container, ButtonGroup, Card, CardBody, HStack, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalFooter, ModalCloseButton, SimpleGrid, IconButton, FormHelperText, Tooltip } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {MenuItem, Select, Button as Btn} from "@mui/material"

import ClassicPostLoader from "../Components/Images Skeleton";
import "./Pages.css"
import TitleCards from "../Components/TitleCards";

import poster from "../Assets/hero_banner.jpg"
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu"
import 'react-horizontal-scrolling-menu/dist/styles.css'
import React,{ useEffect, useState, useRef, useContext } from "react"
import {format} from "date-fns"
import InfiniteScroll from "react-infinite-scroll-component"
import SidebarNav from "../Components/Sidebars";


import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlay, faInfo, faInfoCircle, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import heroTitle from "../Assets/hero_title.png"
import { MyContext } from "../contexts/AuthContext";





function Dashboard({moviePlay, setMoviePlay, movieId, setMovieId, youtubePlay, setYoutubePlay, descrip, setDecrip}){

  let [imageCover, setImageCover] = useState()
  // let [descrip, setDecrip] = useState()
  let [length, setLength] = useState()
  let [releaseDate, setReleaseDate] = useState()
  let [title, setTitle] = useState()

  let replaceTitleSpaces = title? title.replace(/\s+/g, "-").toLowerCase(): "pirate"

  // Split the time string into hours and minutes components. Basically we're unpacking those two variables
  let [hours, minutes] = length? length.split(":") : ""
  // Convert to numbers removing leading zeros. Eg 03 to 3
  let newHours = Number(hours)
  let newMinutes = Number(minutes)
  let Newtime= `${newHours}${newHours==1? "hr":"hrs"} ${newMinutes}mins`


  // Could keep simple without this
  let [hasLink, setHasLiink] = useState()


  let {tvShows, setTvShows} = useContext(MyContext)


  let [movieData, setMovieData] = useState()
  let [movieTMDB, setMovieTMDB] = useState()
  let [movieDataCount, setMovieDataCount] = useState()
  let [movieTMDBCount, setMovieTMDBCount] = useState()
  let [isOpen, setIsOpen] = useState()
  let [movieAddMessage, setMovieAddMessage] = useState(false)
  let [movieAddErrorMessage, setMovieAddErrorMessage] = useState()
  let featuredMovie = movieData && movieData[0]
  


  let onClose = (e)=>{
    setIsOpen(false)
    setMovieAddMessage(false)
    setMovieAddErrorMessage(false)
    setListMessage2(false)
    setListErrorMessage2(false)
    setYoutubePlay(false)
  }


  function getCSRFToken(e){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken()


  let addToMovieList = async(e)=>{
    try{
      let response = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": movieId},{
        headers:{
          'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
          'X-CSRFToken':  csrftoken
        }
      })
      console.log("Movie has been added to Movie List successfully", response)
      toast.success(`${title} added to Movie List`)
      setMovieAddMessage(true)
    }
    catch (error){
      console.error("Error adding movie to Movie List:", error.response.data.detail)
      toast.error("Error adding movie")
      setMovieAddErrorMessage(`${error.response.data.detail}`)
    }
  }

  let [listmessage2, setListMessage2] = useState()
  let [ListErrorMessage2, setListErrorMessage2] = useState()
  

  let addToMovieList2 = async(e)=>{
    try{
      let response = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": featuredMovie.id},{
        headers:{
          'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
          'X-CSRFToken':  csrftoken
        }
      })
      console.log("Movie has been added to Movie List successfully", response)
      toast.success(`${title} added to Movie List`)
      setListMessage2(true)
    }
    catch (error){
      console.error("Error adding movie to Movie List:", error.response.data.detail)
      toast.error("Error adding movie")
      setListErrorMessage2(error.response.data.detail)
    }

  }


 // This function add TMDB movies to model database
 let {imageCard, setImageCard} = useContext(MyContext)

 const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZDQzOTE3NWQwMWEzNzk3YzEwZGQwOTJiNDc5N2NmNCIsIm5iZiI6MTcyMjI4MDAwNi45NDQ5MTYsInN1YiI6IjY2OTY4ZGZjZGE5OTgyZWNhMzVkZDMzZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tFBLv30RlwF91lnZCNN91x3MN9Yovkrizdja_JNaq5I'
  }
};


 let addTMDBToMovieList = async()=>{    
  let details = {
    title: title,
    description: descrip,
    genre: "action",
    category: "popular",
    length: length,
    backdrop_path: imageCard,
    poster_path: imageCover,
    //youtube_video_url: "key",
    tmdb_movieid:  movieId
  }
  const loading = toast.loading("Adding movie...")

  try{
    let responseVidz = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}/videos?language=en-US`, options)
     
    let response = await axios.post("https://api-0be9.onrender.com/api/movies/", {...details,youtube_video_url:responseVidz.data.results[0].key},{
      headers:{
        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
        'X-CSRFToken':  csrftoken
      }
    })
    
    let response2 = await axios.post("https://api-0be9.onrender.com/api/movie-list/", {"movie_id": response.data.id},{
      headers:{
        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
        'X-CSRFToken':  csrftoken
      }
    })

    console.log("Movie has been added to Movie List successfully", response)
    toast.dismiss(loading)
    toast.success(`${title} added to Movie List`)
    setMovieAddMessage(true)

  }
  catch (error){
    console.error("Error adding TMDB movie to Model:", error.response.data)
    console.error("Error adding TMDB to Movie List:", error.response.data)
    toast.dismiss(loading)
    toast.error("Error adding movie")
    setMovieAddErrorMessage(`${error.response.data.detail}`)
  }
}

  
  

  //For ScrollMenu Arrow Buttons. Apparently it uses custom LeftArrow and RightArrow components 
  function LeftArrow(){
  const {scrollPrev} = React.useContext(VisibilityContext)
   return(
     <IconButton size="xs" isDisabled={!hasLess? true:false} colorScheme="messenger" style={{position:"absolute", top:45, right:mobileView?60: 80 }} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
    nextPage>1 && setNextPage(prev=>prev-1)} } />
   )
  }
 function RightArrow(){
  const {scrollNext} = React.useContext(VisibilityContext)
  return(
    <Tooltip hasArrow label={`Page ${nextPage}/${Math.ceil(movieDataCount/10)}`}>
    <IconButton size="xs" isDisabled={!hasMore? true:false} colorScheme="messenger" style={{position:"absolute", top:45, right:mobileView?20: 40 }} cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
    (nextPage < Math.ceil(movieDataCount/10)) &&  setNextPage(prev=>prev+1) }} />
    </Tooltip>
  )
 }






  let btnStyle = {
    //background: "#e50914",
    fontWeight: 700,
    border: "none",
    outline: "none",
    height: "45px",
    }

    let [nextPage, setNextPage] = useState(1)
    let [nextPageTrend, setNextPageTrend] = useState(1)
    let [hasMore, setHasMore] = useState()
    let [hasLess, setHasLess] = useState()
    let [hasMoreTrend, setHasMoreTrend] = useState()
    let [hasLessTrend, setHasLessTrend] = useState()
    let scrollContainer = useRef(null)

    useEffect((e)=>{
      setMoviePlay(false)

      let func = async(e)=>{
        try{
          // Using axios.all to prevent delay(waiting time) of waiting for one to finish before moving to another
          let [response, response2] = await axios.all([ 
          axios.get(`https://api-0be9.onrender.com/api/movies/?category=popular&page=${nextPage}`, {
            headers:{
              'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`
            }}),
          axios.get(`https://api-0be9.onrender.com/api/movies/?category=trending&page=${nextPageTrend}`, {
            headers:{
              'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`
            }})

        ]);
          console.log("Sucess fetching movie data", response.data.results)
          console.log("Sucess fetching movieTMDB movies", response.data.results)
          setMovieData(response.data.results)
          setMovieTMDB(response2.data.results)
          setMovieDataCount(response.data.count)
          setMovieTMDBCount(response2.data.count)
          
          setHasMore(response.data.next)  
          setHasLess(response.data.previous)
          setHasMoreTrend(response2.data.next)  
          setHasLessTrend(response2.data.previous)
        }
        catch(error){
          console.error("Error fetching movie data", error.message)
        }
      }

      func()

    }, [nextPage, hasLess, nextPageTrend, hasLessTrend])


     //Creating a Hamburger Menu / base represents mobile & md represents desktop
    let mobileView = useBreakpointValue({
      base: true,
      md: false
    })


    let tabletView = useBreakpointValue({
      md: true,
      lg:false   
    })

  


    return(
        <div>
            <VStack Width="full" minHeight={tabletView? "60vh":"90vh"} backgroundImage={poster} backgroundSize="cover" >
            {/*height suppose to be 60vh */}
            {/* Creating Overlay */}
              <div style = {{
                background: "linear-gradient(to right, rgba(0,0,0,1), transparent)",
                //maskImage: "linear-gradient(to right, transparent, black 75%)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                pointerEvents: "none" //This prevent overlay from receiving mouse events but it allows it to pass through to the element underneath.
              }}>    
              <Image ml={8} opacity="0.4" mt={80} width="30%" src={heroTitle} />
              </div>

              <Box pt={20} className="my-auto" alignSelf="start" ml={mobileView? 5:8} style={{position:"relative", color:"white" }} >
                <Heading class={mobileView? "text-3xl mb-3": "text-4xl mb-3"} >{featuredMovie? featuredMovie.title: "Doctor Strange in the Multiverse of Supreme"}</Heading>
                <ButtonGroup class="flex space-x-4 mb-4">
                <Button as={Link} to={`/movie/${featuredMovie&& featuredMovie.title}`} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} color="white" fontWeight="bold" onClick={(e)=>{
                if (featuredMovie) { 
                  featuredMovie.tmdb_movieid? setMovieId(featuredMovie.tmdb_movieid): setMovieId(featuredMovie.id)
                  // setMovieId(featuredMovie.id)
                  setTitle(featuredMovie.title)
                  setDecrip(featuredMovie.description)
                  setMoviePlay(true)
                  // Could keep simple with just setYoutubePlay(false)
                  featuredMovie.youtube_video_url? setYoutubePlay(true):  setYoutubePlay(false)
                  setTvShows(false)
                  }

                }}><FontAwesomeIcon icon={faPlay} /><span style={{paddingLeft:8}}>Play</span></Button>
                <Button background="#6d6d6eb3" height="44px" onClick={featuredMovie && addToMovieList2} border="3px solid white" color="white" fontWeight="bold"  _hover={{color: "black", background: "#EDF2F7"}}>
                  {listmessage2? <span> Added <FontAwesomeIcon icon={faCheck} /></span> : (ListErrorMessage2? ListErrorMessage2:<span><FontAwesomeIcon icon={faInfoCircle}/><span style={{paddingLeft:8}}>Watch Later</span></span>)}
                </Button>  
                </ButtonGroup>
                <Text>Released {featuredMovie? featuredMovie.release_date: "2022-05-04"}</Text>
                <Text noOfLines={3} width="50vw">{featuredMovie? featuredMovie.description: "Doctor Strange, with the help of mystical allies both old and new, traverses themind-blowingand dangerous alternate realities of the Multiverse to C..."}</Text>

              </Box>

              



            </VStack>


            <Container maxW="full" pb={20} class={mobileView?"py-10 px-3": "py-10 px-4"} minHeight="60vh" style={{background: "rgba(0, 0, 0, 1)", color: "#fff", position:"relative"}}>
            <Heading size="md" mb={5}>Popular on Netflix</Heading>
            
            <ScrollMenu ref={scrollContainer} LeftArrow={LeftArrow} RightArrow={RightArrow} >
              {movieData? movieData.map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px",marginRight: mobileView?"0px": "25px", position:"relative", transform: mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5} >{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setImageCover(f.poster_path? f.poster_path:f.image_cover)
                    setDecrip(f.description)
                    setLength(f.length)
                    setReleaseDate(f.release_date)
                    setTitle(f.title)
                // Could keep simple with just setYoutubePlay(false)
                f.youtube_video_url? setYoutubePlay(true):  setYoutubePlay(false)
                setHasLiink(f.tmdb_movieid)
                    setTvShows(false)
                  }} src={f.backdrop_path? f.backdrop_path:f.image_card} objectFit="cover" width="195px" height="265px" _hover={{transform:mobileView?"scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
              </>}
            </ScrollMenu>
          
                  
            <div style={{position: "relative"}}>
            <Heading size="md" mb={5}pt={10} >Trending</Heading>
            <ButtonGroup spacing={4} style={{position:"absolute", top:45, right:mobileView?0: 20}} >
            <IconButton size="xs" isDisabled={!hasLessTrend? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={(e)=>{
            nextPageTrend>1 && setNextPageTrend(prev=>prev-1) }} />
            <Tooltip hasArrow label={`Page ${nextPageTrend}/${Math.ceil(movieTMDBCount/10)}`}>
            <IconButton size="xs" isDisabled={!hasMoreTrend? true:false} colorScheme="messenger"  cursor="pointer" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e)=>{
            (nextPageTrend < Math.ceil(movieTMDBCount/10)) && setNextPageTrend(prev=>prev+1) }} />
            </Tooltip>
            </ButtonGroup>
            </div>

            <ScrollMenu >
              {movieTMDB? movieTMDB.map((f, index)=>{
                return <div key={index} style={{width:"195px", height:"265px", marginRight:mobileView?"0px": "25px", position:"relative", transform:mobileView && "scale(0.9)"}}>
                  <Text position="absolute" bottom={0} right={5}>{f.title}</Text>
                  <Image onClick={(e)=>{
                    setIsOpen(true)
                    setMovieId(f.id)
                    setImageCover(f.poster_path? f.poster_path:f.image_cover)
                    setDecrip(f.description)
                    setLength(f.length)
                    setReleaseDate(f.release_date)
                    setTitle(f.title)
                    setYoutubePlay(false)
                    setTvShows(false)
                  }} src={f.backdrop_path? f.backdrop_path:f.image_card} objectFit="cover" width="195px" height="265px" _hover={{transform:mobileView? "scale(1.1)": "scale(1.2)"}} transition="transform 0.3s ease-in-out "  />
                </div>
              })
            
              :

              <>
              <HStack ml={mobileView? -12:-8} mt={-10} mb={-20} >
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              <Box width={250} transform={mobileView? "scale(0.8,1.2)": "scaleY(1.2)"} mr={mobileView&& -14} >
              <ClassicPostLoader />
              </Box>
              
              </HStack>
              </>}

            </ScrollMenu>


            {/* Fetched data from TMDB database */}
            
             {/* All external title cards */}  
            <TitleCards isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}
            movieId={movieId} setMovieId={setMovieId} imageCover={imageCover} setImageCover={setImageCover} 
            length={length} setLength={setLength} descrip={descrip} setDecrip={setDecrip} releaseDate={releaseDate} 
            setReleaseDate={setReleaseDate} title={title} setTitle={setTitle} youtubePlay={youtubePlay} setYoutubePlay={setYoutubePlay} 
            setTubeDetails/>

    

            <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
            scrollBehavior="inside" 
            preserveScrollBarGap={true}
            >
              <ModalOverlay >
                <ModalContent style={{background:"#18181b", color: "#fff"}} >
                  <ModalHeader>
                  <ModalCloseButton />
                   {title}  
                  </ModalHeader>

                  <ModalBody>
                    <Image src={imageCover} width="100%" />
                    <HStack mt={5} width="100%" justifyContent="space-between">
                      <Text>Year: {releaseDate && format(releaseDate, "MMMM-yyyy")}</Text>
                      <Text>Length: {Newtime}</Text>
                    </HStack>
                    <Box mt={5}>
                    <Text>{descrip}</Text>
                    <ButtonGroup class="flex space-x-4 " mt={2}>
                      <Button as={Link} to={"/movie/" + replaceTitleSpaces} onClick={(e)=>{
                      setMoviePlay(true)
                      // Could keep simple with just setMoviePlay(true)
                      hasLink && setMovieId(hasLink)
                      }} style={btnStyle} bg="#e50914" _hover={{background:"red.500"}} color="white" fontWeight="bold">Play</Button>
                      <Button background="transparent" height="43px" border="3px solid white" color="white" fontWeight="bold" onClick={youtubePlay? addTMDBToMovieList: addToMovieList} _hover={{color: "black", background: "#EDF2F7"}} >{movieAddMessage? <span> Added <FontAwesomeIcon icon={faCheck} /></span> : (movieAddErrorMessage? movieAddErrorMessage:"Watch Later")}</Button>
                    </ButtonGroup>
                    </Box>
                  </ModalBody>
                  
                  <ModalFooter>

                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>




            </Container>

            

        </div>
    )
}




export default Dashboard